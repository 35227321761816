import { Component, OnInit } from '@angular/core';
import { FeedbackComponent } from 'src/app/core/components/feedback/feedback.component';
import { MatDialog } from '@angular/material/dialog';
import { AE_WEB_CONST } from '../../models/constants';
import { FeedbackService } from '../../services/feedback.service';
import { SnackbarService } from '../../services/snackbar.service';
import { UntypedFormControl  } from '@angular/forms';
import { Router } from '@angular/router';
import { SpinnerService } from '../../services/spinner.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {
  isShown = false;
  selectedFeedback: number;
  additionalComments = new UntypedFormControl();
  feedbackSuccess = false;
  agentInfo: any;
  rrnReferral = false;
  constructor(public dialog: MatDialog,
    private feedbackSvc: FeedbackService,
    private snackbarService: SnackbarService,
    private router: Router,
    private spinnerService: SpinnerService,
    private titleSvc: Title 
  ) {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras.state
    ) {
      this.agentInfo = this.router.getCurrentNavigation().extras.state.agentDetails;
      this.rrnReferral = true;
    }
    if(this.rrnReferral) {
      this.titleSvc.setTitle('Thank You - CBRRN');
    }
  }

  ngOnInit(): void { }

  openFeedbackPopup() {
    const feedbackDialog = this.dialog.open(FeedbackComponent, {
      autoFocus: false,
      panelClass: 'feedback-panel-section',
      backdropClass: 'feedback-backdrop'
    });
    feedbackDialog.afterClosed().subscribe(feedback => {
      if (feedback) {
        feedback['currentScreen'] = 'Thank You';
        this.feedbackSvc.submitFeedback(feedback).subscribe(response => {
          if (response && response.status === 201) {
            this.snackbarService.snackBarSucess(
              AE_WEB_CONST.feedbackSuccessMessage,
              3000,
              'feedback'
            );
          }
        });
      }
    });
  }

  textAreaShown(feedback) {
    this.isShown = true;
    this.selectedFeedback = feedback;
    document.querySelector('#feedback-module').classList.remove('feedback-module');
  }

  sendFeedback() {
    this.spinnerService.show();
    const feedback = this.rrnReferral ? {
      leadSource: 'Referral Agent Web Form',
      rating: this.selectedFeedback,
      additionalFeedback: this.additionalComments.value ? this.additionalComments.value : undefined,
      feedbackWidget: 'Thank You',
      agentPersonalDetails: {
        firstName: this.agentInfo.firstName,
        lastName: this.agentInfo.lastName,
        email: this.agentInfo.email,
        phoneNumber: this.agentInfo.phoneNo,
        agentGuid: undefined,
        companyName: undefined,
        companyGuid: undefined
      }
    } : {
        feedbackRating: this.selectedFeedback,
        additionalComments: this.additionalComments.value,
        currentScreen: 'Thank You'
      };
    this.feedbackSvc.submitFeedback(feedback, this.rrnReferral).subscribe(response => {
      if (response && response.status === 201) {
        this.spinnerService.hide();
        this.feedbackSuccess = true;
        document.querySelector('#feedback-module').classList.add('feedback-module');
      }
    });
  }

  createReferral() {
    this.rrnReferral ?
      this.router.navigate(['/rni'], {
        state: {
          agentInformation: this.agentInfo
        }
      }) :
      this.router.navigate(['/createreferral']);
  }
}
