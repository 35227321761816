import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AE_WEB_CONST } from 'src/app/core/models/constants';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})

export class MaintenanceComponent implements OnInit {
  routeLink:string;
  emailAdress: string;
  submitReferralLink = environment.SUB_REFERRAL_MAINTENANCE_MODE
  constructor(private titleSvc: Title) { 
    this.routeLink = window.history.state.routeLink;
    if(this.routeLink === '/rni') {
      this.emailAdress = environment.RRN_EMAIL_ADDRESS 
      this.titleSvc.setTitle('Create Referral - CBRRN');
    }
    else {
      this.emailAdress = environment.CB_EMAIL_ADDRESS;
    }
  }

  ngOnInit(): void {
  
  }

}
