<div [ngClass]="rrnReferral ? 'thankyou-wrapper rrn' : 'thankyou-wrapper norrn'">
  <h2>
    Thank you for placing your referral! <br />
    We will be in contact with you shortly.
  </h2>
  <div id="feedback-module" class="feedback-section feedback-module">
    <form *ngIf="!feedbackSuccess">
      <div class="feedback-question">
        <h2>
          Your feedback is important to us! <br />How would you rate your
          experience?
        </h2>
        <div class="feedback-emoji">
          <div class="feedback-emoji-img" (click)="textAreaShown(1)"
            [ngClass]="selectedFeedback === 1 ? 'arrow-top' : ' arrow-inactive'">
            <div class="very-diasatisfied emoji-img"></div>
            <p class="emoji-text" [ngClass]="
                selectedFeedback === 1
                  ? 'emoji-text-active'
                  : 'emoji-text-inactive'
              ">
              Very Dissatisfied
            </p>
          </div>
          <div class="feedback-emoji-img" (click)="textAreaShown(2)"
            [ngClass]="selectedFeedback === 2 ? 'arrow-top' : ' arrow-inactive'">
            <div class="diasatisfied emoji-img"></div>
            <p class="emoji-text" [ngClass]="
                selectedFeedback === 2
                  ? 'emoji-text-active'
                  : 'emoji-text-inactive'
              ">
              Dissatisfied
            </p>
          </div>
          <div class="feedback-emoji-img" (click)="textAreaShown(3)"
            [ngClass]="selectedFeedback === 3 ? 'arrow-top' : ' arrow-inactive'">
            <div class="neutral emoji-img"></div>
            <p class="emoji-text" [ngClass]="
                selectedFeedback === 3
                  ? 'emoji-text-active'
                  : 'emoji-text-inactive'
              ">
              Neutral
            </p>
          </div>
          <div class="feedback-emoji-img" (click)="textAreaShown(4)"
            [ngClass]="selectedFeedback === 4 ? 'arrow-top' : ' arrow-inactive'">
            <div class="satisfied emoji-img"></div>
            <p class="emoji-text" [ngClass]="
                selectedFeedback === 4
                  ? 'emoji-text-active'
                  : 'emoji-text-inactive'
              ">
              Satisfied
            </p>
          </div>
          <div class="feedback-emoji-img" (click)="textAreaShown(5)"
            [ngClass]="selectedFeedback === 5 ? 'arrow-top' : ' arrow-inactive'">
            <div class="very-satisfied emoji-img"></div>
            <p class="emoji-text" [ngClass]="
                selectedFeedback === 5
                  ? 'emoji-text-active'
                  : 'emoji-text-inactive'
              ">
              Very Satisfied
            </p>
          </div>
        </div>
        <div *ngIf="isShown">
          <div class="textarea-div">
            <textarea rows="6" placeholder="Tell us about your experience..."
              [formControl]="additionalComments"></textarea>
          </div>
          <div class="feedback-btn-section">
            <button type="button" class="btn-primary" (click)="sendFeedback()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="feedbackSuccess" id="acknowledgement-message" class="feedback-question">
      <h2>Your feedback has been sent, thank you!</h2>
      <div class="feedback-emoji">
        <div *ngIf="selectedFeedback === 1" class="feedback-emoji-img">
          <div class="very-diasatisfied-active emoji-img"></div>
          <p class="emoji-text emoji-text-active">Very Dissatisfied</p>
        </div>
        <div *ngIf="selectedFeedback === 2" class="feedback-emoji-img">
          <div class="diasatisfied-active emoji-img"></div>
          <p class="emoji-text emoji-text-active">Dissatisfied</p>
        </div>
        <div *ngIf="selectedFeedback === 3" class="feedback-emoji-img">
          <div class="neutral-active emoji-img"></div>
          <p class="emoji-text emoji-text-active">Neutral</p>
        </div>
        <div *ngIf="selectedFeedback === 4" class="feedback-emoji-img">
          <div class="satisfied-active emoji-img"></div>
          <p class="emoji-text emoji-text-active">Satisfied</p>
        </div>
        <div *ngIf="selectedFeedback === 5" class="feedback-emoji-img">
          <div class="very-satisfied-active emoji-img"></div>
          <p class="emoji-text emoji-text-active">Very Satisfied</p>
        </div>
      </div>
    </div>
  </div>
  <div class="thankyou-footer-wrap">
    <button class="btn-primary" (click)="createReferral()"   >
      Create New Referral
    </button>
    <p>Or if you are done, you can close the application.</p>
  </div>
</div>
<div *ngIf="!rrnReferral">
  <button class="feedback-btn" (click)="openFeedbackPopup()">
    <img src="../../../../assets/icons/icon_feedback.png" alt="feedback icon" />
    <p>Feedback</p>
  </button>
</div>