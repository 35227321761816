import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AE_WEB_CONST } from 'src/app/core/models/constants';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-rrn-referral',
  templateUrl: './rrn-referral.component.html',
  styleUrls: ['./rrn-referral.component.scss']
})
export class RrnReferralComponent implements OnInit {
  referralObj: any = {
    step: {
      zero: false,
      first: false,
      second: false,
      third: false,
      fourth: false,
      fifth: false,
      sixth: false,
      seventh: false,
      eighth: false,
      ninth: false,
      tenth: false,
      eleventh: false,
      twelveth: false,
      thirteenth: false,
    },
  };
  stepsMapping = AE_WEB_CONST.stepsMapping;
  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    private snackbarService: SnackbarService,
    private router: Router,  
    private titleSvc: Title    
  ) { 
    this.titleSvc.setTitle('Create Referral - CBRRN');
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras.state
    ) {
      this.referralObj.agentInformation = this.router.getCurrentNavigation().extras.state.agentInformation;
    }
  }

  ngOnInit(): void {
  }

  updateReferralObj(referralObj: any) {
    this.referralObj = { ...referralObj };
  }

  stepChanged(event: StepperSelectionEvent) {
    if (event.previouslySelectedIndex > event.selectedIndex) {
      event.previouslySelectedStep.interacted = false;
    }
  }

  scrollProgressBar(pos: string, value?: number) {
    const elementList = document.querySelectorAll(
      '.step.step--incomplete.step--active'
    );
    const element = elementList[0] as HTMLElement;
    const scrollPos = document.querySelector('.steps');
    if (pos === 'left') {
      const scrollLeftVal = element.offsetLeft + 90;
      scrollPos.scrollLeft = scrollLeftVal;
    } else {
      if (value) {
        const scrollLeftVal = element.offsetLeft - (200 * value);
        scrollPos.scrollLeft = scrollLeftVal;
      } else {
        const scrollRightVal = element.offsetLeft - 260;
        scrollPos.scrollLeft = scrollRightVal;
      }
    }
  }

  navigateToChild(step) {
    this.referralObj.flow = 'edit';
    const steps = Object.keys(this.referralObj.step);
    const idx = steps.indexOf(step.step);
    for (let i = 0; i < idx; i++) {
      this.referralObj.step[`${steps[i]}`] = true;
    } for (let j = idx; j < steps.length; j++) {
      this.referralObj.step[`${steps[j]}`] = false;
    }

    if (this.referralObj.preference === 'buy' || this.referralObj.preference === 'both') {
      this.stepper.selectedIndex = this.stepsMapping[step.step];
    } else if (this.referralObj.preference === 'sell') {
      this.stepper.selectedIndex = this.stepsMapping[step.step] > 3 ? this.stepsMapping[step.step] - 3 : this.stepsMapping[step.step];
    }
    this.scrollProgressBar('right', (this.stepper.steps.length - 1) - this.stepper.selectedIndex);
  }

  changeStep(step: string) {
    if (step === 'next') {
      this.stepper.next();
    }
  }

  displayTransitionMessage() {
    this.snackbarService.snackBarSucess(
      AE_WEB_CONST.transitionMessage,
      5000,
      'success'
    );
  }

  resetReferral() {
    this.referralObj = {  
      step: {
        zero: false,
        first: false,
        second: false,
        third: false,
        fourth: false,
        fifth: false,
        sixth: false,
        seventh: false,
        eighth: false,
        ninth: false,
        tenth: false,
        eleventh: false,
        twelveth: false,
        thirteenth: false,
      },
      agentInformation: this.referralObj.agentInformation
    }
    this.stepper.reset();
  }

}
