import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private matSpinner = new BehaviorSubject<boolean>(null);
  spinner = this.matSpinner.asObservable();

  private spinnerText = new BehaviorSubject<boolean>(null);
  insideText = this.spinnerText.asObservable();

  constructor() { }

  show() {
    this.updateSpinner(true);
  }

  hide() {
    this.updateSpinner(false);
  }

  updateSpinner(value: boolean) {
    this.matSpinner.next(value);
  }

  addSpinnerText(value: boolean) {
    this.spinnerText.next(value);
  }

}
