<div class="message-popup">
  <button class="close-button" [mat-dialog-close]="false">
    <mat-icon class="close-icon clickable" color="warn">close</mat-icon>
  </button>
  <div class="message-icon">
    <div class="success-message-icon" *ngIf="dialogData.type === 'success'">
      <img src="assets/imgs/check-icon.svg" />
    </div>
    <div class="error-message-icon" *ngIf="dialogData.type === 'error'">
      <img src="assets/imgs/error-icon.png" />
    </div>
  </div>
  <div class="dialog-box-text dialog-text">
    <p [innerHTML]="dialogData.message" class="dialog-span-text"></p>
    <div class="dialog-box-actions row">
      <button *ngIf="dialogData.action?.close" [ngClass]="dialogData.action?.close && dialogData.action?.retry ? 'btn-default' : 'btn-primary'" [mat-dialog-close]="false">Close</button>
      <button *ngIf="dialogData.action?.retry" class="btn-primary" [mat-dialog-close]="true">Retry</button>
    </div>
 </div>
</div>