import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { WarningDialogComponent } from 'src/app/core/components/warning-dialog/warning-dialog.component';
import { AE_WEB_CONST } from 'src/app/core/models/constants';
import { AgentSelectionService } from 'src/app/core/services/agent-selection.service';
import { ReferralService } from 'src/app/core/services/referral.service';

@Component({
  selector: 'app-referral-type',
  templateUrl: './referral-type.component.html',
  styleUrls: ['./referral-type.component.scss'],
})
export class ReferralTypeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() referralObj: any;
  @Input() rrnReferral = false;
  @Output() updateReferralObj = new EventEmitter<any>();
  @Output() scrollProgressBar = new EventEmitter<any>();
  @Output() saveUpdateDraft = new EventEmitter<any>();
  @Output() refTypeChanged = new EventEmitter<any>();
  @Output() resetReferral = new EventEmitter<any>();

  deleteDraftSubscription$: Subscription;
  refTypeUpdated = false;
  customerName: string;
  constructor(public dialog: MatDialog,
    private referralSvc: ReferralService,
    private agentSelectionService: AgentSelectionService
  ) { }

  ngOnInit(): void { }

  updatePreference(value: string) {
    const currentRefObj = this.referralObj;
    if ((this.referralObj['buyLocation'] || this.referralObj['sellAddress']) && value !== this.referralObj['preference']) {
      const warningDialog = this.dialog.open(WarningDialogComponent, {
        data: {
          message: AE_WEB_CONST.referralTypeChange
        },
        autoFocus: false
      });
      warningDialog.afterClosed().subscribe((action) => {
        if (action) {
          if (this.referralObj.referralId) {
            this.deleteDraftSubscription$ = this.referralSvc.deleteDraft(this.referralObj.referralId)
              .subscribe(response => console.log(response));
          }
          this.referralObj = {
            preference: value,
            step: currentRefObj.step,
            customerInfo: currentRefObj.customerInfo
          };
          if (currentRefObj.agentInformation) {
            this.referralObj['agentInformation'] = currentRefObj.agentInformation;
          }
          this.agentSelectionService.updateBuyingAgentList(null);
          this.agentSelectionService.updateSellingAgentList(null);
          this.agentSelectionService.updateBuyingAgentBestMatch(null);
          this.agentSelectionService.updateSellingAgentBestMatch(null);
          this.updateReferralObj.emit(this.referralObj);
          this.refTypeChanged.emit(true);
          this.refTypeUpdated = true;
        } else {
          this.referralObj = { ...currentRefObj };
        }
      });

      // this.referralObj = {
      //   step: currentRefObj.step,
      //   customerInfo: currentRefObj.customerInfo
      // };
    } else {
      this.refTypeUpdated = true;
      this.referralObj['preference'] = value;
      this.updateReferralObj.emit(this.referralObj);
    }
  }

  updateChange() {
    this.referralObj.step.second = true;
    this.updateReferralObj.emit(this.referralObj);
    if (this.refTypeUpdated) {
      this.referralObj.currentStep = 'Preference';
      this.saveUpdateDraft.emit(this.referralObj);
      this.refTypeUpdated = false;
    }
  }

  onBackBtnClick() {
    if (this.rrnReferral) {
      this.referralObj.step.zero = false;
    }
    this.referralObj.step.first = false;
    this.referralObj.step.second = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.referralObj) {
      this.referralObj = changes.referralObj.currentValue;
      if (this.referralObj.customerInfo) {
        this.customerName = this.referralObj.customerInfo.customerFirstName;
      }
    }
  }

  cancelReferral() {
    const cancelDialog = this.dialog.open(WarningDialogComponent, {
      data: {
        type: 'resetReferral',
        message: AE_WEB_CONST.cancelReferral
      },
      autoFocus: false
    });
    cancelDialog.afterClosed().subscribe((action) => {
      if (action) {
        this.resetReferral.emit(true);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.deleteDraftSubscription$) {
      this.deleteDraftSubscription$.unsubscribe();
    }
  }
}
