import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AgentDetails } from 'src/app/core/models/AgentDetails';
import { AE_WEB_CONST } from 'src/app/core/models/constants';
import { AgentDetailService } from 'src/app/core/services/agent-detail.service';
import { ReferralService } from 'src/app/core/services/referral.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { CurrencyPipe } from '@angular/common';
import { AgentSelectionService } from 'src/app/core/services/agent-selection.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from 'src/app/core/components/info-dialog/info-dialog.component';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { WarningDialogComponent } from 'src/app/core/components/warning-dialog/warning-dialog.component';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() referralObj: any;
  @Input() rrnReferral = false;
  @Output() updateReferralObj = new EventEmitter<any>();
  @Output() scrollProgressBar = new EventEmitter<any>();
  @Output() navigateToChild = new EventEmitter<any>();
  @Output() resetReferral = new EventEmitter<any>();

  secCustomerInfoCheck = false;
  submitReferralSubscription$: Subscription;
  agentDetails: AgentDetails;
  confirmationForm: UntypedFormGroup;
  fullName: string;
  agentInitials: string;
  userPreference: string;
  userPreferenceId: string;
  buyadditionalComments: any;
  selladditionalComments: any;
  scrolled = false;
  agents = [];
  phoneNo: number;
  position = 'right';
  buyingPropTypes: any;
  buyingAdditionalDetails: any;
  sellingLocation: any;
  sellingPropType: any;
  sellingAdditionalDetails: any;
  agentLicenseState: any;
  @ViewChild('stepper') stepper: MatStepper;
  constructor(private agentDetailSvc: AgentDetailService,
    private referralSvc: ReferralService,
    private readonly confirmationFormBuilder: UntypedFormBuilder,
    private router: Router,
    private currencyPipe: CurrencyPipe,
    private agentSelectionService: AgentSelectionService,
    public dialog: MatDialog,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit(): void {
    this.agentDetailSvc.loggedInAgentDetail.subscribe((agent: AgentDetails) => {
      if (agent) {
        this.agentDetails = agent;
        this.fullName = `${this.agentDetails.agent.firstName} ${this.agentDetails.agent.lastName}`;
        this.agentInitials =
          this.agentDetails.agent.firstName[0] +
          this.agentDetails.agent.lastName[0];
      }
    });
    this.initializeForm();
    this.scrollToTopOfPage();
  }

  initializeForm() {
    this.confirmationForm = this.confirmationFormBuilder.group({
      customerFullName: [''],
      customerEmail: [''],
      customerPhone: [''],
      buyLocation: [''],
      buyPropertyType: [''],
      buyAdditionalDetails: [''],
      buyReferralFee: [''],
      sellLocation: [''],
      sellPropertyType: [''],
      sellAdditionalDetails: [''],
      sellReferralFee: [''],
    });
  }

  navigateToSecInfo() {
    this.referralObj.secondaryInfoFlow = true;
    this.navigateTo('first');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.referralObj) {
      this.referralObj = changes.referralObj.currentValue;
      this.updateForm();
      setTimeout(() => {
        if ((this.referralObj.step.seventh && this.referralObj.preference === 'buy') ||
          (this.referralObj.step.twelveth && (this.referralObj.preference === 'sell' || this.referralObj.preference === 'both'))) {
          this.spinnerService.show();
          setTimeout(() => {
            this.spinnerService.hide();
          }, 3000);
        }
      }, 0);
      this.scrollToTopOfPage();
      if (this.rrnReferral) {
        this.agentLicenseState = AE_WEB_CONST.stateList.find(state => state.shortName === this.referralObj.agentInformation.licenseState);
      }
      if ((this.referralObj?.secCustomerInfo?.secondaryFirstName == null || this.referralObj?.secCustomerInfo?.secondaryFirstName.length == 0) &&
        (this.referralObj?.secCustomerInfo?.secondaryLastName == null || this.referralObj?.secCustomerInfo?.secondaryLastName.length == 0) &&
        (this.referralObj?.secCustomerInfo?.secondaryMobile == null || this.referralObj?.secCustomerInfo?.secondaryMobile.length == 0) &&
        (this.referralObj?.secCustomerInfo?.secondaryEmail == null || this.referralObj?.secCustomerInfo?.secondaryEmail.length == 0)) {
        this.secCustomerInfoCheck = false;
      } else {
        this.secCustomerInfoCheck = true;
      }
    }
  }

  updateForm() {
    if (this.referralObj && this.confirmationForm) {
      this.userPreference =
        this.referralObj.preference === 'buy'
          ? 'Buying'
          : this.referralObj.preference === 'sell'
            ? 'Selling'
            : 'Both';
      this.userPreferenceId = this.userPreference.toLowerCase();
      this.buyingPropTypes = '';
      this.referralObj.buyingPropertyTypes
        ? AE_WEB_CONST.propertyTypes.forEach((propType) => {
          if (this.referralObj.buyingPropertyTypes.includes(propType.value)) {
            this.buyingPropTypes = !this.buyingPropTypes
              ? propType.name
              : this.buyingPropTypes + ', ' + propType.name;
          }
        })
        : (this.buyingPropTypes = '');
      this.buyingAdditionalDetails = this.referralObj.buyingAdditionalDetails
        ? [
          this.referralObj.buyingAdditionalDetails.minPrice > 0 ||
            this.referralObj.buyingAdditionalDetails.maxPrice > 0
            ? [
              this.referralObj.buyingAdditionalDetails.minPrice > 0
                ? `${this.currencyPipe.transform(this.referralObj.buyingAdditionalDetails.minPrice
                  .replace(/\D/g, '').replace(/^0+/, ''), 'USD', 'symbol', '1.0-0')
                }`
                : 'No Minimum',
              this.referralObj.buyingAdditionalDetails.maxPrice > 0
                ? `${this.currencyPipe.transform(this.referralObj.buyingAdditionalDetails.maxPrice
                  .replace(/\D/g, '').replace(/^0+/, ''), 'USD', 'symbol', '1.0-0')
                }`
                : 'No Maximum',
            ]
              .filter(Boolean)
              .join(' to ')
            : null,
          this.referralObj.buyingAdditionalDetails.noOfBeds > 0
            ? `${this.referralObj.buyingAdditionalDetails.noOfBeds} Beds`
            : null,
          this.referralObj.buyingAdditionalDetails.noOfBaths > 0
            ? `${this.referralObj.buyingAdditionalDetails.noOfBaths} Baths`
            : null,
        ]
          .filter(Boolean)
          .join(', ')
        : '';
      this.sellingLocation =
        this.referralObj.sellAddress && this.referralObj.sellAddress.address
          ? [
            this.referralObj.sellAddress.address.streetAddress,
            this.referralObj.sellAddress.apiUnit,
            this.referralObj.sellAddress.address.city,
            this.referralObj.sellAddress.address.state,
            'USA'
          ]
            .filter(Boolean)
            .join(', ')
          : '';
      this.sellingPropType = this.referralObj.sellingPropertyType
        ? AE_WEB_CONST.propertyTypes.find(
          (item) => item.value === this.referralObj.sellingPropertyType
        ).name
        : '',
        this.sellingAdditionalDetails = this.referralObj.sellingAdditionalDetails
          ? [
            this.referralObj.sellingAdditionalDetails.ownershipType > 0
              ? AE_WEB_CONST.ownershipTypes.find(
                (type) =>
                  type.value ===
                  this.referralObj.sellingAdditionalDetails.ownershipType
              ).name
              : null,
            this.referralObj.sellingAdditionalDetails.estimationValue !== ''
              ? `Estimated Value of ${this.currencyPipe.transform(
                this.referralObj.sellingAdditionalDetails.estimationValue
                  .replace(/\D/g, '').replace(/^0+/, ''), 'USD', 'symbol', '1.0-0')}`
              : null,
          ]
            .filter(Boolean)
            .join(', ')
          : '';
      this.buyadditionalComments = this.referralObj.buyingAdditionalDetails
        ? this.referralObj.buyingAdditionalDetails.comments
        : '';
      this.selladditionalComments =
        this.referralObj.sellingAdditionalDetails &&
          this.referralObj.sellingAdditionalDetails.comments
          ? this.referralObj.sellingAdditionalDetails.comments
          : '';
      this.agents = [];
      switch (this.referralObj.preference) {
        case 'buy': {
          this.referralObj['buyingAgentSelected'] && !this.referralObj.buyOfframpReason
            ? (this.referralObj.buyingAgentSelected.hideAgentImage = !this.referralObj.buyingAgentSelected.profilePhoto,
              this.agents.push({
                type: 'Buying Agent',
                details: this.referralObj.buyingAgentSelected,
                phoneNo: this.referralObj.buyingAgentSelected.phoneNo ?
                  this.referralObj.buyingAgentSelected.phoneNo.replace(/\D/g, '').replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3')
                  : null,
                step: 'sixth',
                hideBuyAgent: true,
                hideSellAgent: false
              }))
            : this.agents.push({ type: 'Buying Agent', details: null, step: 'sixth', hideBuyAgent: true, hideSellAgent: false });
          break;
        }
        case 'sell': {
          this.referralObj['sellingAgentSelected'] && !this.referralObj.sellOfframpReason
            ? (this.referralObj.sellingAgentSelected.hideAgentImage = !this.referralObj.sellingAgentSelected.profilePhoto,
              this.agents.push({
                type: 'Selling Agent',
                details: this.referralObj.sellingAgentSelected,
                phoneNo: this.referralObj.sellingAgentSelected.phoneNo ?
                  this.referralObj.sellingAgentSelected.phoneNo.replace(/\D/g, '').replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3')
                  : null,
                step: 'eleventh',
                hideBuyAgent: false,
                hideSellAgent: true
              }))
            : this.agents.push({ type: 'Selling Agent', details: null, step: 'eleventh', hideBuyAgent: false, hideSellAgent: true });
          break;
        }
        case 'both': {
          this.referralObj['buyingAgentSelected'] && !this.referralObj.buyOfframpReason
            ? (this.referralObj.buyingAgentSelected.hideAgentImage = !this.referralObj.buyingAgentSelected.profilePhoto,
              this.agents.push({
                type: 'Buying Agent',
                details: this.referralObj.buyingAgentSelected,
                phoneNo: this.referralObj.buyingAgentSelected.phoneNo ?
                  this.referralObj.buyingAgentSelected.phoneNo.replace(/\D/g, '').replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3')
                  : null,
                step: 'sixth',
                hideBuyAgent: true,
                hideSellAgent: false
              }))
            : this.agents.push({ type: 'Buying Agent', details: null, step: 'sixth', hideBuyAgent: true, hideSellAgent: false });
          this.referralObj['sellingAgentSelected'] && !this.referralObj.sellOfframpReason
            ? (this.referralObj.sellingAgentSelected.hideAgentImage = !this.referralObj.sellingAgentSelected.profilePhoto,
              this.agents.push({
                type: 'Selling Agent',
                details: this.referralObj.sellingAgentSelected,
                phoneNo: this.referralObj.sellingAgentSelected.phoneNo ?
                  this.referralObj.sellingAgentSelected.phoneNo.replace(/\D/g, '').replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3')
                  : null,
                step: 'eleventh',
                hideBuyAgent: false,
                hideSellAgent: true
              }))
            : this.agents.push({ type: 'Selling Agent', details: null, step: 'eleventh', hideBuyAgent: false, hideSellAgent: true });
        }
      }
    }
  }

  // updateChange() {
  //   this.updateReferralObj.emit('confirmation');
  // }

  submitReferral() {
    this.spinnerService.show();
    if (this.referralObj.referralId) {
      this.submitReferralSubscription$ = this.referralSvc.updateReferralDraft(this.referralObj.referralId, this.referralObj, true)
        .subscribe(response => {
          if (response && response.status === 200) {
            this.spinnerService.hide();
            this.agentSelectionService.updateBuyingAgentList(null);
            this.agentSelectionService.updateSellingAgentList(null);
            this.agentSelectionService.updateBuyingAgentBestMatch(null);
            this.agentSelectionService.updateSellingAgentBestMatch(null);
            this.router.navigate(['thankyou']);
          } else {
            this.spinnerService.hide();
            this.dialog.open(InfoDialogComponent, {
              data: {
                type: 'error',
                message: AE_WEB_CONST.submitMessages.errorMessage,
                action: { close: true }
              },
              autoFocus: false
            });
          }
        });
    } else {
      this.submitReferralSubscription$ = this.referralSvc.submitReferral(this.referralObj, this.rrnReferral)
        .subscribe(response => {
          if (response && response.status === 201) {
            this.spinnerService.hide();
            this.agentSelectionService.updateBuyingAgentList(null);
            this.agentSelectionService.updateSellingAgentList(null);
            this.agentSelectionService.updateBuyingAgentBestMatch(null);
            this.agentSelectionService.updateSellingAgentBestMatch(null);
            this.rrnReferral ? this.router.navigate(['thankyou'], {
              state: {
                agentDetails: this.referralObj.agentInformation
              },
            }) : this.router.navigate(['thankyou']);
          } else {
            this.spinnerService.hide();
            this.dialog.open(InfoDialogComponent, {
              data: {
                type: 'error',
                message: AE_WEB_CONST.submitMessages.errorMessage,
                action: { close: true }
              },
              autoFocus: false
            });
          }
        });
    }
  }

  formatNumber(number) {
    if (number >= 1000000) {
      return number / 1000000 + 'M';
    } else {
      return number / 1000 + 'k';
    }
  }

  // onBackBtnClick() {
  //   this.scrollProgressBar.emit('right');
  //   switch (this.referralObj.preference) {
  //     case 'buy':
  //       this.referralObj.step.seventh = false;
  //       this.referralObj.step.thirteenth = false;
  //       break;
  //     default:
  //       this.referralObj.step.twelveth = false;
  //       this.referralObj.step.thirteenth = false;
  //       break;
  //   }
  // }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.rrnReferral) {
      return;
    }
    const buyingInfoSec = document.getElementById('buying');
    const sellingInfoSec = document.getElementById('selling');
    const buyingAgent = document.querySelector(
      '#agent-selected-information .agent-card'
    );
    const sellingAgent = document.querySelector('#selling .agent-card');
    if (buyingInfoSec != null && sellingInfoSec != null) {
      const buying = buyingInfoSec.offsetHeight + 100;
      // const selling = sellingInfoSec.offsetHeight;
      const pageScrollVal = window.pageYOffset;
      if (pageScrollVal > 0 && pageScrollVal <= buying) {
        buyingAgent.classList.add('agent-fixed');
        sellingAgent.classList.remove('agent-fixed');
      } else if (pageScrollVal > buying) {
        sellingAgent.classList.add('agent-fixed');
        buyingAgent.classList.remove('agent-fixed');
      } else {
        sellingAgent.classList.remove('agent-fixed');
        buyingAgent.classList.remove('agent-fixed');
      }
    }
    if (screen.width < 990) {
      buyingAgent.classList.remove('agent-fixed');
      sellingAgent.classList.remove('agent-fixed');
    }
  }
  navigateTo(step: string, hideBuyAgent = false, hideSellAgent = false) {
    const object = {
      step: step,
      hideBuyAgent: hideBuyAgent,
      hideSellAgent: hideSellAgent
    };
    this.navigateToChild.emit(object);
    this.updateReferralObj.emit(this.referralObj);
  }

  setInitials(agentDetails) {
    return agentDetails.hideAgentImage = true;
  }

  scrollToTopOfPage() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  cancelReferral() {
    this.scrollToTopOfPage();
    const cancelDialog = this.dialog.open(WarningDialogComponent, {
      data: {
        type: 'resetReferral',
        message: AE_WEB_CONST.cancelReferral
      },
      autoFocus: false
    });
    cancelDialog.afterClosed().subscribe((action) => {
      if (action) {
        this.resetReferral.emit(true);
      }
    });
  }

  ngOnDestroy() {
    if (this.submitReferralSubscription$) {
      this.submitReferralSubscription$.unsubscribe();
    }
  }

}
