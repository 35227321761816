
  // This file was autogenerated by dynamically running setEnv.ts and using dotenv for managing API key secrecy
  export const environment = {
    production: false,
    GOOGLE_API_KEY: 'AIzaSyD2qekr3ncyEGBD36Z-AOSmkbjwj3juhU4',
    CB_EMAIL_ADDRESS: 'questions@cbrefer.com',
    RRN_EMAIL_ADDRESS: 'CBRRNReferrals@nrtllc.com',
    RRN_LANDING_PAGE_LINK: 'https://cbrealtyreferralnetwork.stagingnrt.com/',
    loggerURL: '',
    URL_LOGSTASH: '',
    APP_MAINTENANCE_MODE: 0,
    SUB_REFERRAL_MAINTENANCE_MODE: 'https://www.erelocation.net/2.0.0.0/b2breferral5/referral.aspx?brokerid=266&brokerunid=ca4b0f79&t=0&tpid=',
  };
