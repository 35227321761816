<div class="referral-questions">
  <p *ngIf="refType === 'buy' && !rrnReferral">
    Where does {{ customerName }} want to buy? Please be as specific as possible. You can enter a city, neighborhood, zip code, or address.</p>
  <p *ngIf="refType === 'buy' && rrnReferral">
    Where does {{ customerName }} want to buy?
  </p>
  <p *ngIf="refType === 'sell'">
    Where is the property {{ customerName }} wants to sell?
  </p>
</div>

<section class="agent-form-section">
  <div>
    <div class="form-wrapper">
      <form [formGroup]="locationForm">
        <div class="form-container">
          <div class="input-field-section">
            <div class="row">
              <div class="input-field-form">
                <mat-form-field appearance="outline" [ngClass]="{
                    'input-field': refType === 'buy',
                    'input-field-address': refType === 'sell'
                  }">
                  <mat-label *ngIf="refType === 'buy' && !rrnReferral"><span>*</span> You can enter a city, neighborhood, zip code, or address</mat-label>
                  <mat-label *ngIf="refType === 'buy' && rrnReferral"><span>*</span> Neighborhood, City, State, Zip Code</mat-label>
                  <mat-label *ngIf="refType === 'sell'"><span>*</span> Street Address, City, State</mat-label>
                  <mat-icon matPrefix class="place-icon"><img src="../../../../../assets/images/place-icon.png"
                      class="map-pin-icon" /></mat-icon>
                <mat-spinner matSuffix *ngIf="showSpinner" class="spinner-icon" color="primary" [diameter]="25"></mat-spinner>
                <input matInput type="text" (focusout)="onFocusOut()" formControlName="searchPlace"
                  (blur)="validateAddress()" [matAutocomplete]="auto" (keyup)="onSearchPlaceInput()" />
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSearchPlaceSelected()">
                    <mat-option *ngFor="let address of addresses" [value]="address">
                      {{ address.fullAddress }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="locationForm.get('searchPlace').hasError('invalid')">
                    Address cannot be found.
                  </mat-error>
                </mat-form-field>
              </div>
              <div *ngIf="refType === 'sell'" class="input-field-form">
                <mat-form-field appearance="outline" class="input-field-address input-field-apt">
                  <mat-label>Apt/Unit #</mat-label>
                  <input matInput type="text" formControlName="apiUnit" maxlength="10" />
                </mat-form-field>
              </div>
            </div>
            <div class="agm-wrapper">
              <google-map width="100%" height="300px" [options]="mapOptions" [zoom]="zoom">
                <map-marker [position]="marker.position"></map-marker>
              </google-map>
            </div>
          </div>

          <div class="next-back-btn-section">
            <button type="button" class="back-btn" (click)="onBackBtnClick()" matStepperPrevious>
              <mat-icon>arrow_backward</mat-icon>
              <span>Back</span>
            </button>
            <button class="back-btn cancel-btn" (click)="cancelReferral()">
              <span>Cancel</span>
            </button>
            <button type="button" class="next-btn" (click)="updateChange()" [disabled]="disableNextBtn" matStepperNext>
              <span>Next</span>
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>