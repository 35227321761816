<div class="main">
  <header class="main-container container-wrap">
    <div class="brand-wrap">
      <a class="brand-link" routerLink="/home">
        <!-- standard logo -->
        <img
          [src]="brandLogo"
          alt="CBRewards Logo"
          class="brand-standard-logo"
        />
        <span>Agent Referral</span>
      </a>
    </div>
    <div class="navbar">
      <button
        mat-button
        class="nav-mobile"
        aria-label="Menu icon"
        (click)="shouldShow = !shouldShow"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <nav
        [ngClass]="{
          navshow: shouldShow,
          navhide: !shouldShow,
          'navbar-wrap': true
        }"
      >
        <ul>
          <li>
            <a
              (click)="scrollTo($event, 'main')"
              routerLink=""
              routerLinkActive="active"
              >Home</a
            >
          </li>
          <li>
            <a (click)="scrollTo($event, 'about-program')">About CB Refer</a>
          </li>
          <li>
            <a (click)="scrollTo($event, 'work-description')">How It Works</a>
          </li>
          <li><a (click)="scrollTo($event, 'promo-video')">Promo video</a></li>
          <li><a (click)="scrollTo($event, 'faqs')">FAQs</a></li>
          <li>
            <a (click)="redirectToOkta()">Log in</a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</div>

<app-image-cta
  [brandProperties]="brandProperties"
  [imgContent]="brandProperties.firstImgContent"
></app-image-cta>

<app-program-description
  [brandProperties]="brandProperties"
></app-program-description>
<app-work-description
  [brandProperties]="brandProperties"
></app-work-description>

<app-brand-video [brandProperties]="brandProperties"></app-brand-video>

<app-image-cta
  [brandProperties]="brandProperties"
  [imgContent]="brandProperties.secondImgContent"
></app-image-cta>

<app-faq></app-faq>
<footer class="footer">
  <div class="container-wrap">
    <div class="footer-wrap">
      <p>
        <img
          width="300"
          height="31"
          src="https://www.cbrealtyrewards.com/wp-content/uploads/2020/05/CB_Logo_RGB_White_Horizontal_Realty300w.png"
          alt="CBR"
        />
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore magnam
        quia ullam rerum odit totam. Consequuntur autem deleniti atque commodi
        a, exercitationem quia! Consequuntur, cumque excepturi labore a eaque
        nesciunt culpa esse odit. Cupiditate quam maiores minima suscipit eum et
        necessitatibus aut perspiciatis, incidunt praesentium quidem reiciendis
        eligendi quia eius doloremque quis consequuntur. Officia natus nobis
        commodi totam, minima similique architecto ea perspiciatis harum at
        aliquid laborum repudiandae vel quasi! Consequatur harum vitae ex
        aliquid modi dicta non numquam. Nemo maiores officiis nesciunt eveniet
        libero voluptas repellendus dolorum animi ullam, expedita consequuntur
        veritatis dolore iste culpa necessitatibus odit sed, quam, ea tempore.
        Commodi accusamus necessitatibus, corporis officia praesentium autem!
        Voluptas maxime corrupti consequatur aspernatur vitae accusamus odio
        porro ab eaque, esse id iure, labore aperiam harum necessitatibus
        praesentium officia? Asperiores et inventore rem magni placeat, saepe
        animi sint, sapiente non aut eligendi cum? Perferendis iure molestiae
        maiores, recusandae repellendus officia
      </p>
    </div>
  </div>
</footer>
<a
  *ngIf="isShown"
  (click)="scrollTo($event, 'ng-star-inserted')"
  class="scrolltop"
>
  <mat-icon>keyboard_arrow_up</mat-icon>
</a>
