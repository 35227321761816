import { CurrencyPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { WarningDialogComponent } from 'src/app/core/components/warning-dialog/warning-dialog.component';
import { AE_WEB_CONST } from 'src/app/core/models/constants';
import { MyErrorStateMatcher, comparePrice } from 'src/app/core/models/MyErrorStateMatcher'

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss'],
})
export class AdditionalInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() referralObj: any;
  @Input() refType: string;
  @Input() rrnReferral = false;
  @Output() updateReferralObj = new EventEmitter<any>();
  @Output() scrollProgressBar = new EventEmitter<any>();
  @Output() saveUpdateDraft = new EventEmitter<any>();
  @Output() displayTransitionMessage = new EventEmitter<any>();
  @Output() resetReferral = new EventEmitter<any>();

  customerName: string;
  placeholder: string;
  numberOfRooms = AE_WEB_CONST.noOfRooms;
  additionalInfoForm: UntypedFormGroup;
  additionalCommentsMaxLength = AE_WEB_CONST.commentsLength;
  minPriceRange = JSON.parse(JSON.stringify(AE_WEB_CONST.minPriceRange));
  maxPriceRange = JSON.parse(JSON.stringify(AE_WEB_CONST.maxPriceRange));
  ownershipTypes = AE_WEB_CONST.ownershipTypes;
  currentReferralObj: any;
  @ViewChild('addlInfoForm', { static: false })
  addlInfoForm: FormGroupDirective;
  matcher = new MyErrorStateMatcher();

  constructor(
    private referralFormBuilder: UntypedFormBuilder,
    private currencyPipe: CurrencyPipe,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.initialiseForm();
    if (this.refType === 'buy') {
      this.placeholder = this.rrnReferral
        ? 'Requested agent info (name/contact info), etc.'
        : 'Requested Agent, Preferred Language, Neighborhood/City, Specific Needs...';
    }
    if (this.refType === 'sell') {
      this.placeholder = this.rrnReferral
        ? 'Requested agent info (name/contact info), etc.'
        : 'Requested Agent, Preferred Language, Selling Timeline, Specific Needs...';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.referralObj) {
      this.referralObj = changes.referralObj.currentValue;
      if (this.referralObj.customerInfo) {
        this.customerName = this.referralObj.customerInfo.customerFirstName;
      }
      switch (this.refType) {
        case 'buy': {
          if (
            this.referralObj.step.fourth &&
            !this.referralObj['buyingAdditionalDetails']
          ) {
            this.addlInfoForm.resetForm();
            this.initialiseForm();
          }
          if (
            this.referralObj['buyingAdditionalDetails'] &&
            this.additionalInfoForm &&
            JSON.stringify(this.referralObj.buyingAdditionalDetails) !==
            JSON.stringify(AE_WEB_CONST.buyingAdditionalInitialValues)
          ) {
            this.additionalInfoForm.patchValue({
              minPrice:
                Number(this.referralObj.buyingAdditionalDetails.minPrice) >= 0
                  || this.referralObj.buyingAdditionalDetails.minPrice === 'No Minimum'
                  ? this.referralObj.buyingAdditionalDetails.minPrice
                  : '',
              maxPrice:
                Number(this.referralObj.buyingAdditionalDetails.maxPrice) >= 0
                  || this.referralObj.buyingAdditionalDetails.maxPrice === 'No Maximum'
                  ? this.referralObj.buyingAdditionalDetails.maxPrice
                  : '',
              noOfBeds: this.referralObj.buyingAdditionalDetails.noOfBeds,
              noOfBaths: this.referralObj.buyingAdditionalDetails.noOfBaths,
              buyingComments: this.referralObj.buyingAdditionalDetails.comments,
            });
            this.additionalInfoForm.markAsDirty();
            this.additionalInfoForm.updateValueAndValidity();
          }
          break;
        }
        case 'sell': {
          if (
            (this.referralObj.step.ninth &&
              !this.referralObj['sellingAdditionalDetails']) ||
            (this.rrnReferral &&
              this.referralObj.step.seventh &&
              !this.referralObj['sellingAdditionalDetails'])
          ) {
            this.addlInfoForm.resetForm();
            this.initialiseForm();
          }
          if (
            this.referralObj['sellingAdditionalDetails'] &&
            this.additionalInfoForm &&
            JSON.stringify(this.referralObj.sellingAdditionalDetails) !==
            JSON.stringify(AE_WEB_CONST.sellingAdditionalInitialValues)
          ) {
            this.additionalInfoForm.patchValue({
              ownershipType:
                this.referralObj.sellingAdditionalDetails.ownershipType,
              estimationValue:
                Number(
                  this.referralObj.sellingAdditionalDetails.estimationValue
                ) > 0
                  ? this.referralObj.sellingAdditionalDetails.estimationValue
                  : '',
              sellingComments:
                this.referralObj.sellingAdditionalDetails.comments,
            });
            this.additionalInfoForm.markAsDirty();
            this.additionalInfoForm.updateValueAndValidity();
          }
          break;
        }
      }
      this.currentReferralObj = JSON.parse(
        JSON.stringify(changes.referralObj.currentValue)
      );
    }
  }

  initialiseForm() {
    this.additionalInfoForm = this.referralFormBuilder.group({
      minPrice: [],
      maxPrice: [],
      noOfBeds: [0],
      noOfBaths: [0],
      ownershipType: [0],
      estimationValue: [],
      buyingComments: [],
      sellingComments: [],
    }, { validators: comparePrice });
    this.additionalInfoForm.get('minPrice').valueChanges.subscribe((value) => {
      this.maxPriceRange = JSON.parse(
        JSON.stringify(AE_WEB_CONST.maxPriceRange)
      );
      if (!value || value === 'none' || value === 0) {
        if (value === 0) {
          this.additionalInfoForm.get('minPrice')
            .setValue('No Minimum', { emitEvent: false })
        }
        return;
      } else {
        const maxPriceIndex = AE_WEB_CONST.maxPriceRange.findIndex(
          (item) => item.value === value
        );
        this.maxPriceRange.splice(0, maxPriceIndex + 1);
      }
    });
    this.additionalInfoForm.get('maxPrice').valueChanges.subscribe((value) => {
      this.minPriceRange = JSON.parse(
        JSON.stringify(AE_WEB_CONST.minPriceRange)
      );
      if (!value || value === 'none' || value === 1000000 || value === 0) {
        if (value === 0) {
          this.additionalInfoForm.get('maxPrice')
            .setValue('No Maximum', { emitEvent: false })
        }
        return;
      } else {
        const minPriceIndex = AE_WEB_CONST.minPriceRange.findIndex(
          (item) => item.value === value
        );
        this.minPriceRange.splice(minPriceIndex);
      }
    });
    this.additionalInfoForm
      .get('estimationValue')
      .valueChanges.subscribe((value) => {
        if (value) {
          this.additionalInfoForm
            .get('estimationValue')
            .setValue(
              this.currencyPipe.transform(
                this.additionalInfoForm
                  .get('estimationValue')
                  .value.replace(/\D/g, '')
                  .substring(0, 10)
                  .replace(/^0+/, ''),
                'USD',
                'symbol',
                '1.0-0'
              ),
              { emitEvent: false }
            );
        }
      });
    this.additionalInfoForm
      .get('minPrice')
      .valueChanges.subscribe((value) => {
        if (value) {
          this.additionalInfoForm
            .get('minPrice')
            .setValue(
              this.currencyPipe.transform(
                this.additionalInfoForm
                  .get('minPrice')
                  .value.toString()
                  .replace(/\D/g, '')
                  .substring(0, 9)
                  .replace(/^0+/, ''),
                'USD',
                'symbol',
                '1.0-0'
              ),
              { emitEvent: false }
            );
        }
      });
    this.additionalInfoForm
      .get('maxPrice')
      .valueChanges.subscribe((value) => {
        if (value) {
          this.additionalInfoForm
            .get('maxPrice')
            .setValue(
              this.currencyPipe.transform(
                this.additionalInfoForm
                  .get('maxPrice')
                  .value.toString()
                  .replace(/\D/g, '')
                  .substring(0, 9)
                  .replace(/^0+/, ''),
                'USD',
                'symbol',
                '1.0-0'
              ),
              { emitEvent: false }
            );
        }
      });
  }

  SpliceMaxPriceRange(event) {
    this.maxPriceRange = JSON.parse(
      JSON.stringify(AE_WEB_CONST.maxPriceRange)
    );
    const currentMinPriceValue = Number(event.target.value.replace(/\D/g, ''));
    if (currentMinPriceValue === 0) {
      return
    }
    if (currentMinPriceValue > 999999) {
      this.maxPriceRange.splice(0, 10);
    }
    else {
      const maxPriceIndex = AE_WEB_CONST.maxPriceRange.findIndex(
        (item) => item.value > currentMinPriceValue
      );
      this.maxPriceRange.splice(0, maxPriceIndex);
    }
  }

  SpliceMinPriceRange(event) {
    this.minPriceRange = JSON.parse(
      JSON.stringify(AE_WEB_CONST.minPriceRange)
    );
    const currentMaxPriceValue = Number(event.target.value.replace(/\D/g, ''));
    if (currentMaxPriceValue === 0) {
      return
    }
    if (currentMaxPriceValue > 900000) {
      this.minPriceRange.splice(10);
    }
    else {
      const minPriceIndex = AE_WEB_CONST.minPriceRange.findIndex(
        (item) => item.value >= currentMaxPriceValue
      );
      this.minPriceRange.splice(minPriceIndex);
    }
  }

  onBackBtnClick() {
    this.scrollProgressBar.emit('right');
    this.updateAdditionalDetails();
    this.saveReferral();
    if (this.refType === 'buy') {
      // If block is for RNI flow and else block is for CBR flow.
      if (this.rrnReferral) {
        this.referralObj.step.fourth = this.referralObj.step.fifth = false; 
      } else {
      this.referralObj.step.fourth = false;
      this.referralObj.step.seventh = false;
      }
    }
    if (this.refType === 'sell') {
      if (this.rrnReferral) {
        this.referralObj.step.seventh = false;
        this.referralObj.step.eighth = false;
      } else {
        this.referralObj.step.ninth = false;
        this.referralObj.step.twelveth = false;
      }
    }
  }

  updateChange() {
    this.scrollProgressBar.emit('left');
    this.updateAdditionalDetails();
    if (this.refType === 'buy') {
      if (this.rrnReferral) {
      this.referralObj.step.fifth = true;
    } else {
      this.referralObj.step.seventh = true;
    }
    }
    if (this.refType === 'sell') {
      if (this.rrnReferral) {
        this.referralObj.step.eighth = true;
      } else {
        this.referralObj.step.twelveth = true;
      }
    }
    this.updateReferralObj.emit(this.referralObj);
    if (this.referralObj.preference === 'both' && this.refType === 'buy') {
      this.displayTransitionMessage.emit();
    }
    this.saveReferral();
  }

  updateAdditionalDetails() {
    switch (this.refType) {
      case 'buy': {
        this.referralObj['buyingAdditionalDetails'] = {
          minPrice:
            this.additionalInfoForm.get('minPrice').value !== null
              && this.additionalInfoForm.get('minPrice').value !== 'No Minimum'
              ? this.additionalInfoForm.get('minPrice').value.replace(/\D/g, '')
              : 0,
          maxPrice:
            this.additionalInfoForm.get('maxPrice').value !== null
              && this.additionalInfoForm.get('maxPrice').value !== 'No Maximum'
              ? this.additionalInfoForm.get('maxPrice').value.replace(/\D/g, '')
              : 0,
          noOfBeds: this.additionalInfoForm.get('noOfBeds').value,
          noOfBaths: this.additionalInfoForm.get('noOfBaths').value,
          comments: this.additionalInfoForm.get('buyingComments').value,
        };
        break;
      }
      case 'sell': {
        this.referralObj['sellingAdditionalDetails'] = {
          ownershipType: this.additionalInfoForm.get('ownershipType').value,
          estimationValue: this.additionalInfoForm.get('estimationValue').value
            ? this.additionalInfoForm
              .get('estimationValue')
              .value.replace(/\D/g, '')
            : '',
          comments: this.additionalInfoForm.get('sellingComments').value,
        };
        break;
      }
    }
  }

  saveReferral() {
    switch (this.refType) {
      case 'buy': {
        if (
          JSON.stringify(this.currentReferralObj.buyingAdditionalDetails) !==
          JSON.stringify(this.referralObj.buyingAdditionalDetails) &&
          !this.additionalInfoForm.pristine &&
          this.referralObj.step.fourth
        ) {
          this.referralObj.currentStep = 'Buying Additional Details';
          this.saveUpdateDraft.emit(this.referralObj);
        }
        break;
      }
      case 'sell': {
        if (
          JSON.stringify(this.currentReferralObj.sellingAdditionalDetails) !==
          JSON.stringify(this.referralObj.sellingAdditionalDetails) &&
          !this.additionalInfoForm.pristine &&
          this.referralObj.step.ninth
        ) {
          this.referralObj.currentStep = 'Selling Additional Details';
          this.saveUpdateDraft.emit(this.referralObj);
        }
        break;
      }
    }
  }

  cancelReferral() {
    const cancelDialog = this.dialog.open(WarningDialogComponent, {
      data: {
        type: 'resetReferral',
        message: AE_WEB_CONST.cancelReferral,
      },
      autoFocus: false,
    });
    cancelDialog.afterClosed().subscribe((action) => {
      if (action) {
        this.resetReferral.emit(true);
      }
    });
  }

  ngOnDestroy() {
    this.updateAdditionalDetails();
    this.saveReferral();
  }
}
