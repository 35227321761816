<section class="agent-form-section">
  <div>
    <div class="form-wrapper" *ngIf="showSecondaryInfo.first">
      <form #custInfoForm="ngForm" [formGroup]="customerInfoForm">
        <!-- Customer Information -->
        <div class="form-container">
          <div class="referral-questions">
            <p>Tell us about your customer…</p>
          </div>
          <div class="input-field-section">
            <div class="row">
              <div class="input-field-form">
                <mat-form-field appearance="outline">
                  <mat-label><span>*</span> First Name</mat-label>
                  <input matInput type="text" formControlName="customerFirstName" maxLength="30" />
                  <mat-error *ngIf="
                      customerInfoForm
                        .get('customerFirstName')
                        .hasError('required')
                    ">
                    Please enter First Name.
                  </mat-error>
                  <mat-error *ngIf="
                      customerInfoForm
                        .get('customerFirstName')
                        .hasError('pattern')
                    ">
                    Please use only letters, a space, and -' characters.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="input-field-form">
                <mat-form-field appearance="outline">
                  <mat-label><span>*</span> Last Name</mat-label>
                  <input matInput type="text" formControlName="customerLastName" maxLength="30" />
                  <mat-error *ngIf="
                      customerInfoForm
                        .get('customerLastName')
                        .hasError('required')
                    ">
                    Please enter Last Name.
                  </mat-error>
                  <mat-error *ngIf="
                      customerInfoForm
                        .get('customerLastName')
                        .hasError('pattern')
                    ">
                    Please use only letters, a space, and -' characters.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="input-field-form">
                <mat-form-field appearance="outline">
                  <mat-label><span>*</span> Email</mat-label>
                  <input matInput type="text" formControlName="customerEmail" maxLength="50" />
                  <mat-error>Please enter a valid email address.</mat-error>
                </mat-form-field>
              </div>
              <div class="input-field-form">
                <mat-form-field appearance="outline">
                  <mat-label><span>*</span> Phone Number</mat-label>
                  <input matInput type="text" formControlName="customerPhone" phoneFormat maxlength="14" />
                  <mat-error *ngIf="
                      customerInfoForm.get('customerPhone').hasError('required')
                    ">
                    Please enter Phone Number.</mat-error>
                  <mat-error *ngIf="
                      customerInfoForm
                        .get('customerPhone')
                        .hasError('minLength')
                    ">
                    Please enter a valid Phone Number.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="add-secondary-wrap">
              <button class="add-secondary-btn" (click)="showSecondaryInfo.second = !showSecondaryInfo.second;showSecondaryInfo.first = false;" [disabled]="customerInfoForm.invalid">Add secondary contact information</button>
            </div>
          </div>
          <div class="next-back-btn-section">
            <button class="back-btn" [style.visibility]="rrnReferral ? 'visible' : 'hidden'" matStepperPrevious>
              <mat-icon>arrow_back</mat-icon><span>Back</span>
            </button>
            <button type="button" class="back-btn cancel-btn" (click)="cancelReferral()">
              <span>Cancel</span>
            </button>
            <button class="next-btn" (click)="updateChange()" matStepperNext [disabled]="customerInfoForm.invalid">
              <span>Next</span>
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="form-wrapper secondary-form-wrapper" *ngIf="showSecondaryInfo.second">
      <form #secContactInfoForm="ngForm" [formGroup]="secondaryContactInfoForm">
        <!-- Secondary Contact Information -->
       <div class="form-container">
          <div class="referral-questions">
            <p>Enter secondary contact information…</p>
          </div>
          <div class="input-field-section">
            <div class="row">
              <div class="input-field-form">
                <mat-form-field appearance="outline">
                  <mat-label>Secondary First Name</mat-label>
                  <input matInput type="text" formControlName="secondaryFirstName" maxLength="30" />
                  <mat-error *ngIf="
                      secondaryContactInfoForm
                        .get('secondaryFirstName')
                        .hasError('pattern')
                    ">
                    Please use only letters, a space, and -' characters.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="input-field-form">
                <mat-form-field appearance="outline">
                  <mat-label>Secondary Last Name</mat-label>
                  <input matInput type="text" formControlName="secondaryLastName" maxLength="30" />
                   
                  <mat-error *ngIf="
                      secondaryContactInfoForm
                        .get('secondaryLastName')
                        .hasError('pattern')
                    ">
                    Please use only letters, a space, and -' characters.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="input-field-form">
                <mat-form-field appearance="outline">
                  <mat-label>Secondary Email</mat-label>
                  <input matInput type="text" formControlName="secondaryEmail" maxLength="50" />
                  <mat-error>Please enter a valid secondary email address.</mat-error>
                </mat-form-field>
              </div>
              <div class="input-field-form">
                <mat-form-field appearance="outline">
                  <mat-label>Secondary Phone Number</mat-label>
                  <input matInput type="text" formControlName="secondaryMobile" phoneFormat maxlength="14" />
                  <mat-error *ngIf="
                      secondaryContactInfoForm
                        .get('secondaryMobile')
                        .hasError('minLength')
                    ">
                    Please enter a valid Phone Number.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="add-secondary-wrap">
              <button class="add-secondary-btn" (click)="showSecondaryInfo.first = !showSecondaryInfo.first;showSecondaryInfo.second = false;"><mat-icon>chevron_left</mat-icon>Back</button>
            </div>
          </div>
          
          <div class="next-back-btn-section">
            <button class="back-btn" [style.visibility]="rrnReferral ? 'hidden' : 'hidden'" matStepperPrevious>
              <mat-icon>arrow_back</mat-icon><span>Back</span>
            </button>
            <button type="button"  [style.visibility]="rrnReferral ? 'hidden' : 'hidden'" class="back-btn cancel-btn" (click)="cancelReferral()">
              <span>Cancel</span>
            </button>
            <button class="next-btn" (click)="updateChange()" matStepperNext [disabled]="secondaryContactInfoForm.invalid">
              <span>Next</span>
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </div>
        </div>
      </form> 
    </div>
  </div>
</section>