import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { catchError, map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AgentProfileService {

    /**
     * Base Constructor
     * @param baseClientSvc Instance of BaseClientService
     * @param logSvc Instance of RemoteLoggingService
     */
    constructor(
        private baseClient: BaseClientService,
        private logSvc: RemoteLoggingService
    ) { }


    getAgentPrfile(paramsObj: any): Observable<any> {
        let httpParams: HttpParams = new HttpParams();
        Object.keys(paramsObj).forEach(function (key) {
            httpParams = httpParams.append(key, paramsObj[key]);
        });
        return this.baseClient.getArr(`/b2b/getViewProfile`, 'get agent prfile', 'agents_api', httpParams)
            .pipe(
                map(r => r.body),
                catchError(err => {
                    const emptyArr = [];
                    this.logSvc.logError(err);
                    return of(emptyArr);
                })
            );
    }

}
