import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ReferralService } from 'src/app/core/services/referral.service';
import { MatDialog } from '@angular/material/dialog';
import { ActionDialogComponent } from 'src/app/core/components/action-dialog/action-dialog.component';
import { InfoDialogComponent } from 'src/app/core/components/info-dialog/info-dialog.component';
import { Router } from '@angular/router';
import { AE_WEB_CONST } from 'src/app/core/models/constants';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { FeedbackComponent } from 'src/app/core/components/feedback/feedback.component';
import { FeedbackService } from 'src/app/core/services/feedback.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';

@Component({
  selector: 'app-drafts-referrals',
  templateUrl: './drafts-referrals.component.html',
  styleUrls: ['./drafts-referrals.component.scss'],
})
export class DraftsReferralsComponent implements OnInit, OnDestroy {
  // MatPaginator Inputs
  pageInfo = {
    pageSize: 5,
    pageSizeOptions: [5, 10, 20],
    totalCount: 0,
    pageIndex: 0
  }
  // MatPaginator Output
  pageEvent: PageEvent;
  position = 'right';

  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  draftList: any = [];
  referralSubscription: Subscription;
  device: string;
  date: Date;
  constructor(
    private titleSvc: Title,
    private referralService: ReferralService,
    private router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private feedbackSvc: FeedbackService,
    private snackbarService: SnackbarService,
    private spinnerService: SpinnerService
  ) {
    this.titleSvc.setTitle('CB Refer - Drafts');
    screen.width < AE_WEB_CONST.mobileWidth ? this.pageInfo.pageSize = 5 : this.pageInfo.pageSize = 10;
  }

  ngOnInit(): void {
    this.getDraftList(this.pageInfo.pageSize, this.pageInfo.pageIndex);
    this.date = new Date();
  }

  getDraftList(limit: number, skip: number) {
    this.spinnerService.show();
    this.referralSubscription = this.referralService
      .getDraftList(limit, skip)
      .subscribe((drafts) => {
        if (drafts && drafts.status === 200) {
          this.paginator.length = drafts.body.count;
          this.draftList = drafts.body.data;
          this.draftList.map((draft) => {
            draft.fullName = `${draft.customerInfo.firstName} ${draft.customerInfo.lastName}`;
            const service = draft.service;
            service.length > 1
              ? (draft.type = 'Both')
              : (draft.type = service[0].type);
          });
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          const draftListDialog = this.dialog.open(InfoDialogComponent, {
            data: {
              type: 'error',
              message: AE_WEB_CONST.saveMessages.errorMessage,
              action: { close: true, retry: true },
            },
            autoFocus: false
          });
          draftListDialog.afterClosed().subscribe((action) => {
            if (action) {
              this.getDraftList(10, 0);
            }
          });
        }
      });
  }

  editReferral(id: string) {
    this.router.navigate(['createreferral'], {
      state: {
        referralDraftId: id,
      },
    });
  }

  confirmDelete(draft) {
    const deleteDialog = this.dialog.open(ActionDialogComponent, {
      data: {
        message: AE_WEB_CONST.deleteDraft.confirmMessage,
        action: { cancel: true, delete: true }
      },
      autoFocus: false
    });
    deleteDialog.afterClosed().subscribe((action) => {
      if (action) {
        this.spinnerService.show();
        this.referralService
          .deleteDraft(draft.referralId)
          .subscribe((response: any) => {
            if (
              response &&
              response.message === 'Record deleted successfully'
            ) {
              const successDialogRef = this.dialog.open(InfoDialogComponent, {
                data: {
                  type: 'success',
                  message: AE_WEB_CONST.deleteDraft.successMessage,
                },
                autoFocus: false
              });
              // const index = this.draftList.indexOf(draft);
              // this.draftList.splice(index, 1);
              // this.paginator.length = this.paginator.length - 1;
              this.getDraftList(this.pageInfo.pageSize, this.pageInfo.pageIndex);
              successDialogRef.afterOpened().subscribe(() => {
                setTimeout(() => {
                  successDialogRef.close();
                }, 3000);
              });
              this.spinnerService.hide();
            } else {
              this.dialog.open(InfoDialogComponent, {
                data: {
                  type: 'error',
                  message: AE_WEB_CONST.saveMessages.errorMessage,
                  action: { close: true },
                },
                autoFocus: false
              });
              this.spinnerService.hide();
            }
          });
      }
    });
  }
  openFeedbackPopup() {
    const feedbackDialog = this.dialog.open(FeedbackComponent, {
      autoFocus: false,
      panelClass: 'feedback-panel-section',
      backdropClass: 'feedback-backdrop'
    });
    feedbackDialog.afterClosed().subscribe(feedback => {
      if (feedback) {
        feedback['currentScreen'] = 'Drafts';
        this.feedbackSvc.submitFeedback(feedback).subscribe(response => {
          if (response && response.status === 201) {
            this.snackbarService.snackBarSucess(
              AE_WEB_CONST.feedbackSuccessMessage,
              3000,
              'feedback'
            );
          }
        });
      }
    });
  }
  onPagination(event) {
    this.pageInfo.pageSize = this.paginator.pageSize;
    this.getDraftList(this.paginator.pageSize, this.paginator.pageIndex * this.paginator.pageSize)
  }

  ngOnDestroy() {
    this.referralSubscription.unsubscribe();
  }
}
