<div class="left-nav-wrapper">
  <ul class="left-nav-wrapper-menus">
    <li>
      <a routerLink="/createreferral" routerLinkActive="active">
        <mat-icon matTooltip="Create Referral" [matTooltipPosition]="position"><img
            src="../../../../assets/imgs/create-menu-icon.png" /></mat-icon>
      </a>
    </li>
    <li>
      <a routerLink="/drafts" routerLinkActive="active">
        <mat-icon matTooltip="Drafts" [matTooltipPosition]="position"><img
            src="../../../../assets/imgs/drafts-menu-icon.png" /></mat-icon>
      </a>
    </li>
    <li>
      <a class="tooltip">
        <small>Questions</small>
        <mat-icon style="font-size: 32px; margin-right: 0px; margin-top: 5px;">help_outline</mat-icon>
        <span class="tooltiptext">Need assistance?<span style="display: inline-flex;font-weight: 700;"><a
              href="mailTo:questions@cbrefer.com" class="email-us">Email
              us directly.</a>
            <mat-icon class="email-icon">email</mat-icon>
          </span>
        </span>
      </a>
    </li>
  </ul>
</div>