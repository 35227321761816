<div class="main promo-video">
    <div class="main-container container-wrap">
      <div class="left-container">
        <h2 class="title-left">{{ brandProperties.video_heading_text }}</h2>
        <p>{{ brandProperties.video_content_text }}</p>
      </div>
      <div class="right-container">
<iframe title="Brand Promo Video" id="videoPlayer" [src]="videoSource" allow="gyroscope; fullscreen"
    allowfullscreen></iframe>

</div>
</div>
</div>