import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) { }

  snackBarSucess(message: string, duration: number, type = '') {
    return this.snackBar.openFromComponent(SnackbarComponent, {
      duration, data: { message: message, type: type }, panelClass: type
    });
  }

}
