import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Title } from '@angular/platform-browser';
import { ReferralService } from 'src/app/core/services/referral.service';
import { AE_WEB_CONST } from 'src/app/core/models/constants';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { InfoDialogComponent } from 'src/app/core/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { WarningDialogComponent } from 'src/app/core/components/warning-dialog/warning-dialog.component';
import { FeedbackComponent } from 'src/app/core/components/feedback/feedback.component';
import { FeedbackService } from 'src/app/core/services/feedback.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';

@Component({
  selector: 'app-create-referral',
  templateUrl: './create-referral.component.html',
  styleUrls: ['./create-referral.component.scss'],
})
export class CreateReferralComponent implements OnInit, OnDestroy {
  referralObj: any = {
    step: {
      first: false,
      second: false,
      third: false,
      fourth: false,
      fifth: false,
      sixth: false,
      seventh: false,
      eighth: false,
      ninth: false,
      tenth: false,
      eleventh: false,
      twelveth: false,
      thirteenth: false,
    },
  };
  referralDraftId: string;
  draftLimitMessage = false;
  @ViewChild('stepper') stepper: MatStepper;
  stepsMapping = AE_WEB_CONST.stepsMapping;
  updateDraftSubscription$: Subscription;
  saveDraftSubscription$: Subscription;
  getReferralDraftSubscription$: Subscription;
  constructor(
    private titleSvc: Title,
    private referralSvc: ReferralService,
    private snackbarService: SnackbarService,
    private router: Router,
    public dialog: MatDialog,
    private feedbackSvc: FeedbackService,
    private spinnerService: SpinnerService
  ) {
    this.titleSvc.setTitle('CB Refer - Create Referral');
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras.state
    ) {
      this.referralDraftId = this.router.getCurrentNavigation().extras.state.referralDraftId;
    }
  }

  ngOnInit(): void {
    if (this.referralDraftId) {
      this.spinnerService.show();
      this.getReferralDraftSubscription$ = this.referralSvc
        .getDraftById(this.referralDraftId)
        .subscribe((response) => {
          if (response && response.status === 200) {
            this.populateDraftData(response.body);
            this.spinnerService.hide();
          } else {
            this.spinnerService.hide();
            const dialogRef = this.dialog.open(InfoDialogComponent, {
              data: {
                type: 'error',
                message: AE_WEB_CONST.getDraft.errorMessage,
                action: { close: true }
              },
              autoFocus: false
            });
            dialogRef.afterClosed().subscribe(() => {
              this.router.navigate(['/drafts']);
            });
          }
        });
    }
  }

  updateReferralObj(referralObj: any) {
    this.referralObj = { ...referralObj };
  }

  stepChanged(event: StepperSelectionEvent) {
    if (event.previouslySelectedIndex > event.selectedIndex) {
      event.previouslySelectedStep.interacted = false;
    }
  }

  scrollProgressBar(pos: string, value?: number) {
    const elementList = document.querySelectorAll(
      '.step.step--incomplete.step--active'
    );
    const element = elementList[0] as HTMLElement;
    const scrollPos = document.querySelector('.steps');
    if (pos === 'left') {
      const scrollLeftVal = element.offsetLeft + 90;
      scrollPos.scrollLeft = scrollLeftVal;
    } else {
      if (value) {
        const scrollLeftVal = element.offsetLeft - (200 * value);
        scrollPos.scrollLeft = scrollLeftVal;
      } else {
        const scrollRightVal = element.offsetLeft - 260;
        scrollPos.scrollLeft = scrollRightVal;
      }
    }
  }

  saveUpdateDraft(referralObj) {
    if (referralObj.referralId) {
      this.updateDraftSubscription$ = this.referralSvc
        .updateReferralDraft(referralObj.referralId, referralObj)
        .subscribe((response) => {
          if (response && response.status === 200) {
            const snackBarRef = this.snackbarService.snackBarSucess(
              AE_WEB_CONST.saveMessages.successMessage,
              3000
            );
            snackBarRef.afterDismissed().subscribe(() => {
              if (referralObj.showTransitionMessage) {
                this.displayTransitionMessage();
              }
            });
          } else {
            this.dialog.open(InfoDialogComponent, {
              data: {
                type: 'error',
                message: AE_WEB_CONST.saveMessages.errorMessage,
                action: { close: true }
              },
              autoFocus: false
            });
          }
        });
    } else {
      this.saveDraftSubscription$ = this.referralSvc
        .saveReferral(referralObj)
        .subscribe((response) => {
          if (response && response.status === 201) {
            this.snackbarService.snackBarSucess(
              AE_WEB_CONST.saveMessages.successMessage,
              3000
            );
            this.referralObj.referralId = response.body.referralId;
            this.draftLimitMessage = false;
          } else {
            if (response.message === AE_WEB_CONST.saveMessages.maxDraftMessage) {
              if (!this.draftLimitMessage) {
                this.draftLimitMessage = true;
                const warningDialog = this.dialog.open(WarningDialogComponent, {
                  data: {
                    message: AE_WEB_CONST.saveMessages.maxDraftErrorMessage,
                    type: 'draftLimit'
                  },
                  autoFocus: false,
                  disableClose: true
                });
                warningDialog.afterClosed().subscribe(action => {
                  if (action) {
                    this.router.navigate(['/drafts']);
                  }
                });
              }
            } else {
              this.dialog.open(InfoDialogComponent, {
                data: {
                  type: 'error',
                  message: AE_WEB_CONST.saveMessages.errorMessage,
                  action: { close: true }
                },
                autoFocus: false
              });
            }
          }
        });
    }
  }

  navigateToChild(step) {
    this.referralObj.hideBuySelection = true;
    this.referralObj.hideSellLocation = true;
    const steps = Object.keys(this.referralObj.step);
    const idx = steps.indexOf(step.step);
    for (let i = 0; i <= idx; i++) {
      this.referralObj.step[`${steps[i]}`] = true;
    }
    for (let j = idx + 1; j <= steps.length; j++) {
      this.referralObj.step[`${steps[j]}`] = false;
    }
    this.referralObj.flow = 'edit';
    if (step.hideBuyAgent === true) {
      this.referralObj.hideBuySelection = false;
    }
    if (step.hideSellAgent === true) {
      this.referralObj.hideSellLocation = false;
    }

    if (this.referralObj.preference === 'buy') {
      this.stepper.selectedIndex =
        this.stepsMapping[step.step] === 1 ? 0 : this.stepsMapping[step.step];
    } else if (this.referralObj.preference === 'sell') {
      this.stepper.selectedIndex =
        this.stepsMapping[step.step] === 1
          ? 0
          : this.stepsMapping[step.step] === 2
            ? 2
            : this.stepsMapping[step.step] - 5;
    } else if (this.referralObj.preference === 'both') {
      if (this.stepsMapping[step.step] > 6) {
        this.stepper.selectedIndex =
          this.stepsMapping[step.step] === 1
            ? 0
            : this.stepsMapping[step.step] - 2;
      } else {
        this.stepper.selectedIndex =
          this.stepsMapping[step.step] === 1 ? 0 : this.stepsMapping[step.step];
      }
    }
    this.scrollProgressBar('right', (this.stepper.steps.length - 1) - this.stepper.selectedIndex);
  }

  changeStep(step: string) {
    if (step === 'next') {
      this.stepper.next();
    }
  }

  displayTransitionMessage() {
    this.snackbarService.snackBarSucess(
      AE_WEB_CONST.transitionMessage,
      5000,
      'success'
    );
  }

  populateDraftData(draft) {
    const draftReferral = { ...this.referralObj };
    draftReferral.referralId = draft.referralId;
    draftReferral.customerInfo = {
      customerFirstName: draft.customerInfo.firstName,
      customerLastName: draft.customerInfo.lastName,
      customerEmail: draft.customerInfo.contact.primaryEmail,
      customerPhone: draft.customerInfo.contact.primaryMobile,
    };
    draftReferral.secCustomerInfo = {
      secondaryFirstName: draft.customerInfo.secondaryFirstName,
      secondaryLastName: draft.customerInfo.secondaryLastName,
      secondaryEmail: draft.customerInfo.contact.secondaryEmail,
      secondaryMobile: draft.customerInfo.contact.secondaryMobile,
    }
    draft.isWebBothBuySell
      ? (draftReferral.preference = 'both')
      : draft.service[0].type === 'Buying'
        ? (draftReferral.preference = 'buy')
        : (draftReferral.preference = 'sell');
    draft.service.forEach((service) => {
      switch (service.type) {
        case 'Buying': {
          const buyingAddress = service.property.address;
          if (
            [
              buyingAddress.street,
              buyingAddress.city,
              buyingAddress.state,
              buyingAddress.zipcode,
            ].filter(Boolean).length > 0
          ) {
            draftReferral['buyLocation'] = {
              location: {
                fullAddress: [
                  buyingAddress.street,
                  buyingAddress.city,
                  buyingAddress.state,
                  'USA',
                ]
                  .filter(Boolean)
                  .join(', '),
                streetAddress: buyingAddress.street,
                city: buyingAddress.city,
                state: buyingAddress.state,
                zipcode: buyingAddress.zipcode,
                country: 'USA',
              },
            };
          }
          if (service.property.propertyInfo.length > 0) {
            draftReferral.buyingPropertyTypes = service.property.propertyInfo;
          }
          draftReferral.buyingAdditionalDetails = {
            minPrice:
              service.property.priceRange.minPrice > 0
                ? Number(service.property.priceRange.minPrice)
                : 0,
            maxPrice: service.property.priceRange.maxPrice
              ? Number(service.property.priceRange.maxPrice)
              : 0,
            noOfBeds: service.property.noOfBedRooms
              ? service.property.noOfBedRooms
              : 0,
            noOfBaths: service.property.noOfBathRooms
              ? service.property.noOfBathRooms
              : 0,
            comments: service.property.additionalComments
              ? service.property.additionalComments
              : null,
          };
          draftReferral.buyReferralFee = service.requested_referral_fee ? Number(service.requested_referral_fee) : null;
          break;
        }
        case 'Selling': {
          const sellingAddress = service.property.address;
          if (
            [
              sellingAddress.street,
              sellingAddress.city,
              sellingAddress.state,
              sellingAddress.zipcode,
            ].filter(Boolean).length > 0
          ) {
            draftReferral['sellAddress'] = {
              address: {
                fullAddress: [
                  sellingAddress.street,
                  sellingAddress.city,
                  sellingAddress.state,
                  'USA',
                ]
                  .filter(Boolean)
                  .join(', '),
                streetAddress: sellingAddress.street,
                city: sellingAddress.city,
                state: sellingAddress.state,
                zipcode: sellingAddress.zipcode,
                country: 'USA',
              },
            };
          }
          if (sellingAddress.prop_address_2) {
            draftReferral.sellAddress
              ? (draftReferral.sellAddress.apiUnit = sellingAddress.prop_address_2)
              : (draftReferral['sellAddress'] = {
                apiUnit: sellingAddress.prop_address_2,
              });
          }
          if (service.property.propertyInfo.length > 0) {
            draftReferral.sellingPropertyType =
              service.property.propertyInfo[0];
          }
          draftReferral.sellingAdditionalDetails = {
            ownershipType:
              service.property.ownershipType.length > 0
                ? service.property.ownershipType[0]
                : 0,
            estimationValue: service.property.estimatedValue,
            comments: service.property.additionalComments
              ? service.property.additionalComments
              : null,
          };
          draftReferral.sellReferralFee = service.requested_referral_fee ? Number(service.requested_referral_fee) : null;
          break;
        }
      }
    });
    this.referralObj = draftReferral;
  }

  refTypeChanged() {
    this.draftLimitMessage = false;
  }

  openFeedbackPopup() {
    const feedbackDialog = this.dialog.open(FeedbackComponent, {
      autoFocus: false,
      panelClass: 'feedback-panel-section',
      backdropClass: 'feedback-backdrop'
    });
    feedbackDialog.afterClosed().subscribe(feedback => {
      if (feedback) {
        feedback['currentScreen'] = this.getCurrentStep();
        this.feedbackSvc.submitFeedback(feedback).subscribe(response => {
          if (response && response.status === 201) {
            this.snackbarService.snackBarSucess(
              AE_WEB_CONST.feedbackSuccessMessage,
              3000,
              'feedback'
            );
          }
        });
      }
    });
  }

  getCurrentStep() {
    if (this.stepper.selectedIndex === 0) {
      return 'Customer Info';
    } else if (this.stepper.selectedIndex === 1) {
      return 'Type of Referral';
    } else {
      const screens = AE_WEB_CONST.createReferralScreens.find(x => x.flow === this.referralObj.preference).screens;
      const stepIndex = this.stepper.selectedIndex;
      switch (this.referralObj.preference) {
        case 'buy': {
          if (this.referralObj.hideBuySelection) {
            return stepIndex === 6 ?
              screens.find(x => x.step === stepIndex + 1).name :
              screens.find(x => x.step === stepIndex).name;
          } else {
            return screens.find(x => x.step === stepIndex).name;
          }
        }
        case 'sell': {
          if (this.referralObj.hideSellLocation) {
            return stepIndex === 6 ?
              screens.find(x => x.step === stepIndex + 1).name :
              screens.find(x => x.step === stepIndex).name;
          } else {
            return screens.find(x => x.step === stepIndex).name;
          }
        }
        case 'both': {
          if (stepIndex < 6) {
            return screens.find(x => x.step === stepIndex).name;
          } else if (stepIndex === 6) {
            return this.referralObj.hideBuySelection ?
              screens.find(x => x.step === stepIndex + 1).name :
              screens.find(x => x.step === stepIndex).name;
          } else {
            if (this.referralObj.hideBuySelection) {
              return this.referralObj.hideSellLocation && stepIndex === 10 ?
                screens.find(x => x.step === stepIndex + 2).name :
                screens.find(x => x.step === stepIndex + 1).name;
            } else {
              return this.referralObj.hideSellLocation && stepIndex === 11 ?
                screens.find(x => x.step === stepIndex + 1).name :
                screens.find(x => x.step === stepIndex).name;
            }
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    // if (this.saveDraftSubscription$) {
    //   this.saveDraftSubscription$.unsubscribe();
    // }
    // if (this.updateDraftSubscription$) {
    //   this.updateDraftSubscription$.unsubscribe();
    // }
    if (this.getReferralDraftSubscription$) {
      this.getReferralDraftSubscription$.unsubscribe();
    }
  }
}
