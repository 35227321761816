<div class="stepper">
  <div class="stepper-container rni-container">
    <div [hidden]="!referralObj?.step?.second" class="stepper-header">
      <ul class="steps">
        <li [ngClass]="
            referralObj?.step?.zero
              ? 'step step--complete step--active'
              : 'step step--incomplete step--active'
          ">
          <span class="step__label step__label_single">YOUR INFO</span>
          <span class="step__icon"></span>
        </li>
        <li [ngClass]="
            referralObj?.step?.first || referralObj?.step?.zero
              ? 'step step--complete step--active'
              : 'step step--incomplete step--active'
          ">
          <span class="step__label step__label_single">CUSTOMER INFO</span>
          <span class="step__icon"></span>
        </li>
        <li [ngClass]="
            referralObj?.step?.second || referralObj?.step?.first
              ? 'step step--complete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label step__label_single">TYPE OF REFERRAL</span>
          <span class="step__icon"></span>
        </li>
        <li *ngIf="
            referralObj.preference === 'buy' ||
            referralObj.preference === 'both'
          " [ngClass]="
            referralObj?.step?.third
              ? 'step step--complete step--active'
              : referralObj?.step?.second
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label">BUYING <br />
            LOCATION</span>
          <span class="step__icon"></span>
        </li>
        <li *ngIf="
            referralObj.preference === 'buy' ||
            referralObj.preference === 'both'
          " [ngClass]="
            referralObj?.step?.fourth
              ? 'step step--complete step--active'
              : referralObj?.step?.third
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label">BUYING <br />
            PROPERTY TYPE</span>
          <span class="step__icon"></span>
        </li>
        <li *ngIf="
            referralObj.preference === 'buy' ||
            referralObj.preference === 'both'
          " [ngClass]="
            referralObj?.step?.fifth
              ? 'step step--complete step--active'
              : referralObj?.step?.fourth
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label">BUYING <br />
            ADDITIONAL INFO</span>
          <span class="step__icon"></span>
        </li>
        <li *ngIf="
            referralObj.preference === 'sell' ||
            referralObj.preference === 'both'
          " [ngClass]="
            referralObj?.step?.sixth
              ? 'step step--complete step--active'
              : (referralObj?.step?.fifth &&
                  referralObj.preference === 'both') ||
                (referralObj?.step?.second && referralObj.preference === 'sell')
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label">SELLING <br />
            LOCATION</span>
          <span class="step__icon"></span>
        </li>
        <li *ngIf="
            referralObj.preference === 'sell' ||
            referralObj.preference === 'both'
          " [ngClass]="
            referralObj?.step?.seventh
              ? 'step step--complete step--active'
              : referralObj?.step?.sixth
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label">SELLING <br />
            PROPERTY TYPE</span>
          <span class="step__icon"></span>
        </li>
        <li *ngIf="
            referralObj.preference === 'sell' ||
            referralObj.preference === 'both'
          " [ngClass]="
            referralObj?.step?.eighth
              ? 'step step--complete step--active'
              : referralObj?.step?.seventh
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label">SELLING <br />
            ADDITIONAL INFO</span>
          <span class="step__icon"></span>
        </li>
        <li [ngClass]="
            referralObj?.step?.ninth
              ? 'step step--complete step--active'
              : (referralObj?.step?.eighth &&
                  (referralObj.preference === 'sell' ||
                    referralObj.preference === 'both')) ||
                (referralObj?.step?.fifth && referralObj.preference === 'buy')
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label step__label_single">CONFIRMATION </span>
          <span class="step__icon"></span>
        </li>
      </ul>
    </div>

    <mat-horizontal-stepper #stepper (selectionChange)="stepChanged($event)">
      <mat-step>
        <app-agent-info [referralObj]="referralObj" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)">
        </app-agent-info>
      </mat-step>
      <mat-step>
        <app-customer-info [referralObj]="referralObj" (updateReferralObj)="updateReferralObj($event)"
          rrnReferral="true" (resetReferral)="resetReferral()">
        </app-customer-info>
      </mat-step>
      <mat-step>
        <app-referral-type [referralObj]="referralObj" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" rrnReferral="true" (resetReferral)="resetReferral()">
        </app-referral-type>
      </mat-step>
      <mat-step *ngIf="
          referralObj.preference === 'buy' || referralObj.preference === 'both'
        ">
        <app-location [referralObj]="referralObj" refType="buy" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" rrnReferral="true" (resetReferral)="resetReferral()">
        </app-location>
      </mat-step>
      <mat-step *ngIf="
          referralObj.preference === 'buy' || referralObj.preference === 'both'
        ">
        <app-property-type [referralObj]="referralObj" refType="buy" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" (resetReferral)="resetReferral()">
        </app-property-type>
      </mat-step>
      <mat-step *ngIf="
          referralObj.preference === 'buy' || referralObj.preference === 'both'
        ">
        <app-additional-info [referralObj]="referralObj" refType="buy" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" rrnReferral="true"
          (displayTransitionMessage)="displayTransitionMessage()" (resetReferral)="resetReferral()">
        </app-additional-info>
      </mat-step>
      <!-- <mat-step *ngIf="referralObj.preference === 'buy' || referralObj.preference === 'both'">
        <app-rrn-receiving-agent-info [referralObj]="referralObj" refType="buy"
          (updateReferralObj)="updateReferralObj($event)" (scrollProgressBar)="scrollProgressBar($event)">
        </app-rrn-receiving-agent-info>
      </mat-step> -->
      <mat-step *ngIf="
          referralObj.preference === 'sell' || referralObj.preference === 'both'
        ">
        <app-location [referralObj]="referralObj" refType="sell" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" rrnReferral="true" (resetReferral)="resetReferral()">
        </app-location>
      </mat-step>
      <mat-step *ngIf="
          referralObj.preference === 'sell' || referralObj.preference === 'both'
        ">
        <app-property-type [referralObj]="referralObj" refType="sell" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" rrnReferral="true" (resetReferral)="resetReferral()">
        </app-property-type>
      </mat-step>
      <mat-step *ngIf="
          referralObj.preference === 'sell' || referralObj.preference === 'both'
        ">
        <app-additional-info [referralObj]="referralObj" refType="sell" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" rrnReferral="true" (resetReferral)="resetReferral()">
        </app-additional-info>
      </mat-step>
      <!-- <mat-step *ngIf="referralObj.preference === 'sell' || referralObj.preference === 'both'">
        <app-rrn-receiving-agent-info [referralObj]="referralObj" refType="sell"
          (updateReferralObj)="updateReferralObj($event)" (scrollProgressBar)="scrollProgressBar($event)">
        </app-rrn-receiving-agent-info>
      </mat-step> -->
      <mat-step *ngIf="referralObj.preference">
        <div class="rni-confirmation-wrap">
          <app-confirmation [referralObj]="referralObj" (updateReferralObj)="updateReferralObj($event)"
            (scrollProgressBar)="scrollProgressBar($event)" (navigateToChild)="navigateToChild($event)"
            rrnReferral="true" (resetReferral)="resetReferral()">
          </app-confirmation>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>