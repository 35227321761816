import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AE_WEB_CONST } from '../models/constants';
import { ActionDialogComponent } from '../components/action-dialog/action-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CreateReferralComponent } from 'src/app/public/referral/create-referral/create-referral.component';

@Injectable()
export class DirtyCheckGuardService {

    constructor(
        public dialog: MatDialog
    ) { }

    // canDeactivate(component: CreateReferralComponent): boolean | Observable<boolean> {
    //     if (component && !component.canDeactivate()) {
    //         const dialogRef = this.dialog.open(ActionDialogComponent, {
    //             data: {
    //                 type: 'error',
    //                 message: AE_WEB_CONST.warningMessages.dirtyCheckConfirmation,
    //                 action: { leave: true, stay: true }
    //             }
    //         });
    //         return dialogRef.afterClosed();
    //     } else {
    //         return true;
    //     }
    // }
}
