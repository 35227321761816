import { NgModule, InjectionToken } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { LogoutComponent } from './core/components/logout/logout.component';
import { ExternRouteComponent } from './core/components/extern-route/extern-route.component';
import { CreateReferralComponent } from './public/referral/create-referral/create-referral.component';
import { DraftsReferralsComponent } from './public/referral/drafts-referrals/drafts-referrals.component';
import { DirtyCheckGuardService as DirtyCheckGuard } from './core/services/dirty-check-guard.service';
import { FaqComponent } from './core/components/faq/faq.component';
import { ThankYouComponent } from './core/components/thank-you/thank-you.component';
import { SpinnerComponent } from './core/components/spinner/spinner.component';
import { RrnReferralComponent } from './public/rrn-referral/rrn-referral.component';
import { MaintenanceComponent } from './public/maintenance/maintenance.component';
const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  // {
  //   path: 'home',
  //   component: HomeComponent,
  // },
  {
    path: 'faqs',
    component: FaqComponent,
  },
  {
    path: 'createreferral',
    component: CreateReferralComponent,
    // canDeactivate: [DirtyCheckGuard],
  },
  {
    path: 'drafts',
    component: DraftsReferralsComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'thankyou',
    component: ThankYouComponent
  },
  {
    path: 'spinner',
    component: SpinnerComponent
  },
  {
    path: 'rni',
    component: RrnReferralComponent,
    children: [{
      path: '**',
      component: RrnReferralComponent,
    }],
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider,
    },
    component: ExternRouteComponent,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalURL = route.paramMap.get('externalUrl');
        window.location.replace(externalURL);
      },
    },
  ],
  imports: [RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
