import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-image-cta',
  templateUrl: './image-cta.component.html',
  styleUrls: ['./image-cta.component.scss']
})
export class ImageCtaComponent implements OnInit {
  @Input() brandProperties: any;
  @Input() imgContent: any;

  constructor(
    private router: Router,
    private appConfig: AppConfigService
  ) { }

  ngOnInit(): void {
  }

  redirectToOkta() {
    const redirectURL = <string>this.appConfig.getConfig('redirectUrl');
    this.router.navigate(['/externalRedirect', { externalUrl: redirectURL }], {
      skipLocationChange: true
    });
  }
}
