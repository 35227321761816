import { AbstractControl, UntypedFormControl , FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid);
        const invalidParent = !!(control && control.parent && control.parent.invalid);
        return (invalidCtrl || invalidParent);
    }
}

export function comparePrice(control: AbstractControl): { [key: string]: boolean } {
    const minprice = control.get('minPrice');
    const maxprice = control.get('maxPrice');

    if (minprice.value && maxprice.value
        && Number(minprice.value.replace(/\D/g, '')) !== 0
        && Number(maxprice.value.replace(/\D/g, '')) !== 0) {
        return Number(minprice.value.replace(/\D/g, '')) >= Number(maxprice.value.replace(/\D/g, ''))
            ? { misMatch: true }
            : null;
    }
    else {
        return null;
    }
}
