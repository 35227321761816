/* tslint:disable */
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';

/**
 * Gets the global API services configuration
 */
@Injectable({
  providedIn: 'root'
})
export class ApiConfigService {

  /** Root URL for Agent API operations */
  agentRootUrl: string;
  /** Root URL for Agents API operations */
  agentsRootUrl: string;
  /** Root URL for Referral API operations */
  referralRootUrl: string;
  /** Root URL for Access Mgmt API operations */
  accessManagementRootUrl: string;

  /**
   * Initialize the rootURL
   */
  constructor(private readonly config: AppConfigService) {
    const agentApiConfig: { [key: string]: string | number } = this.config.getConfig('agent_api');
    //this.rootUrl = apiConfig.protocol + '://' + apiConfig.host + ':' + apiConfig.port + apiConfig.base_url;
    this.agentRootUrl = agentApiConfig.protocol + '://' + agentApiConfig.host;
    
    const agentsApiConfig: { [key: string]: string | number } = this.config.getConfig('agents_api');
    this.agentsRootUrl = agentsApiConfig.protocol + '://' + agentsApiConfig.host;

    const referralApiConfig: { [key: string]: string | number } = this.config.getConfig('referral_api');
    this.referralRootUrl = referralApiConfig.protocol + '://' + referralApiConfig.host;

    const apiConfig2: { [key: string]: string | number } = this.config.getConfig('accessManagement');
    this.accessManagementRootUrl = apiConfig2.protocol + '://' + apiConfig2.host;
  }
}
