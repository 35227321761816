<section class="about-program">
  <div class="main-container">
    <div class="about-text">
      <h2 class="title-heading"> {{this.brandProperties.aboutTheProgramTitle}} </h2>
    </div>
    <div class="about-border"></div>
    <p class="about-heading">
      <strong>{{this.brandProperties.aboutTheProgramHeading}}</strong>
    </p>

    <p class="about-para">{{this.brandProperties.aboutTheProgramParagraph1}}</p>

    <p class="about-para">{{this.brandProperties.aboutTheProgramParagraph2}}</p>

  </div>
</section>
