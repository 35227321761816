import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  /** Variable to store the token  */

  constructor(
    private baseClientSvc: BaseClientService
  ) {

  }

  getAccessToken(url: string): Observable<any> {
    return this.baseClientSvc
      .postExt<string>(url, 'get agent details')
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const emptyArray: string[] = [];
          return of(emptyArray);
        })
      );
  }

}

