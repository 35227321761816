import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AgentDetails } from '../models/AgentDetails';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';

@Injectable({
  providedIn: 'root'
})
export class AgentDetailService {

  private loggedInAgentDataSource = new BehaviorSubject<AgentDetails | any>(null);
  loggedInAgentDetail = this.loggedInAgentDataSource.asObservable();

  constructor(
    private baseClient: BaseClientService,
    private logSvc: RemoteLoggingService
  ) { }

  getLoggedInAgent(): Observable<any> {
    return this.baseClient
      .getArr<string>(`/b2b/agent/details`, 'get agent details', 'agent_api')
      .pipe(
        map(r => r),
        catchError((err, source) => {
          const emptyArray: string[] = [];
          this.logSvc.logError(err);
          return of(emptyArray);
        })
      );
  }

  updateLoggedInAgentDetails(response: AgentDetails | any) {
    this.loggedInAgentDataSource.next(response);
  }
}
