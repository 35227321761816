import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AgentDetails } from '../../models/AgentDetails';
import { AppConfigService } from '../../services/app-config.service';
import { Router } from '@angular/router';
import { ActionDialogComponent } from '../action-dialog/action-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AgentProfileService } from 'src/app/core/services/agent-profile.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { environment } from '../../../../environments/environment'
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() agentDetail: AgentDetails | any;
  @Input() rrnReferral = false;
  fullName: string;
  agentInitials: string;
  brandLogo = `assets/imgs/${AppConfigService.getBrandingData.logo}`;
  agentHasDashRoles = false;
  AppMaintenance = environment.APP_MAINTENANCE_MODE;

  // brandLogo = `assets/imgs/logo.png`;
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
  @Output() loggedOut = new EventEmitter<boolean>();
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private agentProfileService: AgentProfileService,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit() {
    if (this.agentDetail && this.agentDetail !== 'unauthorizedAgent') {
      this.fullName = `${this.agentDetail.agent.firstName} ${this.agentDetail.agent.lastName}`;
      this.agentInitials = this.agentDetail.agent.firstName[0] + this.agentDetail.agent.lastName[0];
      this.agentHasDashRoles = true;
    }
  }
  logout() {
    this.router.navigate(['/logout']).then(event => {
      if (event) {
        this.loggedOut.emit(true);
      }
    });
  }
  close() {
    this.sidenav.close();
  }

  setInitials() {
    if (this.agentDetail) {
      this.agentDetail.agent.photoUrl = null;
    }
  }

  viewAgentProfile() {
    this.spinnerService.show();
    const personId = sessionStorage.getItem('car-ses-con');
    const pinCode = this.agentDetail.agent.postalCode;
    const paramsObj = { personId, pinCode };
    this.agentProfileService.getAgentPrfile(paramsObj)
      .subscribe((response) => {
        if (response) {
          this.dialog.open(ActionDialogComponent, {
            panelClass: 'agent-profile-panel-section',
            data: {
              type: 'profile',
              message: response,
              action: { close: true, selectAgent: false },
            },
            autoFocus: false,
          });
        }
        this.spinnerService.hide();
      });
  }
}
