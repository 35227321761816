import { Directive, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[formControlName][phoneFormat]',
})
export class PhoneFormatDirective implements OnInit {
    isBackSpaceOrDelete: boolean = false;
    constructor(public ngControl: NgControl) { }
    ngOnInit() {
        setTimeout(() => {
            if (this.ngControl.value) {
                this.onInputChange(this.ngControl.value);
            }
        }, 2000);
    }

    // IE usage
    @HostListener('keydown', ['$event'])
    onKeyDown(event) {
        if (event.which === 8 || event.which === 46) {
            this.isBackSpaceOrDelete = true;
        }
    }

    @HostListener('input', ['$event'])
    onInput(event) {
        if (!(event.inputType === 'deleteContentForward' || event.inputType === 'deleteContentBackward' || this.isBackSpaceOrDelete)) {
            this.onInputChange(event.target.value);
        }
        this.isBackSpaceOrDelete = false;
    }

    @HostListener('focusout', ['$event'])
    onBlur(event) {
        this.onInputChange(event.target.value);
    }

    onInputChange(event) {
        let newVal = event.replace(/\D/g, '');
        if (newVal.length === 0) {
            newVal = '';
        } else if (newVal.length <= 3) {
            newVal = newVal.replace(/^(\d{0,3})/, '($1)');
        } else if (newVal.length <= 6) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
        } else if (newVal.length <= 10) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        } else {
            newVal = newVal.substring(0, 10);
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        }
        this.ngControl.valueAccessor.writeValue(newVal);
        if (newVal.length === 0) {
            // this.ngControl.control.setErrors({ required: true });
        } else if (newVal.length < 14) {
            this.ngControl.control.setErrors({ minLength: true });
        } else {
            this.ngControl.control.setErrors(null);
        }
    }
}