import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { WarningDialogComponent } from 'src/app/core/components/warning-dialog/warning-dialog.component';
import { AE_WEB_CONST } from 'src/app/core/models/constants';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss'],
})
export class CustomerInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() referralObj: any;
  @Input() rrnReferral = false;
  @Output() updateReferralObj = new EventEmitter<any>();
  @Output() scrollProgressBar = new EventEmitter<any>();
  @Output() saveUpdateDraft = new EventEmitter<any>();
  @Output() resetReferral = new EventEmitter<any>();

  customerInfoForm: UntypedFormGroup;
  secondaryContactInfoForm: UntypedFormGroup;
  currentReferralObj: any;
  @ViewChild('custInfoForm', { static: false })
  custInfoForm: FormGroupDirective;
  @ViewChild('secContactInfoForm', { static: false })
  secContactInfoForm: FormGroupDirective;

  showSecondaryInfo = {
    first: true,
    second: false,
  };

  constructor(private referralFormBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.initialiseForm();
    this.initialiseSecForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.referralObj) {
      if (this.referralObj.secondaryInfoFlow && this.referralObj.flow === 'edit') {
        this.showSecondaryInfo.first = false;
        this.showSecondaryInfo.second = true;
      } else if (this.referralObj.flow === 'edit') {
          this.showSecondaryInfo.first = true;
          this.showSecondaryInfo.second = false;
        }
      this.referralObj = changes.referralObj.currentValue;
      if (this.referralObj.customerInfo) {
        this.customerInfoForm.patchValue({
          customerFirstName: this.referralObj.customerInfo.customerFirstName,
          customerLastName: this.referralObj.customerInfo.customerLastName,
          customerEmail: this.referralObj.customerInfo.customerEmail,
          customerPhone: this.referralObj.customerInfo.customerPhone
        });
      } else if (this.rrnReferral && this.customerInfoForm && !this.referralObj.customerInfo) {
        this.initialiseForm();
        this.custInfoForm.resetForm();
      }
      if (this.referralObj.secCustomerInfo) {
        this.secondaryContactInfoForm.patchValue({
          secondaryFirstName: this.referralObj.secCustomerInfo.secondaryFirstName,
          secondaryLastName: this.referralObj.secCustomerInfo.secondaryLastName,
          secondaryEmail: this.referralObj.secCustomerInfo.secondaryEmail,
          secondaryMobile: this.referralObj.secCustomerInfo.secondaryMobile
        });
      } else if (this.rrnReferral && this.secondaryContactInfoForm && !this.referralObj.secCustomerInfo) {
        this.initialiseSecForm();
        this.secContactInfoForm?.resetForm();
      }
      this.currentReferralObj = JSON.parse(JSON.stringify(changes.referralObj.currentValue));
    }
    this.referralObj.secondaryInfoFlow = false;
  }

  initialiseForm() {
    this.customerInfoForm = this.referralFormBuilder.group({
      customerFirstName: [
        '',
        [
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(/^[A-Za-z'-]+[\ ]?([a-zA-Z'-]*)$/),
        ],
      ],
      customerLastName: [
        '',
        [
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(/^[A-Za-z'-]+[\ ]?([a-zA-Z'-]*)$/),
        ],
      ],
      customerEmail: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z0-9]{0}[a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+[\ ]?$/
          ),
        ],
      ],
      customerPhone: ['', [Validators.required]],
    });
  }
  initialiseSecForm() {
    this.secondaryContactInfoForm = this.referralFormBuilder.group({
      secondaryFirstName: [
        '',
        [
          Validators.maxLength(30),
          Validators.pattern(/^[A-Za-z'-]+[\ ]?([a-zA-Z'-]*)$/),
        ],
      ],
      secondaryLastName: [
        '',
        [
          Validators.maxLength(30),
          Validators.pattern(/^[A-Za-z'-]+[\ ]?([a-zA-Z'-]*)$/),
        ],
      ],
      secondaryEmail: [
        '',
        [
          Validators.maxLength(50),
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z0-9]{0}[a-zA-Z\-0-9]+[^-]\.)+[a-zA-Z]{2,}))+[\ ]?$/
          ),
        ],
      ],
      secondaryMobile: [''],
    });
  }

  updateChange() {
    this.scrollProgressBar.emit('left');
    this.referralObj['step'].zero = true;
    this.referralObj['step'].first = true;
    this.updateCustomerInformation();
    this.updateReferralObj.emit(this.referralObj);
    this.saveReferral();
  }

  updateCustomerInformation() {
    this.referralObj['customerInfo'] = {
      customerFirstName: this.customerInfoForm.get('customerFirstName').value.trim(),
      customerLastName: this.customerInfoForm.get('customerLastName').value.trim(),
      customerEmail: this.customerInfoForm.get('customerEmail').value.trim(),
      customerPhone: this.customerInfoForm.get('customerPhone').value
        .replace(/\D/g, '').substring(0, 10).replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3'),
    };
    this.referralObj['secCustomerInfo'] = {
      secondaryFirstName: this.secondaryContactInfoForm.get('secondaryFirstName').value.trim(),
      secondaryLastName: this.secondaryContactInfoForm.get('secondaryLastName').value.trim(),
      secondaryEmail: this.secondaryContactInfoForm.get('secondaryEmail').value.trim(),
      secondaryMobile: this.secondaryContactInfoForm.get('secondaryMobile').value ? 
        this.secondaryContactInfoForm.get('secondaryMobile').value
        .replace(/\D/g, '').substring(0, 10).replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3') : null,
    };
  }

  saveReferral() {
    if (this.referralObj.preference && this.referralObj.referralId &&
       JSON.stringify(this.currentReferralObj.customerInfo) !== JSON.stringify(this.referralObj.customerInfo)) {
      this.referralObj.currentStep = 'Customer Information';
      this.saveUpdateDraft.emit(this.referralObj);
    }
  }

  cancelReferral() {
    const cancelDialog = this.dialog.open(WarningDialogComponent, {
      data: {
        type: 'resetReferral',
        message: AE_WEB_CONST.cancelReferral
      },
      autoFocus: false
    });
    cancelDialog.afterClosed().subscribe((action) => {
      if (action) {
        this.resetReferral.emit(true);
      }
    });
  }

  ngOnDestroy() {
    this.updateCustomerInformation();
    if (!this.referralObj.step.first && this.customerInfoForm.valid) {
      this.saveReferral();
    }
  }
}
