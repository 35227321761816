import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AppConfigService } from './app-config.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
    constructor(
        private router: Router,
        private readonly appConfig: AppConfigService
    ) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        let token = sessionStorage.getItem('car-ses-tok');
        // if (!token) {
        //     const redirectURL = this.appConfig.getConfig('redirectUrl');
        //     this.router.navigate(['/externalRedirect', { externalUrl: redirectURL }], {
        //         skipLocationChange: true
        //     });
        //     return false;
        // }
        return true;
    }
}