import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AE_WEB_CONST } from 'src/app/core/models/constants';
import { SpinnerService } from '../../services/spinner.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss'],
})
export class ActionDialogComponent implements OnInit {
  phoneNo: number;
  companyAddress: string;
  propertySpecializations = [];

  constructor(
    public dialogRef: MatDialogRef<ActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private spinnerService: SpinnerService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    if (this.dialogData.type === 'profile') {
      this.mapProperties(this.dialogData.message.propertySpecializations);
      if (this.dialogData.message.phoneNo) {
        this.phoneNo = this.dialogData.message.phoneNo ?
          this.dialogData.message.phoneNo.replace(/\D/g, '').replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3') : null;
      }
      this.dialogData.message.hideAgentImage = !this.dialogData.message.profilePhoto;
      this.dialogData.message.agentInitials = `${this.dialogData.message.fName[0]}${this.dialogData.message.lName[0]}`;
      this.companyAddress = this.dialogData.message.brokerageInfo ?
        [this.dialogData.message.brokerageInfo.split(',')[0], this.dialogData.message.brokerageInfo.split(',')[1],
        this.dialogData.message.brokerageInfo.split(',')[2]].join(', ') : '';
      setTimeout(() => {
        this.spinnerService.show();
      }, 0);
      setTimeout(() => {
        this.spinnerService.hide();
      }, 3000);
      if(this.dialogData.message.awards !== null) {
        this.dialogData.message.awards = this.dialogData.message.awards || [];
        this.dialogData.message.awards.sort((a, b) => {
          return +(new Date(b.yearAchieved)) - +(new Date(a.yearAchieved)); // descending sort by award year
        })
      }
    }
  }

  setInitials() {
    this.dialogData.message.hideAgentImage = true;
  }

  mapProperties(property) {
    if (property && property.length) {
      const properties = new Map();
      const propertySpecializationKeyValue = AE_WEB_CONST.propertySpecializationKeyValue;
      for (const [key, value] of Object.entries(propertySpecializationKeyValue)) {
        properties.set(key, value);
      }
      for (const [key, value] of properties) {
        property.forEach(item => {
          if (value.includes(item) && this.propertySpecializations.indexOf(key) === -1) {
            this.propertySpecializations.push(key);
          }
        });
      }
    }
  }

  openWebsite(link) {
    window.open(link, "_blank");
  }

}
