<app-spinner></app-spinner>
<div>
  <section id="agent-form-section">
    <div class="agent-section" id="agent-section">
      <div class="left-container" id="left-container">
        <div class="review-con-title">
          <h2>Review & <br />Confirm</h2>
        </div>
      </div>
      <div class="form-wrapper" id="{{ userPreferenceId }}">
        <form [formGroup]="confirmationForm">
          <div class="form-container">
            <div class="resp-title">
              <h2>Review & Confirm</h2>
            </div>
            <div id="agent-selected-information">
              <div *ngIf="referralObj.agentInformation" class="agent-info-div custom-info-div">
                <div class="agent-info-title">
                  Your Information
                  <div class="edit-icon-div">
                    <button mat-icon-button class="edit-btn" (click)="navigateTo('zero')">
                      <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
                    </button>
                  </div>
                </div>
                <div class="row agent-row cutomer-row cutomer-row-name">
                  <span>{{
                    referralObj.agentInformation
                    ? referralObj.agentInformation.firstName
                    : ''
                    }}</span>
                  <span>{{
                    referralObj.agentInformation
                    ? referralObj.agentInformation.lastName
                    : ''
                    }}</span>
                </div>

                <div class="row agent-row cutomer-row">
                  <span>{{
                    referralObj.agentInformation
                    ? referralObj.agentInformation.phoneNo
                    : ''
                    }}</span>
                  <span>{{
                    referralObj.agentInformation
                    ? referralObj.agentInformation.email
                    : ''
                    }}</span>
                </div>
                <div class="row agent-row cutomer-row">
                  <span>{{
                    referralObj.agentInformation
                    ? agentLicenseState.name
                    : ''
                    }}</span>
                </div>
              </div>

              <div class="agent-info-div custom-info-div">
                <div class="agent-info-title">
                  Customer Info
                  <div class="edit-icon-div">
                    <button mat-icon-button class="edit-btn" (click)="navigateTo('first')">
                      <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
                    </button>
                  </div>
                </div>
                <div class="row agent-row cutomer-row cutomer-row-name">
                  <span>{{
                    this.referralObj.customerInfo
                    ? this.referralObj.customerInfo.customerFirstName
                    : ''
                    }}</span>
                  <span>{{
                    this.referralObj.customerInfo
                    ? this.referralObj.customerInfo.customerLastName
                    : ''
                    }}</span>
                </div>

                <div class="row agent-row cutomer-row">
                  <span>{{
                    this.referralObj.customerInfo
                    ? this.referralObj.customerInfo.customerPhone
                    : ''
                    }}</span>
                  <span>{{
                    this.referralObj.customerInfo
                    ? this.referralObj.customerInfo.customerEmail
                    : ''
                    }}</span>
                </div>
              </div>

              <ng-container *ngIf="!rrnReferral">
                <ng-container *ngIf="agents[0] && agents[0].details" [ngTemplateOutlet]="agentProfile"
                  [ngTemplateOutletContext]="agents[0]">
                </ng-container>
                <ng-container *ngIf="agents[0] && !agents[0].details" [ngTemplateOutlet]="noProfile"
                  [ngTemplateOutletContext]="agents[0]">
                </ng-container>
              </ng-container>
              
              <hr class="reponsive-hr" />

              <div class="agent-info-div custom-info-div">
                <div class="agent-info-title">
                  Secondary Contact Info
                  <div class="edit-icon-div">
                    <button mat-icon-button class="edit-btn" (click)="navigateToSecInfo()">
                    <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
                    </button>
                  </div>
                </div>
                <div [ngClass]="this.referralObj.secCustomerInfo.secondaryFirstName ?
                  'row agent-row cutomer-row cutomer-row-name': 
                  'row agent-row cutomer-row cutomer-row-name2'"
                  *ngIf="(this.secCustomerInfoCheck && (this.referralObj.secCustomerInfo.secondaryFirstName ||
                    this.referralObj.secCustomerInfo.secondaryLastName))">
                  <span>{{
                    (this.referralObj.secCustomerInfo)
                    ? (this.referralObj.secCustomerInfo.secondaryFirstName)
                    : ''
                  }}</span>
                  <span>{{
                    this.referralObj.secCustomerInfo
                    ? this.referralObj.secCustomerInfo.secondaryLastName
                    : ''
                    }}</span>
                </div>

                <div [ngClass]="this.referralObj.secCustomerInfo.secondaryMobile ?
                  'row agent-row cutomer-row' : 'row agent-row cutomer-row-name2'"
                  *ngIf="(this.secCustomerInfoCheck && (this.referralObj.secCustomerInfo.secondaryMobile ||
                    this.referralObj.secCustomerInfo.secondaryEmail))">
                  <span>{{
                    this.referralObj.secCustomerInfo
                    ? this.referralObj.secCustomerInfo.secondaryMobile
                    : ''
                    }}</span>
                  <span>{{
                    this.referralObj.secCustomerInfo
                    ? this.referralObj.secCustomerInfo.secondaryEmail
                    : ''
                    }}</span>
                </div>      
                <div  class = "row agent-row cutomer-row" *ngIf="!this.secCustomerInfoCheck">
                  <span>None Provided</span>
                </div>
              </div>

              <div *ngIf="
                  referralObj.preference === 'buy' ||
                  referralObj.preference === 'both'
                " id="{{ userPreferenceId === 'both' ? 'buying' : '' }}">
                <div class="agent-info-div type-referral-div">
                  <div class="agent-info-title agent-title">
                    Type of Referral
                    <mat-icon *ngIf="!rrnReferral" class="error-icon"
                      matTooltip="Type of Referral cannot be changed from this screen" [matTooltipPosition]="position">
                      error_outline</mat-icon>
                  </div>
                  <div class="edit-icon-div" *ngIf="rrnReferral">
                    <button mat-icon-button class="edit-btn" (click)="navigateTo('second')">
                      <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
                    </button>
                  </div>
                  <div class="row agent-row type-referral">
                    <h2 class="info-title">Buying</h2>
                  </div>
                </div>
                <div class="agent-info-div">
                  <div class="agent-info-title">
                    Buying Location
                    <mat-icon *ngIf="!rrnReferral" class="error-icon error-icon-location"
                      matTooltip="You will need to select the agent again if you change Location."
                      [matTooltipPosition]="position">error_outline
                    </mat-icon>

                    <div class="edit-icon-div">
                      <button mat-icon-button class="edit-btn" (click)="navigateTo(rrnReferral ? 'third' : 'second')">
                        <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
                      </button>
                    </div>
                  </div>
                  <div class="row agent-row ie-location">
                    <div class="location-info-icon">
                      <span>{{
                        this.referralObj.buyLocation &&
                        this.referralObj.buyLocation.location
                        ? this.referralObj.buyLocation.location.fullAddress
                        : ''
                        }}</span>
                    </div>
                  </div>
                </div>

                <div class="agent-info-div">
                  <div class="agent-info-title">
                    Buying Property Type
                    <div class="edit-icon-div">
                      <button mat-icon-button class="edit-btn" (click)="navigateTo(rrnReferral ? 'fourth' : 'third')">
                        <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
                      </button>
                    </div>
                  </div>
                  <div class="row agent-row">
                    <span>{{ buyingPropTypes }}</span>
                  </div>
                </div>

                <div class="agent-info-div">
                  <div class="agent-info-title">
                    {{ rrnReferral ? 'Buying Additional Info' : 'Buying Additional Details' }}
                    <div class="edit-icon-div">
                      <button mat-icon-button class="edit-btn" (click)="navigateTo(rrnReferral ? 'fifth' : 'fourth')">
                        <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
                      </button>
                    </div>
                  </div>
                  <div class="row agent-row" *ngIf="buyingAdditionalDetails">
                    <span>{{ buyingAdditionalDetails }}</span>
                  </div>

                  <div class="row agent-row additional-info-row" *ngIf="buyadditionalComments">
                    <p>{{ buyadditionalComments }}</p>
                  </div>
                  <div class="row agent-row additional-info-row"
                    *ngIf="!buyingAdditionalDetails && !buyadditionalComments">
                    <p>None Provided</p>
                  </div>
                </div>

                <!-- <div class="agent-info-div" *ngIf="!rrnReferral">
                  <div class="agent-info-title">
                    Buying Referral Fee
                    <div class="edit-icon-div">
                      <button mat-icon-button class="edit-btn" (click)="navigateTo('fifth')">
                        <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
                      </button>
                    </div>
                  </div>
                  <div class="row agent-row">
                    <span>{{
                      this.referralObj.buyReferralFee
                      ? this.referralObj.buyReferralFee + '%'
                      : ''
                      }}</span>
                  </div>
                </div> -->
              </div>
              <div *ngIf="
                  referralObj.preference === 'sell' ||
                  referralObj.preference === 'both'
                " id="{{ userPreferenceId === 'both' ? 'selling' : '' }}">
                <ng-container *ngIf="userPreferenceId === 'both' && !rrnReferral">
                  <ng-container *ngIf="agents[1] && agents[1].details" [ngTemplateOutlet]="agentProfile"
                    [ngTemplateOutletContext]="agents[1]">
                  </ng-container>
                  <ng-container *ngIf="agents[1] && !agents[1].details" [ngTemplateOutlet]="noProfile"
                    [ngTemplateOutletContext]="agents[1]">
                  </ng-container>
                </ng-container>

                <div class="agent-info-div type-referral-div">
                  <div class="agent-info-title agent-title">
                    Type of Referral
                    <mat-icon *ngIf="!rrnReferral" class="error-icon"
                      matTooltip="Type of Referral cannot be changed from this screen" [matTooltipPosition]="position">
                      error_outline</mat-icon>
                  </div>
                  <div class="edit-icon-div" *ngIf="rrnReferral">
                    <button mat-icon-button class="edit-btn" (click)="navigateTo('second')">
                      <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
                    </button>
                  </div>
                  <div class="row agent-row type-referral">
                    <h2 class="info-title">Selling</h2>
                  </div>
                </div>

                <div class="agent-info-div" [ngClass]="
                    referralObj.preference === 'both'
                      ? 'selling-location-div'
                      : ''
                  ">
                  <div class="agent-info-title">
                    Selling Location
                    <mat-icon *ngIf="!rrnReferral" class="error-icon error-icon-location"
                      matTooltip="You will need to select the agent again if you change Location."
                      [matTooltipPosition]="position">error_outline
                    </mat-icon>
                    <div class="edit-icon-div">
                      <button mat-icon-button class="edit-btn" (click)="
                          navigateTo(
                            referralObj.preference === 'sell' && rrnReferral
                              ? 'third'
                              : referralObj.preference === 'sell'
                              ? 'second'
                              : referralObj.preference === 'both' && rrnReferral
                              ? 'sixth'
                              : 'seventh'
                          )
                        ">
                        <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
                      </button>
                    </div>
                  </div>
                  <div class="row agent-row ie-location">
                    <div class="location-info-icon">
                      <span [innerHTML]="sellingLocation"></span>
                    </div>
                  </div>
                </div>

                <div class="agent-info-div">
                  <div class="agent-info-title">
                    Selling Property Type
                    <div class="edit-icon-div">
                      <button mat-icon-button class="edit-btn" (click)="navigateTo(rrnReferral ? 'seventh' : 'eighth')">
                        <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
                      </button>
                    </div>
                  </div>

                  <div class="row agent-row">
                    <span>{{ sellingPropType }}</span>
                  </div>
                </div>

                <div class="agent-info-div">
                  <div class="agent-info-title">
                    {{ rrnReferral ? 'Selling Additional Info' : 'Selling Additional Details'}}
                    <div class="edit-icon-div">
                      <button mat-icon-button class="edit-btn" (click)="navigateTo(rrnReferral ? 'eighth' : 'ninth')">
                        <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
                      </button>
                    </div>
                  </div>
                  <div class="row agent-row" *ngIf="sellingAdditionalDetails">
                    <span>{{ sellingAdditionalDetails }}</span>
                  </div>

                  <div class="row agent-row additional-info-row" *ngIf="selladditionalComments">
                    <p>{{ selladditionalComments }}</p>
                  </div>
                  <div class="row agent-row additional-info-row"
                    *ngIf="!sellingAdditionalDetails && !selladditionalComments">
                    <p>None Provided</p>
                  </div>
                </div>
                <hr />
                <!-- <div class="agent-info-div" *ngIf="!rrnReferral">
                  <div class="agent-info-title">
                    Selling Referral Fee
                    <div class="edit-icon-div">
                      <button mat-icon-button class="edit-btn" (click)="navigateTo('tenth')">
                        <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
                      </button>
                    </div>
                  </div>
                  <div class="row agent-row">
                    <span>{{
                      this.referralObj.sellReferralFee
                      ? this.referralObj.sellReferralFee + '%'
                      : ''
                      }}</span>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </form>
      </div>

      <ng-template #agentProfile let-agentDetails="details" let-agentType="type" let-phoneNo="phoneNo" let-step="step"
        let-hideBuyAgent="hideBuyAgent" let-hideSellAgent="hideSellAgent">
        <div class="agent-card" id="profile" [ngClass]="
            referralObj.preference === 'sell' ||
            referralObj.preference === 'buy'
              ? 'selling-buying'
              : ''
          ">
          <mat-card class="card responsive-agent-card">
            <span class="card-title">{{ agentType }}</span>
            <div *ngIf="
                agentType === 'Buying Agent' && !referralObj.isCommercialBuy
              " class="edit-icon-div profile-edit-icon">
              <button mat-icon-button class="edit-btn" (click)="navigateTo(step, hideBuyAgent, hideSellAgent)">
                <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
              </button>
            </div>
            <div *ngIf="
                agentType === 'Selling Agent' && !referralObj.isCommercialSell
              " class="edit-icon-div profile-edit-icon">
              <button mat-icon-button class="edit-btn" (click)="navigateTo(step, hideBuyAgent, hideSellAgent)">
                <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
              </button>
            </div>
            <mat-card-header>
              <div *ngIf="!agentDetails.hideAgentImage" mat-card-avatar class="example-header-image agent-img">
                <img [src]="agentDetails.profilePhoto" alt="agent picture" (error)="setInitials(agentDetails)" />
              </div>
              <div *ngIf="agentDetails.hideAgentImage" mat-card-avatar class="example-header-image agent-initials">
                {{ agentDetails.agentInitials }}
              </div>
            </mat-card-header>
            <mat-card-content>
              <div class="agent-info">
                <h2>{{ agentDetails.agentName }}</h2>
                <p>{{ agentDetails.companyName }}</p>

                <p *ngIf="phoneNo" class="phone-info">
                  <span>{{ phoneNo }}</span>
                </p>
                <p class="mail-info">
                  <span>{{ agentDetails.mailId }}</span>
                </p>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-template>

      <!----No profile-->
      <ng-template #noProfile let-agentType="type" let-step="step" let-hideBuyAgent="hideBuyAgent"
        let-hideSellAgent="hideSellAgent">
        <div class="agent-card agent-card-buy" id="no-profile" [ngClass]="
            referralObj.preference === 'sell' ||
            referralObj.preference === 'buy'
              ? 'selling-buying'
              : ''
          ">
          <mat-card class="card responsive-agent-card">
            <span class="card-title">{{ agentType }}</span>
            <div *ngIf="
                agentType === 'Buying Agent' && !referralObj.isCommercialBuy
              " class="edit-icon-div profile-edit-icon">
              <button mat-icon-button class="edit-btn" (click)="navigateTo(step, hideBuyAgent, hideSellAgent)">
                <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
              </button>
            </div>
            <div *ngIf="
                agentType === 'Selling Agent' && !referralObj.isCommercialSell
              " class="edit-icon-div profile-edit-icon">
              <button mat-icon-button class="edit-btn" (click)="navigateTo(step, hideBuyAgent, hideSellAgent)">
                <img src="../../../../../assets/images/edit-icon.png" alt="edit icon" />
              </button>
            </div>
            <mat-card-header>
              <div class="agent-info-logo no-profile-logo">
                <img src="../../../../../assets/imgs/agent-lnfo-logo.png" alt="brand logo" />
              </div>
            </mat-card-header>
            <mat-card-content>
              <div class="agent-info">
                <p class="agent-info-no-profile">
                  An Operations Specialist will be in contact with you shortly
                  to provide agent assignment information.
                </p>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-template>
    </div>

    <div class="confirm-btn-section">
      <button class="back-btn cancel-btn" (click)="cancelReferral()">
        <span>Cancel</span>
      </button>
      <button type="button" class="next-btn confirm-btn" (click)="submitReferral()" matStepperNext>
        <span>Confirm</span>
      </button>
    </div>
  </section>
</div>