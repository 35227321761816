export const AE_WEB_CONST = {
  referralSubmit: `Submit Referral To`,
  referralDetails: `Your Referral Details`,
  /** Title to display copyright*/
  copyright: `&copy; 2000-2019 Cartus Corporation`,
  /** state list mock json */
  stateList: [
    { name: `Alabama`, shortName: `AL` },
    { name: `Alaska`, shortName: `AK` },
    { name: `Arizona`, shortName: `AZ` },
    { name: `Arkansas`, shortName: `AR` },
    { name: `California`, shortName: `CA` },
    { name: `Colorado`, shortName: `CO` },
    { name: `Connecticut`, shortName: `CT` },
    { name: `Delaware`, shortName: `DE` },
    { name: `District of Columbia`, shortName: `DC` },
    { name: `Florida`, shortName: `FL` },
    { name: `Georgia`, shortName: `GA` },
    { name: `Hawaii`, shortName: `HI` },
    { name: `Idaho`, shortName: `ID` },
    { name: `Illinois`, shortName: `IL` },
    { name: `Indiana`, shortName: `IN` },
    { name: `Iowa`, shortName: `IA` },
    { name: `Kansa`, shortName: `KS` },
    { name: `Kentucky`, shortName: `KY` },
    { name: `Lousiana`, shortName: `LA` },
    { name: `Maine`, shortName: `ME` },
    { name: `Maryland`, shortName: `MD` },
    { name: `Massachusetts`, shortName: `MA` },
    { name: `Michigan`, shortName: `MI` },
    { name: `Minnesota`, shortName: `MN` },
    { name: `Mississippi`, shortName: `MS` },
    { name: `Missouri`, shortName: `MO` },
    { name: `Montana`, shortName: `MT` },
    { name: `Nebraska`, shortName: `NE` },
    { name: `Nevada`, shortName: `NV` },
    { name: `New Hampshire`, shortName: `NH` },
    { name: `New Jersey`, shortName: `NJ` },
    { name: `New Mexico`, shortName: `NM` },
    { name: `New York`, shortName: `NY` },
    { name: `North Carolina`, shortName: `NC` },
    { name: `North Dakota`, shortName: `ND` },
    { name: `Ohio`, shortName: `OH` },
    { name: `Oklahoma`, shortName: `OK` },
    { name: `Oregon`, shortName: `OR` },
    { name: `Pennsylvania`, shortName: `PA` },
    { name: `Rhode Island`, shortName: `RI` },
    { name: `South Carolina`, shortName: `SC` },
    { name: `South Dakota`, shortName: `SD` },
    { name: `Tennessee`, shortName: `TN` },
    { name: `Texas`, shortName: `TX` },
    { name: `Utah`, shortName: `UT` },
    { name: `Vermont`, shortName: `VT` },
    { name: `Virginia`, shortName: `VA` },
    { name: `Washington`, shortName: `WA` },
    { name: `West Virginia`, shortName: `WV` },
    { name: `Wisconsin`, shortName: `WI` },
    { name: `Wyoming`, shortName: `WY` },
  ],
  /** property types json */
  propertyTypes: [
    { name: `Single Family`, value: 1 },
    { name: `Townhouse`, value: 4 },
    { name: `New Construction`, value: 12 },
    { name: `Condo / Co-op`, value: 5 },
    { name: `Commercial`, value: 9 },
    { name: `Other`, value: 6 },
    { name: 'Lot / Land', value: 11 }
  ],
  /** radius dropdown values */
  withinRadiusValues: [
    { name: `Any`, value: 0 },
    { name: `5 miles`, value: 5 },
    { name: `10 miles`, value: 10 },
    { name: `20 miles`, value: 20 },
    { name: `50+ miles`, value: 50 },
  ],
  /** number of rooms list */
  noOfRooms: [
    { name: `Any`, value: 0 },
    { name: `1+`, value: 1 },
    { name: `2+`, value: 2 },
    { name: `3+`, value: 3 },
    { name: `4+`, value: 4 },
    { name: `5+`, value: 5 },
  ],
  /** receiving agent referral fee dropdown values */
  referralFeeList: [
    { name: `10%`, value: 10 },
    { name: `15%`, value: 15 },
    { name: `20%`, value: 20 },
    { name: `25%`, value: 25 },
    { name: `30%`, value: 30 },
    { name: `35%`, value: 35 },
  ],
  /** ownership types list */
  ownershipTypes: [
    { name: 'N/A', value: 0 },
    { name: `Primary Residence`, value: 4 },
    { name: `Vacation Home`, value: 3 },
    { name: `Secondary Home`, value: 2 },
    { name: `Rental Property`, value: 1 },
  ],
  validationMessages: {
    customerFirstName: {
      required: `Please enter First Name.`,
      pattern: `Please use only letters, a space, and -' characters.`,
    },
    customerLastName: {
      required: `Please enter Last Name.`,
      pattern: `Please use only letters, a space, and -' characters.`,
    },
    customerEmail: {
      required: `Please enter a valid email address.`,
      pattern: `Please enter a valid email address.`,
      maxlength: `Please enter a valid email address.`,
    },
    customerPhone: {
      required: `Please enter Phone.`,
      pattern: `Please enter a valid Phone.`,
      minLength: `Please enter a valid Phone.`,
    },
    customerStreetAddress: {
      required: `Please enter Mailing Street Address.`,
      maxlength: `Please enter Mailing Street Address.`,
    },
    customerCity: {
      required: `Please enter City.`,
      maxlength: `Please enter City.`,
    },
    customerState: {
      required: `Please enter a State.`,
    },
    customerZipCode: {
      required: `Please enter Zip.`,
      zipError: `Please enter a valid Zip.`,
    },
    referringAgentFirstName: {
      required: `Please enter First Name.`,
      pattern: `Please use only letters, a space, and -' characters.`,
    },
    referringAgentLastName: {
      required: `Please enter Last Name.`,
      pattern: `Please use only letters, a space, and -' characters.`,
    },
    referringAgentEmail: {
      required: `Please enter a valid email address.`,
      pattern: `Please enter a valid email address.`,
      maxlength: `Please enter a valid email address.`,
    },
    referringAgentPhone: {
      required: `Please enter Phone.`,
      pattern: `Please enter a valid Phone.`,
      minLength: `Please enter a valid Phone.`,
    },
    customerPreference: {
      required: `Please select`,
    },
    buyingCity: {
      required: `Please enter Destination City.`,
    },
    buyingState: {
      required: `Please enter Destination State.`,
    },
    maximumPrice: {
      required: `Please enter Price Range Maximum.`,
      invalid: `Price Range Maximum cannot be less than 0 or Price Range Minimum.`,
    },
    houseHuntingStartDate: {
      required: `Please enter Start Date.`,
      invalidDate: `Start Date cannot be less than current date.`,
      matDatepickerParse: `Please enter valid Start Date.`,
      matDatepickerMin: `Please enter valid Start Date.`,
    },
    houseHuntingEndDate: {
      required: `Please enter End Date.`,
      invalidDate: `End Date cannot be less than Start Date.`,
      matDatepickerParse: `Please enter valid End Date.`,
      matDatepickerMin: `Please enter valid End Date.`,
    },
    buyingPropertyTypes: {
      required: `Please enter Property Type.`,
    },
    sellingStreetAddress: {
      required: `Please enter Street Address.`,
    },
    sellingCity: {
      required: `Please enter City.`,
    },
    sellingState: {
      required: `Please enter State.`,
    },
    sellingZipCode: {
      required: `Please enter Zip.`,
      zipError: `Please enter a valid Zip.`,
    },
    sellingPropertyType: {
      required: `Please enter Property Type.`,
    },
    sellingOwnershipType: {
      required: `Please enter Ownership Type.`,
    },
    receivingAgentFirstName: {
      required: `Please enter First Name.`,
      pattern: `Please use only letters, a space, and -' characters.`,
    },
    receivingAgentLastName: {
      required: `Please enter First Name.`,
      pattern: `Please use only letters, a space, and -' characters.`,
    },
    receivingAgentCompany: {
      required: `Please enter Company.`,
      maxlength: `Please enter Company.`,
    },
    receivingAgentEmail: {
      required: `Please enter a valid email address.`,
      pattern: `Please enter a valid email address.`,
      maxlength: `Please enter a valid email address.`,
    },
    receivingAgentPhone: {
      required: `Please enter Phone.`,
      pattern: `Please enter a valid Phone.`,
      minLength: `Please enter a valid Phone.`,
    },
    receivingAgentCity: {
      required: `Please enter City.`,
      maxlength: `Please enter City.`,
    },
    receivingAgentState: {
      required: `Please enter a state.`,
    },
    receivingAgentZip: {
      required: `Please enter Zip.`,
      zipError: `Please enter a valid Zip.`,
    },
  },
  formErrors: {
    customerFirstName: ``,
    customerLastName: ``,
    customerEmail: ``,
    customerPhone: ``,
    customerPhoneType: ``,
    customerStreetAddress: ``,
    customerCity: ``,
    customerState: ``,
    customerZipCode: ``,
    customerPreference: ``,
    referringAgentFirstName: ``,
    referringAgentLastName: ``,
    referringAgentEmail: ``,
    referringAgentPhone: ``,
    buyingCity: ``,
    buyingState: ``,
    maximumPrice: ``,
    houseHuntingStartDate: ``,
    houseHuntingEndDate: ``,
    sellingStreetAddress: ``,
    sellingCity: ``,
    sellingState: ``,
    sellingZipCode: ``,
    receivingAgentFirstName: ``,
    receivingAgentLastName: ``,
    receivingAgentCompany: ``,
    receivingAgentEmail: ``,
    receivingAgentPhone: ``,
    receivingAgentCity: ``,
    receivingAgentState: ``,
    receivingAgentZip: ``,
  },
  submitMessages: {
    successMessage: `We will be in touch with you shortly to connect you and your customer with a STAR agent.`,
    errorMessage: `Sorry we have encountered an issue with your request.
    Please contact us at <span>questions@cbrefer.com</span> for assistance or try again in a few minutes.`,
  },
  errorMessageForDashRoles: {
    error: `You are not authorized to access this application. If you
       believe you have received this message in error, please contact us at email@email.com or xxx-xxx-xxxx.`,
  },
  saveMessages: {
    successMessage: `Your progress has been saved as a draft.`,
    errorMessage: `Sorry we have encountered an issue with your request. Please contact us at
     <span>questions@cbrefer.com</span> for assistance or try again in a few minutes.`,
    maxDraftMessage: `An error occurred while save referral: Referral Draft maximum count is 20`,
    maxDraftErrorMessage: `You have reached your draft limit. Select View Drafts to delete an entry or click Continue New Referral to proceed without saving. Please note you will still be able to submit your referral.`,
  },
  getDraft: {
    errorMessage: `We are unable to process your request at this time.<br/> Please try again later.`,
  },
  deleteDraft: {
    confirmMessage: `Are you sure you want to delete this draft? <br />This action cannot be undone.`,
    successMessage: `Draft has been successfully deleted.`,
    errorMessage: `We are unable to process your request at this time.<br/> Please try again later.`,
  },
  warningMessages: {
    dirtyCheckConfirmation: `Please note: You have unsaved changes that may be lost. Are you sure you want continue?`,
  },
  sessionWarningMessage: {
    confirmationMessage: `Your session is about to expire. To stay logged in, please click OK.`,
  },
  mobileWidth: 415,
  CB_Brand: `coldwellbanker`,
  cbProperties: {
    image: `test`,
    videoLink: 'https://www.youtube.com/embed/ZHdJoWZvZrc?autoplay=0&rel=0',
    slider_image: `./assets/images/recruitnow-bgrnd-nologo.jpg`,
    cover_image: `./assets/images/testimonials-bgrnd9.jpg`,
    // span_text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
    // heading_text: `tincidunt. eget. nullam.`,
    // btn_text: `vestibulum sed`,
    cover_span_text: `tortor at risus?`,
    para_text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
     do eiusmod tempor incididunt ut labore et dolore magna aliqua. `,
    banner_btn_text: `vestibulum sed`,
    registration_text: `vestibulum sed`,
    step_1: `./assets/icons/bulb.png`,
    step_2: `./assets/icons/regiformicon.png`,
    step_3: `./assets/icons/earnincome.png`,
    aboutTheProgramTitle: `ABOUT THE PROGRAM`,
    aboutTheProgramHeading: `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
    aboutTheProgramParagraph1: `Lorem Ipsum has been the industry's standard dummy text ever
     since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    aboutTheProgramParagraph2: `Lorem Ipsum has been the industry's standard dummy text ever
     since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    howItWorksTitle: `HOW IT WORKS`,
    howItWorksHeading: `Contrary to popular belief, Lorem Ipsum is not simply random text`,
    howItWorksFirstCardName: `1. Content here`,
    howItWorksSecondCardName: `2. Content here`,
    howItWorksThirdCardName: `3. Content here`,
    video_heading_text: `A LOOK INSIDE CB REFER`,
    video_content_text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
     dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
     Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat
      non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    firstImgContent: {
      span_text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
      heading_text: `tincidunt. eget. nullam.`,
      btn_text: `Refer Now`,
    },
    secondImgContent: {
      span_text: ` sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
      heading_text: `dui. ut. ornare.`,
      btn_text: `Refer Now`,
    }
  },
  commentsLength: 1000,
  minPriceRange: [
    { name: 'No Minimum', value: 0 },
    { name: '$100,000', value: 100000 },
    { name: '$200,000', value: 200000 },
    { name: '$300,000', value: 300000 },
    { name: '$400,000', value: 400000 },
    { name: '$500,000', value: 500000 },
    { name: '$600,000', value: 600000 },
    { name: '$700,000', value: 700000 },
    { name: '$800,000', value: 800000 },
    { name: '$900,000+', value: 900000 }
  ],
  maxPriceRange: [
    { name: '$100,000', value: 100000 },
    { name: '$200,000', value: 200000 },
    { name: '$300,000', value: 300000 },
    { name: '$400,000', value: 400000 },
    { name: '$500,000', value: 500000 },
    { name: '$600,000', value: 600000 },
    { name: '$700,000', value: 700000 },
    { name: '$800,000', value: 800000 },
    { name: '$900,000', value: 900000 },
    { name: '$1,000,000+', value: 1000000 },
    { name: 'No Maximum', value: 0 }
  ],
  estimationValues: [
    { name: 'No Estimated Value', value: 'none' },
    { name: '$50,000', value: 50000 },
    { name: '$100,000', value: 100000 },
    { name: '$150,000', value: 150000 },
    { name: '$200,000', value: 200000 },
    { name: '$250,000', value: 250000 },
    { name: '$300,000', value: 300000 },
    { name: '$350,000', value: 350000 },
    { name: '$400,000', value: 400000 },
    { name: '$450,000', value: 450000 },
    { name: '$500,000', value: 500000 },
    { name: '$600,000', value: 600000 },
    { name: '$700,000', value: 700000 },
    { name: '$800,000', value: 800000 },
    { name: '$900,000', value: 900000 },
    { name: '$1,000,000+', value: 1000000 },
  ],
  stepsMapping: {
    zero: 0,
    first: 1,
    second: 2,
    third: 3,
    fourth: 4,
    fifth: 5,
    sixth: 6,
    seventh: 7,
    eighth: 8,
    ninth: 9,
    tenth: 10,
    eleventh: 11,
    twelveth: 12,
    thirteenth: 13,
  },
  referralTypeChange: `Changing your type of referral will clear your data and start the process over.`,
  transitionMessage: `Lets continue with your selling information.`,
  buyingAdditionalInitialValues: {
    minPrice: 0,
    maxPrice: 0,
    noOfBeds: 0,
    noOfBaths: 0,
    comments: null
  },
  sellingAdditionalInitialValues: {
    ownershipType: 0,
    estimationValue: '',
    comments: null
  },
  defaultAgentText: `An Operations Specialist will be in contact with you to provide agent assignment information.`,
  sortOptions: [
    { name: 'Best Match', value: 'bestMatch' },
    // { name: 'Name', value: 'lName' },
    // { name: 'Experience', value: 'experience' },
    { name: 'Transactions', value: 'numberOfClosedTransaction' },
    { name: 'Avg. Sales Price', value: 'last12MonthsAvgSalesPrice' }
  ],
  errorMessageForAgents: {
    error: `Sorry we have encountered an issue with your request. Please contact us at
      <span>questions@cbrefer.com</span> for assistance or try again in a few minutes.`
  },
  createReferralScreens: [
    {
      flow: 'buy', screens: [
        { step: 2, name: 'Buying Location' },
        { step: 3, name: 'Buying Property Types' },
        { step: 4, name: 'Buying Additional Details' },
        { step: 5, name: 'Buying Referral Fee' },
        { step: 6, name: 'Buying Agent Selection' },
        { step: 7, name: 'Confirmation' }
      ]
    }, {
      flow: 'sell', screens: [
        { step: 2, name: 'Selling Location' },
        { step: 3, name: 'Selling Property Types' },
        { step: 4, name: 'Selling Additional Details' },
        { step: 5, name: 'Selling Referral Fee' },
        { step: 6, name: 'Selling Agent Selection' },
        { step: 7, name: 'Confirmation' }
      ]
    }, {
      flow: 'both', screens: [
        { step: 2, name: 'Buying Location' },
        { step: 3, name: 'Buying Property Types' },
        { step: 4, name: 'Buying Additional Details' },
        { step: 5, name: 'Buying Referral Fee' },
        { step: 6, name: 'Buying Agent Selection' },
        { step: 7, name: 'Selling Location' },
        { step: 8, name: 'Selling Property Types' },
        { step: 9, name: 'Selling Additional Details' },
        { step: 10, name: 'Selling Referral Fee' },
        { step: 11, name: 'Selling Agent Selection' },
        { step: 12, name: 'Confirmation' }
      ]
    }
  ],
  feedbackSuccessMessage: 'Thank you, your feedback has been sent',
  propertySpecializationKeyValue: {
    'Single Family': [
      'Single Family Attached',
      'Single Family Detached',
      'Hacienda',
      'Historical',
      'Single Family Residence'
    ],
    'Townhouse': [
      'Semi-Detached',
      'Townhouse'
    ],
    'New Construction': [
      'Other Residential'
    ],
    'Condo / Co-op': [
      'Apartment',
      'Co-Op',
      'Condominium',
      'Duplex',
      'Flat',
      'Fourplex',
      'Multi-Family',
      'Multi-Family (2-4 Units)',
      'Multi-Family Home',
      'Triplex',
      'Multi-Family Residence',
      'Condo',
      'Co-op',
    ],
    'Commercial': [
      'Commercial-Other',
      'Golf course',
      'Hospitality',
      'Industrial',
      'Office Bldg',
      'Other Business Opportunity',
      'Retail',
      'Commercial Sale',
      'Commercial Lease'
    ],
    'Lot / Land': [
      'Land -Agricultural (Not Zoned)',
      'Land -Industrial',
      'Land -Multi-Family Acreage',
      'Land -Office',
      'Land -Retail',
      'Land -Single Family Acreage',
      'Land-Hospitality',
      'Lots & Land-Other',
      'Redevelopment Land',
      'Residential Lot',
      'Vacant Land',
      'Vacant Land (0-10 Acres)',
      'Vacant Land (10+ Acres)',
      'Land'
    ],
    'Other': [
      'Bed & Breakfast–Res',
      'Boat Slip',
      'Equestrian',
      'Farm/Ranch/Plantation',
      'Fractional Ownership',
      'Mixed Use',
      'Mobile/Manufactured Hm w Land',
      'Mobile/Manufactured Home',
      'Private Island',
      'Recreational',
      'Religious',
      'Rental Property',
      'Row',
      'Special Purpose',
      'Time Share',
      'Vineyard',
      'Vineyard-Res',
      'Winery',
      'Winery-Res',
      '~Unknown',
      'Manufactured/Mobile Homes',
      'Farm',
      'Unknown'
    ]
  },
  cancelReferral: 'Are you sure you want to cancel?',
};
