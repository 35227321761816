<section class="agent-form-section">
  <div>
    <div class="form-wrapper">
      <div class="form-container">
        <div class="referral-questions">
          <p>Is {{ customerName }} buying or selling?</p>
        </div>
        <div class="sell-buy-btn">
          <button class="referral-btn" [ngClass]="{
              'select-customer-active': referralObj['preference'] === 'buy'
            }" (click)="updatePreference('buy')">
            Buying
          </button>
          <button class="referral-btn" [ngClass]="{
              'select-customer-active': referralObj['preference'] === 'sell'
            }" (click)="updatePreference('sell')">
            Selling
          </button>
          <button class="referral-btn" [ngClass]="{
              'select-customer-active': referralObj['preference'] === 'both'
            }" (click)="updatePreference('both')">
            Both
          </button>
        </div>
        <div class="next-back-btn-section">
          <button class="back-btn" (click)="onBackBtnClick()" matStepperPrevious>
            <mat-icon>arrow_back</mat-icon><span>Back</span>
          </button>
          <button class="back-btn cancel-btn" (click)="cancelReferral()">
            <span>Cancel</span>
          </button>
          <button class="next-btn" (click)="updateChange()" matStepperNext [disabled]="!referralObj['preference']">
            <span>Next</span>
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>