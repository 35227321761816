import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AE_WEB_CONST } from '../../models/constants';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public shouldShow = false;
  isShown: boolean = false; // hidden by default
  brandClass = AppConfigService.brandingData.brandClass;
  brandProperties: any;
  brandLogo = `assets/imgs/${AppConfigService.getBrandingData.logo}`;

  @HostListener('window:scroll', ['$event'])
  onScrollEvent(event) {
    if (document.documentElement.scrollTop > 200) {
      this.isShown = true;
    } else {
      this.isShown = false;
    }
  }

  constructor(private appConfig: AppConfigService, private routing: Router) {
    switch (this.brandClass) {
      case 'coldwellbanker':
        this.brandProperties = AE_WEB_CONST.cbProperties;
        break;
      case 'century21':
        break;
      default:
        // this.brandProperties = AE_WEB_CONST.cbProperties;
        break;
    }
  }

  ngOnInit(): void { }

  redirectToOkta() {
    const redirectURL = this.appConfig.getConfig('redirectUrl');
    this.routing.navigate(['/externalRedirect', { externalUrl: redirectURL }], {
      skipLocationChange: true,
    });
  }
  scrollTo(event: Event, className: string): void {
    const isIE = /msie\s|trident\//i.test(window.navigator.userAgent)
    const elementList = document.querySelectorAll('.' + className);
    const element = elementList[0] as HTMLElement;
    const scrollVal = element.offsetTop - 86;
    window.scroll({ top: scrollVal, behavior: 'smooth' });
    if (isIE) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
    if (className !== 'ng-star-inserted') {
      (event.target as HTMLElement).parentNode.parentNode.childNodes.forEach(
        (li) => {
          (li.firstChild as HTMLElement).removeAttribute('class');
        }
      );
      (event.target as HTMLElement).setAttribute('class', 'active');
    }
  }
}
