<div class="referral-questions">
  <p *ngIf=!rrnReferral>
  Any additional details you would like to provide such as requested agent information, preferred language, or any other specifics?</p>
  <p *ngIf=rrnReferral>Are there any additional details you would like to provide?</p>
</div>
<section class="agent-form-section">
  <div>
    <form #addlInfoForm="ngForm" [formGroup]="additionalInfoForm">
      <div class="form-container">

        <div class="input-field-section">
          <div *ngIf="refType === 'buy'" class="row">
            <div class="input-field-form">
              <mat-form-field appearance="outline">
                <mat-label>Min Budget</mat-label>
                <input matInput
                  (keyup) = "SpliceMaxPriceRange($event)"
                  formControlName="minPrice"
                  [errorStateMatcher]="matcher"
                  [matAutocomplete]="autoMin">
                <mat-error
                  *ngIf="additionalInfoForm.hasError('misMatch')"
                  >The maximum amount must always be greater than the minimum amount.
                </mat-error>      
                <mat-autocomplete #autoMin="matAutocomplete">
                  <mat-option *ngFor="let price of minPriceRange" [value]="price.value">
                    {{price.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="input-field-form">
              <mat-form-field appearance="outline">
                <mat-label>Max Budget</mat-label>
                <input matInput
                  (keyup) = "SpliceMinPriceRange($event)"
                  formControlName="maxPrice"
                  [errorStateMatcher]="matcher"
                  [matAutocomplete]="autoMax">
                <mat-error
                  *ngIf="additionalInfoForm.hasError('misMatch')"
                  >The maximum amount must always be greater than the minimum amount.
                </mat-error>      
                <mat-autocomplete #autoMax="matAutocomplete">
                  <mat-option *ngFor="let price of maxPriceRange" [value]="price.value">
                    {{price.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="refType === 'buy'" class="row budget-row">
            <div class="input-field-form">
              <mat-form-field appearance="outline" class="price-input-field">
                <mat-label># of Beds</mat-label>
                <mat-select formControlName="noOfBeds">
                  <mat-option
                    *ngFor="let room of numberOfRooms"
                    [value]="room.value"
                  >
                    {{ room.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="input-field-form">
              <mat-form-field appearance="outline" class="price-input-field">
                <mat-label># of Bathrooms</mat-label>
                <mat-select formControlName="noOfBaths">
                  <mat-option
                    *ngFor="let room of numberOfRooms"
                    [value]="room.value"
                  >
                    {{ room.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="refType === 'sell'" class="row">
            <div class="input-field-form">
              <mat-form-field appearance="outline">
                <mat-label>Ownership Type</mat-label>
                <mat-select formControlName="ownershipType">
                  <mat-option
                    *ngFor="let type of ownershipTypes"
                    [value]="type.value"
                  >
                    {{ type.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="input-field-form">
              <mat-form-field appearance="outline">
                <mat-label>Estimation of Value</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="estimationValue"
                  maxlength="14"
                />
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="refType === 'buy'" [ngClass]="rrnReferral ? 'row rrn-comment' : 'row'">
            <div class="input-field-form input-additional-form">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="additional-comments"
              >
                <span class="textarea-characters">
                  {{
                    additionalCommentsMaxLength -
                      additionalInfoForm.get('buyingComments').value?.length
                  }}
                  characters remaining</span
                >
                <mat-label>Comments</mat-label>
                <textarea class="comment-box"
                  matInput
                  [placeholder]="placeholder"
                  maxlength="1000"
                  formControlName="buyingComments"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="refType === 'sell'" [ngClass]="rrnReferral ? 'row rrn-comment' : 'row'">
            <div class="input-field-form input-additional-form">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="additional-comments additional-comments-selling"
              >
                <span class="textarea-characters">
                  {{
                    additionalCommentsMaxLength -
                      additionalInfoForm.get('sellingComments').value?.length
                  }}
                  characters remaining</span
                >
                <mat-label>Comments</mat-label>
                <textarea class="comment-box"
                  matInput
                  [placeholder]="placeholder"
                  maxlength="1000"
                  formControlName="sellingComments"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="next-back-btn-section">
          <button
            class="back-btn"
            matStepperPrevious
            (click)="onBackBtnClick()"
          >
            <mat-icon>arrow_back</mat-icon><span>Back</span>
          </button>
          <button class="back-btn cancel-btn" (click)="cancelReferral()">
            <span>Cancel</span>
          </button>
          <!-- <button mat-fab color="primary" class="save-btn" aria-label="Save icon">
                        <img src="../../../../../assets/imgs/save_icon.png" />
                    </button> -->
          <button
            class="next-btn skip-btn"
            matStepperNext
            *ngIf="additionalInfoForm.pristine"
            (click)="updateChange()"
          >
            <span>Skip</span>
            <mat-icon>arrow_forward</mat-icon>
          </button>
          <button
            class="next-btn"
            matStepperNext
            *ngIf="!additionalInfoForm.pristine"
            (click)="updateChange()"
            [disabled]="additionalInfoForm.invalid"
          >
            <span>Next</span>
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      </div>
    </form>
  </div>
</section>
