import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from './base-service';
import { ApiConfigService } from './api-config.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandlerService } from './http-error-handler.service';
import { CookieService } from 'ngx-cookie-service';
/**
 * Used to make generic standard API calls.  The base URL for the service calls is based on the configuration.
 */
@Injectable({
  providedIn: 'root'
})
export class BaseClientService extends BaseService {

  /**
   * base constructor
   * @param config API Config service injector
   * @param http HTTP Client injector
   * @param errorHandler HTTP error handler injector
   */

  constructor(
    config: ApiConfigService,
    http: HttpClient,
    private readonly errorHandler: HttpErrorHandlerService,
    private cookieService: CookieService) {
    super(config, http);
  }

  /** Run a GET API call, expecting a response with a single model
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param action The action that is performing the request
   * @return A response containing the expected model (single)
   */
  getOne<TResponseModel>(route: string, action: string = 'error executing requests', urlType?: string,
    params: HttpParams = new HttpParams(), withCredentials: boolean = false): Observable<HttpResponse<TResponseModel>> {
    const url = this.getUrl(urlType);
    let options;
    if (withCredentials) {
      options = {
        headers: this.getRequestHeaders(),
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true
      };
    } else {
      options = {
        headers: this.getRequestHeaders(),
        params: params,
        observe: 'response',
        responseType: 'json'
      };
    }

    return this.http.get<TResponseModel>(
      url + route, options)
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run an external GET API call, expecting a response with a single model
   * @param url The url for the request
   * @param action The action that is performing the request
   * @return A response containing the expected model (single)
   */
  getExt<TResponseModel>(url: string, action: string = 'error executing requests',
    params: HttpParams = new HttpParams()): Observable<HttpResponse<TResponseModel>> {
    return this.http.get<TResponseModel>(
      url,
      {
        headers: this.getRequestHeaders(),
        params: params,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a GET API call, expectiing a response with an array of the expected model
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param action The action that is performing the request
   * @return A response containing the expected models (array)
   */
  getArr<TResponseModel>(route: string, action: string = 'error executing requests', urlType?: string,
    params: HttpParams = new HttpParams(), rrnReferral?: boolean): Observable<HttpResponse<Array<TResponseModel>>> {
    const url = this.getUrl(urlType);
    const headers = this.getRequestHeaders(rrnReferral)
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache')
      .set('Expires', '0');
    return this.http.get<TResponseModel>(
      url + route,
      {
        headers: headers,
        params: params,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a PUT API call
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being updated
   * @param action The action that is performing the request
   * @return A response containing the expected result (single)
   */
  put<TResponseModel>(route: string, body: any, action: string = 'error putting request', urlType?: string,
    params: HttpParams = new HttpParams()): Observable<HttpResponse<TResponseModel>> {
    const url = this.getUrl(urlType);
    return this.http.put<TResponseModel>(url + route,
      body,
      {
        headers: this.getRequestHeaders(),
        params: params,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run an external POST API call
   * @param url The url for the request
   * @param body The object that is being posted
   * @param action The action that is performing the request
   * @return A response containing the expected result (single)
   */
  postExt<TResponseModel>(url: string, body: any, action: string = 'error posting request',
    params: HttpParams = new HttpParams()): Observable<HttpResponse<TResponseModel>> {
    const header = new HttpHeaders({
      'content-type': 'application/x-www-form-urlencoded'
    })
    return this.http.post<TResponseModel>(url,
      body,
      {
        headers: header,
        params: params,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a POST API call
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being posted
   * @param action The action that is performing the request
   * @return A response containing the expected result (single)
   */
  post<TResponseModel>(route: string, body: any, action: string = 'error posting request', urlType?: string,
    params: HttpParams = new HttpParams(), rrnReferral?: boolean): Observable<HttpResponse<TResponseModel>> {
    const url = this.getUrl(urlType);
    return this.http.post<TResponseModel>(url + route,
      body,
      {
        headers: this.getRequestHeaders(rrnReferral),
        params: params,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a DELETE API call
   * @param route The endpoint for the delete request
   * @param action The action that is performing the request
   * @return A response containing the expected result
   */
  delete<TResponseModel>(route: string, action: string = 'error delete request', urlType?: string,
    params: HttpParams = new HttpParams()): Observable<HttpResponse<TResponseModel>> {
    const url = this.getUrl(urlType);
    return this.http.delete<TResponseModel>(
      url + route,
      {
        headers: this.getRequestHeaders(),
        params: params,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }


  /** Run a GET API call, expecting a response with a single model
  * @param route The endpoint for the request (ie. - '/v1/reports_recent')
  * @param action The action that is performing the request
  * @return A response containing the expected model (single)
  */
  getById<TResponseModel>(
    route: string,
    action: string = 'error executing requests',
    urlType?: string
  ): Observable<HttpResponse<TResponseModel>> {
    const url = this.getUrl(urlType);
    const headers = this.getRequestHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache')
      .set('Expires', '0');
    return this.http
      .get<TResponseModel>(url + route, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: headers
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }


  private getRequestHeaders(isRrnReferral?: boolean): HttpHeaders {
    // Only send headers which have values to send
    const rrnReferral = isRrnReferral ? 'Referral Agent Web Form' : 'web';
    let headers = new HttpHeaders();
    const token = this.cookieService.get('car-ses-tok');
    if (token) {
      const context = sessionStorage.getItem('car-ses-con');
      if (context) {
        headers = new HttpHeaders({
          'Authorization': token,
          'Channel': 'web',
          'content-type': 'application/json',
          'PersonId': context
        });
      } else {
        headers = new HttpHeaders({
          'Authorization': token,
          'Channel': 'web',
          'content-type': 'application/json'

        });
      }
    } else {
      headers = new HttpHeaders({
        'Channel': rrnReferral,
        'content-type': 'application/json'
      });
    }

    return headers;
  }

  private getUrl(urlType: string) {
    switch (urlType) {
      case `agent_api`:
        return this.agentRootUrl;
      case `agents_api`:
        return this.agentsRootUrl;
      default:
        return this.referralRootUrl;
    }
  }
}
