import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-program-description',
  templateUrl: './program-description.component.html',
  styleUrls: ['./program-description.component.scss']
})
export class ProgramDescriptionComponent implements OnInit {
  @Input() brandProperties: any;

  constructor() { }

  ngOnInit(): void {
  }

}
