import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  brandLogo = `assets/imgs/logo.png`;
  constructor(
    private readonly router: Router,
    private readonly appConfig: AppConfigService,
    private cookieSvc: CookieService
  ) { }

  ngOnInit() {
    if (this.cookieSvc.get('car-ses-id') && localStorage.getItem('logoutType')) {
      this.logOut();
    }
    sessionStorage.clear();
    this.cookieSvc.deleteAll();
    localStorage.clear();
  }

  redirectToOkta() {
    const redirectURL = <string>this.appConfig.getConfig('redirectUrl');
    //  redirectURL = redirectURL.replace('idToken', this.cookieSvc.get('car-ses-id'));
    this.router.navigate(['/externalRedirect', { externalUrl: redirectURL }], {
      skipLocationChange: true
    });
  }

  logOut() {
    let redirectURL = <string>this.appConfig.getConfig('logoutUrl');
    console.log(this.cookieSvc.get('car-ses-id'));

    redirectURL = redirectURL.replace('idToken', this.cookieSvc.get('car-ses-id'));
    console.log(redirectURL);

    this.router.navigate(['/externalRedirect', { externalUrl: redirectURL }], {
      skipLocationChange: true
    });
  }
}
