<div class="header">
  <div *ngIf="agentHasDashRoles" class="header-menu-icon">
    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav-content>
        <button mat-button aria-label="Menu icon" (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  <div class="header-brand" *ngIf="!rrnReferral || AppMaintenance">
    <img [src]="brandLogo" />
  </div>
  <div class="header-brand header-brand-rrn" *ngIf="rrnReferral">
    <img src="../../../../assets/imgs/logo_cbrealtyreferralnetwork.png" />
  </div>
  <!-- <div class="draft-btn-icon">
    <button class="draft-btn">
      <mat-icon>bookmark</mat-icon>
      <span>Save as Draft</span>
    </button>
  </div> -->
  <div *ngIf="agentHasDashRoles" class="header-profile">
    <button mat-button [matMenuTriggerFor]="menu" [disableRipple]="true" aria-label="Profile dropdown menu">
      <span *ngIf="!agentDetail.agent.photoUrl" class="candidate-short-name">
        {{ agentInitials }}
      </span>
      <span *ngIf="agentDetail.agent.photoUrl" class="header-profile-img">
        <img [src]="agentDetail.agent.photoUrl" (error)="setInitials()" />
      </span>
      <span class="header-profile-title hidden-md">{{ fullName }}</span>

      <span class="header-profile-dropdown">
        <mat-icon>arrow_drop_down</mat-icon>
      </span>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <!-- <button mat-menu-item (click)="viewAgentProfile()" class="profile-btn">
        <img src="../../../../assets/imgs/profile_icon.png" /><span>View My Profile</span>
      </button> -->
      <button mat-menu-item (click)="logout()" class="logout-btn">
        <img src="../../../../assets/imgs/logout.png" /><span>Logout</span>
      </button>
    </mat-menu>
  </div>
  <div class="help-tooltip" *ngIf="rrnReferral">
  <a class="tooltip">
    <mat-icon style="font-size: 28px;">help_outline </mat-icon>
    <p class="help-text">Help</p>
    
    <span class="tooltiptext">Need assistance?<span style="display: inline-flex;font-weight: 700;"><a
          href="mailTo:CBRRNReferrals@nrtllc.com" class="email-us">Email
          us directly.</a>
        <mat-icon class="email-icon">email</mat-icon>
      </span>
    </span>
  </a>
</div>
</div>

<mat-sidenav *ngIf="agentHasDashRoles" #sidenav disableClose (keydown.escape)="close()">
  <div class="left-nav-wrapper">
    <div class="profile row">
      <div>
        <img src="../../../../assets/imgs/brand-cb.png" class="brand-img" />
        <p class="profile-title">{{ fullName }}</p>
        <p class="profile-description">
          {{ agentDetail.agent.companyName }}
        </p>
      </div>
      <button mat-button aria-label="Close icon" class="menu-close" (click)="sidenav.toggle()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ul class="left-nav-wrapper-menus">
      <li [routerLink]="['/createreferral']">
        <a href="javascript:void(0)" (click)="close()">
          <mat-icon>add_circle</mat-icon><span>Create Referral</span>
        </a>
      </li>
      <li>
        <a routerLink="/drafts" (click)="close()" routerLinkActive="active" matTooltip="Draft">
          <mat-icon><img src="../../../../assets/imgs/drafts-menu-icon.png" /></mat-icon><span>Drafts</span>
        </a>
      </li>
      <li>
        <a (click)="logout()" class="logout-btn">
          <img src="../../../../assets/imgs/logout_icon_white.png" /><span>Logout</span>
        </a>
      </li>
      <li class="assistance">
        <a class="need-assitance">
          <mat-icon>email</mat-icon>
          <span>Need assistance?
            <a href="mailTo:questions@cbrefer.com" class="email-us">Email us directly.</a></span>
        </a>
      </li>
    </ul>
  </div>
</mat-sidenav>
