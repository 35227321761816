<footer class="footer">
  <div [ngClass]="rrnReferral ? 'container-rrn' : 'container'">
    <div class="footer-copyright-container">
      <span *ngIf="!rrnReferral">©{{currentYear}} Coldwell Banker Real Estate LLC. </span>
      <span *ngIf="rrnReferral">©{{currentYear}} Coldwell Banker Realty Referral Network</span>
    </div>
    <!-- <div class="footer-menu-container">
      <ul>
        <li><a (click)="onClickLinks()">Terms of Use</a></li>
        <li><a (click)="onClickLinks()">Privacy Policy</a></li>
      </ul>
    </div> -->
  </div>
</footer>