import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';

@Injectable({
  providedIn: 'root'
})
export class AgentSelectionService {

  private isAgentAvailable: boolean;

  private buyingAgentListDataSource = new BehaviorSubject<any>(null);
  buyingAgentList = this.buyingAgentListDataSource.asObservable();

  private sellingAgentListDataSource = new BehaviorSubject<any>(null);
  sellingAgentList = this.sellingAgentListDataSource.asObservable();
  
  private sameReceivingAgentDataSource = new BehaviorSubject<any>(null);
  sameReceivingAgent = this.sameReceivingAgentDataSource.asObservable();

  private buyingAgentBestMatchSource = new BehaviorSubject<any>(null);
  buyingAgentBestMatch = this.buyingAgentBestMatchSource.asObservable();

  private sellingAgentBestMatchSource = new BehaviorSubject<any>(null);
  sellingAgentBestMatch = this.sellingAgentBestMatchSource.asObservable();

  /**
   * Base Constructor
   * @param baseClientSvc Instance of BaseClientService
   * @param logSvc Instance of RemoteLoggingService
   */
  constructor(
    private baseClient: BaseClientService,
    private logSvc: RemoteLoggingService
  ) { }

  getAgentSelectionList(paramsObj: any): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    Object.keys(paramsObj).forEach(function (key) {
      httpParams = httpParams.append(key, paramsObj[key]);
    });
    return this.baseClient
      .getArr<string>('/b2b/receivingConfigAgents', 'get agent selection List', 'agents_api', httpParams)
      .pipe(
        map(r => r.body),
        catchError((err) => {
          const emptyResponse = {};
          this.logSvc.logError(err);
          return of(err);
        })
      );
  }

  updateBuyingAgentList(response: any) {
    this.buyingAgentListDataSource.next(response);
  }

  updateSellingAgentList(response: any) {
    this.sellingAgentListDataSource.next(response);
  }
  
  updateSameReceivingAgent(response: any) {
    this.sameReceivingAgentDataSource.next(response);
  }

  get agentAvailable() {
    return this.isAgentAvailable;
  }
  
  set agentAvailable(value) {
    this.isAgentAvailable = value;
  }

  updateBuyingAgentBestMatch(response: any) {
    this.buyingAgentBestMatchSource.next(response);
  }

  get buyingAgentBestMatchValue() {
    return this.buyingAgentBestMatchSource.getValue();
  }

  updateSellingAgentBestMatch(response: any) {
    this.sellingAgentBestMatchSource.next(response);
  }

  get sellingAgentBestMatchValue() {
    return this.sellingAgentBestMatchSource.getValue();
  }

}
