import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { catchError, map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { AgentDetails } from '../models/AgentDetails';
import { AgentDetailService } from './agent-detail.service';

@Injectable({
  providedIn: 'root'
})
export class ReferralService {
  agentDetails: AgentDetails;
  /**
   * Base Constructor
   * @param baseClientSvc Instance of BaseClientService
   * @param logSvc Instance of RemoteLoggingService
   */
  constructor(
    private baseClient: BaseClientService,
    private logSvc: RemoteLoggingService,
    private agentDetailSvc: AgentDetailService
  ) { }

  submitReferral(referralObj, rrnReferral = false): Observable<any> {
    const requestObj = this.createReferralObject(referralObj, false, rrnReferral);
    return this.baseClient.post('/b2b/referral', requestObj, 'submit referral', null, null, rrnReferral)
      .pipe(
        map(r => r),
        catchError((err) => {
          const emptyResponse = {};
          this.logSvc.logError(err);
          return of(emptyResponse);
        })
      );
  }

  saveReferral(referralObj): Observable<any> {
    const requestObj = this.createReferralObject(referralObj, true);
    return this.baseClient.post('/b2b/referral', requestObj, 'save referral')
      .pipe(
        map(r => r),
        catchError((err) => {
          const errorResponse = { message: err.message };
          this.logSvc.logError(err);
          return of(errorResponse);
        })
      );
  }

  getDraftList(limit: number, skip: number): Observable<any> {
    return this.baseClient.getArr(`/b2b/referral/draft?limit=${limit}&skip=${skip}`, 'get DraftList')
      .pipe(
        map(r => r),
        catchError((err) => {
          const emptyResponse = {};
          this.logSvc.logError(err);
          return of(emptyResponse);
        })
      );
  }

  getDraftById(draftId: string) {
    return this.baseClient.getById(`/b2b/referral/${draftId}`, 'get Draft by id')
      .pipe(
        map(r => r),
        catchError((err) => {
          const emptyResponse = null;
          this.logSvc.logError(err);
          return of(emptyResponse);
        })
      );
  }

  updateReferralDraft(draftId, referralObj, submit = false): Observable<any> {
    const requestObj = this.createReferralObject(referralObj, !submit);
    return this.baseClient.put(`/b2b/referral/draft/${draftId}`, requestObj, 'update referral')
      .pipe(
        map(r => r),
        catchError((err) => {
          const emptyResponse = {};
          this.logSvc.logError(err);
          return of(emptyResponse);
        })
      );
  }

  deleteDraft(referralId: string) {
    return this.baseClient.delete(`/b2b/referral/draft/${referralId}`, 'delete draft')
      .pipe(
        map(r => r.body),
        catchError((err) => {
          const emptyResponse = {};
          this.logSvc.logError(err);
          return of(emptyResponse);
        })
      );
  }

  createReferralObject(referralObj, isDraft = false, isRrnReferral = false) {
    this.agentDetailSvc.loggedInAgentDetail.subscribe((agent: AgentDetails) => {
      this.agentDetails = agent;
    });
    const requestObj = {
      createdChannel: 'Web',
      createdSystem: 'B2B',
      currentProgress: referralObj.currentStep,
      customerInfo: {
        firstName: referralObj.customerInfo.customerFirstName ? referralObj.customerInfo.customerFirstName : undefined,
        lastName: referralObj.customerInfo.customerLastName ? referralObj.customerInfo.customerLastName : undefined,
        secondaryFirstName: referralObj.secCustomerInfo && referralObj.secCustomerInfo.secondaryFirstName ?
         referralObj.secCustomerInfo.secondaryFirstName : undefined,
        secondaryLastName:  referralObj.secCustomerInfo && referralObj.secCustomerInfo.secondaryLastName ?
          referralObj.secCustomerInfo.secondaryLastName : undefined,
        contact: {
          primaryEmail: referralObj.customerInfo.customerEmail ? referralObj.customerInfo.customerEmail : undefined,
          primaryMobile: referralObj.customerInfo.customerPhone ? referralObj.customerInfo.customerPhone.replace(/\D/g, '')
            .replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3') : undefined,
          primaryNumberFlag: 2,
          secondaryEmail:  referralObj.secCustomerInfo && referralObj.secCustomerInfo.secondaryEmail ?
           referralObj.secCustomerInfo.secondaryEmail : undefined,
          secondaryMobile:  referralObj.secCustomerInfo && referralObj.secCustomerInfo.secondaryMobile ?
           referralObj.secCustomerInfo.secondaryMobile.replace(/\D/g, '')
            .replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3') : undefined,
        },
      },
      isReferralAGR: false,
      isWebBothBuySell: referralObj.preference === 'both',
      recevingAgentType: '',
      status: isDraft ? 'draft' : 'submit',
      referralAgent: isRrnReferral ? {
        firstName: referralObj.agentInformation.firstName,
        lastName: referralObj.agentInformation.lastName,
        agentLicenseState: referralObj.agentInformation.licenseState,
        contact: {
          phone: referralObj.agentInformation.phoneNo,
          email: referralObj.agentInformation.email
        }
      } : {
          agentFullName: this.agentDetails.agent.name,
          agentId: this.agentDetails.agent.id,
          brokarageId: this.agentDetails.agent.brokerId,
          brokarageName: this.agentDetails.agent.companyName,
          cityName: this.agentDetails.agent.cityName,
          companyId: this.agentDetails.agent.companyId,
          companyRFGId: this.agentDetails.agent.companyRFGId,
          contact: {
            email: this.agentDetails.agent.emailAddress,
            phone: this.agentDetails.agent.businessPhone,
            phoneType: 'business'
          },
          firstName: this.agentDetails.agent.firstName,
          lastName: this.agentDetails.agent.lastName,
          officeId: this.agentDetails.agent.officeId,
          officeStreet: this.agentDetails.agent.officeStreet,
          officeRFGId: this.agentDetails.agent.officeRFGId,
          postalCode: this.agentDetails.agent.postalCode,
          stateCode: this.agentDetails.agent.stateCode
        },
      service: []
    };
    if (referralObj.preference === 'buy' || referralObj.preference === 'both') {
      requestObj.service.push({
        type: 'Buying',
        property: {
          address: {
            street: referralObj.buyLocation && referralObj.buyLocation.location ?
              referralObj.buyLocation.location.streetAddress : undefined,
            city: referralObj.buyLocation && referralObj.buyLocation.location ? referralObj.buyLocation.location.city : undefined,
            country: 'US',
            state: referralObj.buyLocation && referralObj.buyLocation.location ? referralObj.buyLocation.location.state : undefined,
            zipcode: referralObj.buyLocation && referralObj.buyLocation.location ? referralObj.buyLocation.location.zipcode : undefined
          },
          propertyInfo: referralObj.buyingPropertyTypes && referralObj.buyingPropertyTypes.length > 0 ?
            referralObj.buyingPropertyTypes : undefined,
          priceRange: {
            minPrice: referralObj.buyingAdditionalDetails && referralObj.buyingAdditionalDetails.minPrice > 0 ?
              referralObj.buyingAdditionalDetails.minPrice : undefined,
            maxPrice: referralObj.buyingAdditionalDetails && referralObj.buyingAdditionalDetails.maxPrice > 0 ?
              referralObj.buyingAdditionalDetails.maxPrice : undefined
          },
          noOfBedRooms: referralObj.buyingAdditionalDetails && referralObj.buyingAdditionalDetails.noOfBeds > 0 ?
            referralObj.buyingAdditionalDetails.noOfBeds : undefined,
          noOfBathRooms: referralObj.buyingAdditionalDetails && referralObj.buyingAdditionalDetails.noOfBaths > 0 ?
            referralObj.buyingAdditionalDetails.noOfBaths : undefined,
          additionalComments: referralObj.buyingAdditionalDetails && referralObj.buyingAdditionalDetails.comments ?
            referralObj.buyingAdditionalDetails.comments : undefined
        },
        requestedReferralFee: referralObj.buyReferralFee ? referralObj.buyReferralFee.toString() : undefined,
        offrampReason: referralObj.buyOfframpReason
      });
    }
    if (referralObj.preference === 'sell' || referralObj.preference === 'both') {
      requestObj.service.push({
        type: 'Selling',
        property: {
          address: {
            aptUnit: referralObj.sellAddress && referralObj.sellAddress.apiUnit ? referralObj.sellAddress.apiUnit : undefined,
            street: referralObj.sellAddress && referralObj.sellAddress.address ? referralObj.sellAddress.address.streetAddress : undefined,
            city: referralObj.sellAddress && referralObj.sellAddress.address ? referralObj.sellAddress.address.city : undefined,
            country: 'US',
            state: referralObj.sellAddress && referralObj.sellAddress.address ? referralObj.sellAddress.address.state : undefined,
            zipcode: referralObj.sellAddress && referralObj.sellAddress.address ? referralObj.sellAddress.address.zipcode : undefined
          },
          propertyInfo: referralObj.sellingPropertyType ? [referralObj.sellingPropertyType] : undefined,
          ownershipType: referralObj.sellingAdditionalDetails && referralObj.sellingAdditionalDetails.ownershipType > 0 ?
            [referralObj.sellingAdditionalDetails.ownershipType] : undefined,
          additionalComments: referralObj.sellingAdditionalDetails && referralObj.sellingAdditionalDetails.comments ?
            referralObj.sellingAdditionalDetails.comments : undefined,
          estimatedValue: referralObj.sellingAdditionalDetails && referralObj.sellingAdditionalDetails.estimationValue > 0 ?
            referralObj.sellingAdditionalDetails.estimationValue.replace(/\D/g, '').toString() : undefined
        },
        requestedReferralFee: referralObj.sellReferralFee ? referralObj.sellReferralFee.toString() : undefined,
        offrampReason: referralObj.sellOfframpReason
      });
    }
    if (!isDraft) {
      if (referralObj.buyingAgentSelected) {
        requestObj.recevingAgentType = 'Agent';
        requestObj['receivingAgents'] = {
          assignedDt: '',
          assignedId: '',
          firstName: referralObj.buyingAgentSelected.agentFirstName,
          lastName: referralObj.buyingAgentSelected.agentLastName,
          companyRFGId: referralObj.buyingAgentSelected.companyRFGId,
          emailid: referralObj.buyingAgentSelected.mailId,
          phoneNo: referralObj.buyingAgentSelected.phoneNo ?
            referralObj.buyingAgentSelected.phoneNo.replace(/\D/g, '').replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3') : '',
          phoneType: 'Mobile',
          cityName: referralObj.buyingAgentSelected.address.split(',')[0].trim(),
          stateCode: referralObj.buyingAgentSelected.address.split(',')[1].trim(),
          postalCode: referralObj.buyingAgentSelected.address.split(',')[2].trim(),
          id: referralObj.buyingAgentSelected.agentId,
          brokarageId: referralObj.buyingAgentSelected.brokerId,
          companyName: referralObj.buyingAgentSelected.companyName,
          agentReferralCertified: referralObj.buyingAgentSelected.agentReferralCertified,
          agentRank: referralObj.buyingAgentSelected.agentRank
        };
      }
      if (referralObj.sellingAgentSelected) {
        requestObj.recevingAgentType = 'Agent';
        const agentDetails = {
          assignedDt: '',
          assignedId: '',
          firstName: referralObj.sellingAgentSelected.agentFirstName,
          lastName: referralObj.sellingAgentSelected.agentLastName,
          companyRFGId: referralObj.sellingAgentSelected.companyRFGId,
          emailid: referralObj.sellingAgentSelected.mailId,
          phoneNo: referralObj.sellingAgentSelected.phoneNo ?
            referralObj.sellingAgentSelected.phoneNo.replace(/\D/g, '').replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3') : '',
          phoneType: 'Mobile',
          cityName: referralObj.sellingAgentSelected.address.split(',')[0].trim(),
          stateCode: referralObj.sellingAgentSelected.address.split(',')[1].trim(),
          postalCode: referralObj.sellingAgentSelected.address.split(',')[2].trim(),
          id: referralObj.sellingAgentSelected.agentId,
          brokarageId: referralObj.sellingAgentSelected.brokerId,
          companyName: referralObj.sellingAgentSelected.companyName,
          agentReferralCertified: referralObj.sellingAgentSelected.agentReferralCertified,
          agentRank: referralObj.sellingAgentSelected.agentRank
        };
        referralObj.preference === 'both' ?
          requestObj['receivingBothFlowSecondAgent'] = agentDetails :
          requestObj['receivingAgents'] = agentDetails;
      }
    }
    return requestObj;
  }
}
