<div class="main-container drafts">
  <h4 class="title-header">Drafts</h4>
  <div *ngIf="draftList?.length === 0" class="no-draft-found-text">
    No drafts found
  </div>
  <div *ngFor="let draft of draftList" class="form-container">
    <div class="row">
      <div>
      <div class="drafts-content-info">
        <p>
          <strong [matTooltip]="draft.fullName" [matTooltipPosition]="position" class="draft-content"
            >{{ draft.fullName }}
          </strong>
        </p>
        <p>
          Type of Referral: <strong>{{ draft.type }}</strong>
        </p>
      </div>
      <div class="mobile-respo-date">Saved on <strong>{{draft.updatedDt | date:'d MMMM y'}}</strong></div>
    </div>
      <div class="drafts-content-actions" style="display: inline-flex;">
        <div  class="desktop-date">Saved on <strong>{{draft.updatedDt | date:'d MMMM y'}}</strong></div>
        <ul>
          <li>
            <button
              mat-button
              class="btn-primary"
              (click)="editReferral(draft.referralId)"
            >
              <img
                src="../../../../assets/imgs/edit_icon.png"
                class="btn-icon"
                alt="Edit"
              /><span>Edit</span>
            </button>
          </li>
          <li>
            <button
              mat-button
              class="link-danger"
              (click)="confirmDelete(draft)"
            >
              <img
                src="../../../../assets/imgs/delete_icon.png"
                class="btn-icon btn-desktop"
                alt="Delete"
              /><img
                src="../../../../assets/imgs/delete_icon_blue.png"
                class="btn-icon btn-mobile"
                alt="Delete"
              />Delete
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <mat-paginator
    [length]="pageInfo.totalCount"
    [pageSize]="pageInfo.pageSize"
    [pageSizeOptions]="pageInfo.pageSizeOptions"
    (page)="onPagination($event)"
  ></mat-paginator>
  <div>
    <button class="feedback-btn" (click)="openFeedbackPopup()"><img src="../../../../assets/icons/icon_feedback.png"><p>Feedback</p></button>
  </div>
</div>
