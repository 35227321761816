import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './core/components/login/login.component';
import { LogoutComponent } from './core/components/logout/logout.component';
import { ExternRouteComponent } from './core/components/extern-route/extern-route.component';
import { DirtyCheckGuardService } from './core/services/dirty-check-guard.service';
import { HeaderComponent } from './core/components/header/header.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { CookieService } from 'ngx-cookie-service';
import { APP_BASE_HREF, CurrencyPipe, DatePipe } from '@angular/common';
import { AuthGuardService } from './core/services/auth-guard.service';
import { SnackbarService } from './core/services/snackbar.service';
import { DashboardComponent } from './public/referral/dashboard/dashboard.component';
import { CreateReferralComponent } from './public/referral/create-referral/create-referral.component';
import { PhoneFormatDirective } from './core/directives/phone-format.directive';
import { InfoDialogComponent } from './core/components/info-dialog/info-dialog.component';
import { SnackbarComponent } from './core/components/snackbar/snackbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ActionDialogComponent } from './core/components/action-dialog/action-dialog.component';
import { ZipFormatDirective } from './core/directives/zip-format.directive';
import { DraftsReferralsComponent } from './public/referral/drafts-referrals/drafts-referrals.component';
import { LeftNavigationComponent } from './core/components/left-navigation/left-navigation.component';
import { HomeComponent } from './core/components/home/home.component';
import { ImageCtaComponent } from './core/components/home/image-cta/image-cta.component';
import { ProgramDescriptionComponent } from './core/components/home/program-description/program-description.component';
import { WorkDescriptionComponent } from './core/components/home/work-description/work-description.component';
import { BrandVideoComponent } from './core/components/home/brand-video/brand-video.component';
import { FaqComponent } from './core/components/faq/faq.component';
import { ReferralTypeComponent } from './public/referral/create-referral/referral-type/referral-type.component';
import { CustomerInfoComponent } from './public/referral/create-referral/customer-info/customer-info.component';
import { LocationComponent } from './public/referral/create-referral/location/location.component';
import { PropertyTypeComponent } from './public/referral/create-referral/property-type/property-type.component';
import { AdditionalInfoComponent } from './public/referral/create-referral/additional-info/additional-info.component';
import { ReferralFeeComponent } from './public/referral/create-referral/referral-fee/referral-fee.component';
import { ConfirmationComponent } from './public/referral/create-referral/confirmation/confirmation.component';
import { GoogleMapsModule } from '@angular/google-maps'
import { environment } from 'src/environments/environment';
import { AgentSelectionComponent } from './public/referral/create-referral/agent-selection/agent-selection.component';
import { WarningDialogComponent } from './core/components/warning-dialog/warning-dialog.component';
import { ThankYouComponent } from './core/components/thank-you/thank-you.component';
import { ShortNumberPipe } from './core/pipes/short-number.pipe';
import { TooltipDirective } from './core/directives/tooltip.directive';
import { FeedbackComponent } from './core/components/feedback/feedback.component';
import { SpinnerComponent } from './core/components/spinner/spinner.component';
import { RrnReferralComponent } from './public/rrn-referral/rrn-referral.component';
import { AgentInfoComponent } from './public/rrn-referral/agent-info/agent-info.component';
import { RrnReceivingAgentInfoComponent } from './public/rrn-referral/rrn-receiving-agent-info/rrn-receiving-agent-info.component';
import { UrlSerializer, DefaultUrlSerializer, UrlTree } from '@angular/router';
import { MaintenanceComponent } from './public/maintenance/maintenance.component';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const path = url.split('?')[0];
    const query = url.split('?')[1] || '';
    return super.parse(path.toLowerCase() + (query !== '' ? `?${query}`: ''));
  }
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    ExternRouteComponent,
    HeaderComponent,
    FooterComponent,
    LeftNavigationComponent,
    DashboardComponent,
    CreateReferralComponent,
    DraftsReferralsComponent,
    PhoneFormatDirective,
    ZipFormatDirective,
    TooltipDirective,
    InfoDialogComponent,
    ActionDialogComponent,
    SnackbarComponent,
    HomeComponent,
    ImageCtaComponent,
    ProgramDescriptionComponent,
    WorkDescriptionComponent,
    BrandVideoComponent,
    FaqComponent,
    ReferralTypeComponent,
    CustomerInfoComponent,
    LocationComponent,
    PropertyTypeComponent,
    AdditionalInfoComponent,
    ReferralFeeComponent,
    ConfirmationComponent,
    AgentSelectionComponent,
    WarningDialogComponent,
    ThankYouComponent,
    ShortNumberPipe,
    FeedbackComponent,
    SpinnerComponent,
    RrnReferralComponent,
    AgentInfoComponent,
    RrnReceivingAgentInfoComponent,
    MaintenanceComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    NoopAnimationsModule,
    BrowserModule,
    MaterialModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    GoogleMapsModule
  ],
  providers: [CookieService, CurrencyPipe, DatePipe, AuthGuardService, SnackbarService, DirtyCheckGuardService, {provide: UrlSerializer,
    useClass: LowerCaseUrlSerializer }, { provide: APP_BASE_HREF, useValue: '/' } ] ,
  entryComponents: [InfoDialogComponent, ActionDialogComponent, SnackbarComponent, WarningDialogComponent, FeedbackComponent],
  exports: [
    PhoneFormatDirective,
    ZipFormatDirective,
    TooltipDirective,
    InfoDialogComponent,
    ActionDialogComponent,
    FeedbackComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
