import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AgentDetails } from '../models/AgentDetails';
import { AgentDetailService } from './agent-detail.service';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  agentDetails: AgentDetails;

  constructor(
    private baseClient: BaseClientService,
    private logSvc: RemoteLoggingService,
    private agentDetailSvc: AgentDetailService
  ) { }

  submitFeedback(requestObj, rrnReferral = false): Observable<any> {
    const feedbackObj = rrnReferral ? requestObj : this.createFeedback(requestObj);
    return this.baseClient.post('/b2b/feedback', feedbackObj, 'save feedback', null, null, rrnReferral)
      .pipe(
        map(r => r),
        catchError((err) => {
          const emptyResponse = {};
          this.logSvc.logError(err);
          return of(emptyResponse);
        })
      );
  }

  createFeedback(requestObj) {
    this.agentDetailSvc.loggedInAgentDetail.subscribe((agent: AgentDetails) => {
      this.agentDetails = agent;
    });
    return {
      leadSource: 'CB Refer',
      rating: requestObj.feedbackRating,
      additionalFeedback: requestObj.additionalComments ? requestObj.additionalComments : undefined,
      feedbackWidget: requestObj.currentScreen,
      agentPersonalDetails: {
        firstName: this.agentDetails.agent.firstName,
        lastName: this.agentDetails.agent.lastName,
        email: this.agentDetails.agent.emailAddress,
        phoneNumber: this.agentDetails.agent.businessPhone,
        agentGuid: this.agentDetails.agent.id,
        companyName: this.agentDetails.agent.companyName,
        companyGuid: this.agentDetails.agent.companyId
      }
    };
  }
}
