<div class="message-popup" *ngIf="dialogData.type !== 'resetReferral'">
  <button class="close-button" [mat-dialog-close]="false">
    <mat-icon class="close-icon clickable" color="warn"
      ><img src="../../../../assets/images/close-icon.png" />
    </mat-icon>
  </button>
  <p class="warning-title">Warning</p>
  <p [innerHTML]="dialogData.message" class="warning-message"></p>
  <div *ngIf="dialogData.type !== 'draftLimit'">
    <button class="btn-default cancel-btn" [mat-dialog-close]="false">
      Cancel
    </button>
    <button class="btn-primary continue-btn" [mat-dialog-close]="true">
      Continue
    </button>
  </div>
  <div
    class="draft-limit-actions-wrap"
    *ngIf="dialogData.type === 'draftLimit'"
  >
    <button class="btn-default cancel-btn" [mat-dialog-close]="true">
      View Drafts
    </button>
    <button class="btn-primary continue-btn" [mat-dialog-close]="false">
      Continue New Referral
    </button>
  </div>
</div>

<div
  class="message-popup rni-message-popup"
  *ngIf="dialogData.type === 'resetReferral'"
>
  <button class="close-button" [mat-dialog-close]="false">
    <mat-icon class="close-icon clickable" color="warn"
      ><img alt="close icon" src="../../../../assets/images/close-icon.png" />
    </mat-icon>
  </button>
  <p [innerHTML]="dialogData.message" class="warning-message"></p>
  <div class="rni-cancel-actions-wrap">
    <button class="btn-default cancel-btn" [mat-dialog-close]="true">
      Yes, Cancel
    </button>
    <button class="btn-primary continue-btn" [mat-dialog-close]="false">
      No, Continue
    </button>
  </div>
</div>
