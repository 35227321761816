import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-brand-video',
  templateUrl: './brand-video.component.html',
  styleUrls: ['./brand-video.component.scss'],
})
export class BrandVideoComponent implements OnInit {
  @Input() brandProperties: any;

  videoSource: any;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.videoSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.brandProperties.videoLink);
  }
}
