import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { WarningDialogComponent } from 'src/app/core/components/warning-dialog/warning-dialog.component';
import { Address } from 'src/app/core/models/Address';
import { AE_WEB_CONST } from 'src/app/core/models/constants';
import { AgentSelectionService } from 'src/app/core/services/agent-selection.service';
import { LocationService } from 'src/app/core/services/location.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() referralObj: any;
  @Input() refType: string;
  @Input() rrnReferral = false;
  @Output() updateReferralObj = new EventEmitter<any>();
  @Output() scrollProgressBar = new EventEmitter<any>();
  @Output() saveUpdateDraft = new EventEmitter<any>();
  @Output() resetReferral = new EventEmitter<any>();

  locationForm: UntypedFormGroup;
  lat: number;
  lng: number;
  zoom: number;
  defaultLatitude = 37.09024;
  defaultLongitude = -95.712891;
  customerName = '';
  addresses: Address[] = [];
  disableNextBtn = true;
  currentReferralObj: any;
  addressSubject = new Subject;
  showSpinner: boolean = false;
  constructor(
    private readonly locationFormBuilder: UntypedFormBuilder,
    private locationService: LocationService,
    private agentSelectionService: AgentSelectionService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.setLocation(this.defaultLatitude, this.defaultLongitude, 4);
    this.initialiseForm();
    this.onSearchPlace();
    switch (this.refType) {
      case 'buy': {
        if (this.referralObj['buyLocation'] && this.locationForm && (!this.addresses || this.addresses.length === 0)) {
          this.locationForm.get('searchPlace').setValue(this.referralObj.buyLocation.location.fullAddress);
          this.locationService.getStreetAddress(this.locationForm.get('searchPlace').value, this.rrnReferral)
            .subscribe(address => {
              this.addresses = address;
              this.agentSelectionService.updateBuyingAgentList(null);
              this.agentSelectionService.updateBuyingAgentBestMatch(null);
              this.validateAddress();
            });
        }
        break;
      }
      case 'sell': {
        if (this.referralObj['sellAddress'] && this.locationForm && (!this.addresses || this.addresses.length === 0)) {
          if (this.referralObj.sellAddress.apiUnit) { this.locationForm.get('apiUnit').setValue(this.referralObj.sellAddress.apiUnit); }
          if (this.referralObj.sellAddress.address) {
            this.locationForm.get('searchPlace').setValue(this.referralObj.sellAddress.address.fullAddress);
            this.locationService.getStreetAddress(this.locationForm.get('searchPlace').value, this.rrnReferral)
              .subscribe(address => {
                this.addresses = address;
                this.agentSelectionService.updateSellingAgentList(null);
                this.agentSelectionService.updateSellingAgentBestMatch(null);
                this.validateAddress();
              });
          }
        }
        break;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.referralObj) {
      this.referralObj = changes.referralObj.currentValue;
      if (this.referralObj.customerInfo) {
        this.customerName = this.referralObj.customerInfo.customerFirstName;
      } if (!this.referralObj['buyLocation'] && this.refType === 'buy') {
        this.initialiseForm();
        this.disableNextBtn = true;
        this.setLocation(this.defaultLatitude, this.defaultLongitude, 4);
      } else if (this.referralObj['buyLocation'] && this.locationForm && (!this.addresses || this.addresses.length === 0)
        && this.refType === 'buy') {
        this.locationForm.get('searchPlace').setValue(this.referralObj.buyLocation.location.fullAddress);
        this.locationService.getStreetAddress(this.locationForm.get('searchPlace').value, this.rrnReferral)
          .subscribe(address => {
            this.addresses = address;
            this.agentSelectionService.updateBuyingAgentList(null);
            this.agentSelectionService.updateBuyingAgentBestMatch(null);
            this.validateAddress();
          });
      }
      if (!this.referralObj['sellAddress'] && this.refType === 'sell') {
        this.initialiseForm();
        this.disableNextBtn = true;
        this.setLocation(this.defaultLatitude, this.defaultLongitude, 4);
      } else if (this.referralObj['sellAddress'] && this.locationForm && (!this.addresses || this.addresses.length === 0)
        && this.refType === 'sell') {
        if (this.referralObj.sellAddress.apiUnit) { this.locationForm.get('apiUnit').setValue(this.referralObj.sellAddress.apiUnit); }
        if (this.referralObj.sellAddress.address) {
          this.locationForm.get('searchPlace').setValue(this.referralObj.sellAddress.address.fullAddress);
          this.locationService.getStreetAddress(this.locationForm.get('searchPlace').value, this.rrnReferral)
            .subscribe(address => {
              this.addresses = address;
              this.agentSelectionService.updateSellingAgentList(null);
              this.agentSelectionService.updateSellingAgentBestMatch(null);
              this.validateAddress();
            });
        }
      }
      this.currentReferralObj = JSON.parse(JSON.stringify(changes.referralObj.currentValue));
    }
  }

  initialiseForm() {
    this.locationForm = this.locationFormBuilder.group({
      searchPlace: [''],
      apiUnit: [''],
    });
  }

  mapOptions: google.maps.MapOptions = {
    center: { lat: 37.09024, lng: -95.712891 },
    mapTypeControl: false,
    fullscreenControl: false
  }
  marker = {
    position: { lat: 37.09024, lng: -95.712891 },
  }

  setLocation(lat: number, lng: number, zoom: number) {
    this.lat = lat;
    this.lng = lng;
    this.zoom = zoom;
    const position = { lat, lng }
    this.marker.position = position
    this.mapOptions = {
      ...this.mapOptions,
      center: position,
      zoom: zoom
    }
  }

  updateChange() {
    this.scrollProgressBar.emit('left');
    this.updateLocationDetails();
    if (this.refType === 'buy') {
      this.referralObj['step'].third = true;
    } if (this.refType === 'sell') {
      if (this.rrnReferral) {
        this.referralObj['step'].sixth = true;
      } else {
        this.referralObj['step'].eighth = true;
      }
    }
    this.updateReferralObj.emit(this.referralObj);
    this.saveReferral();
  }

  updateLocationDetails() {
    switch (this.refType) {
      case 'buy': {
        // this.referralObj['buyLocation'] = {
        //   location: this.locationForm.get('searchPlace').value,
        // };
        break;
      }
      case 'sell': {
        if (this.locationForm.get('apiUnit').value) {
          this.referralObj['sellAddress'] ? this.referralObj['sellAddress']['apiUnit'] = this.locationForm.get('apiUnit').value :
            this.referralObj['sellAddress'] = { apiUnit: this.locationForm.get('apiUnit').value };
        } else {
          this.referralObj['sellAddress'] ? delete this.referralObj['sellAddress']['apiUnit'] :
            null;
        }

        //  this.referralObj['sellAddress'] ? this.referralObj['sellAddress']['apiUnit'] = this.locationForm.get('apiUnit').value : '';
        // address: this.locationForm.get('searchPlace').value,
        // apiUnit: this.locationForm.get('apiUnit').value,
        break;
      }
    }
  }

  onSearchPlaceInput() {
    const searchTerm = this.locationForm.get('searchPlace').value.trim();
    this.addressSubject.next(searchTerm);
  }

  onSearchPlace() {
    const rrnReferral = this.rrnReferral;
    this.addressSubject
      .pipe(
        debounceTime(350),
        distinctUntilChanged(),
        switchMap((searchText) => {
          if (searchText) {
            this.showSpinner = true;
            return this.locationService.getStreetAddress(searchText, rrnReferral);
          } else {
            return of([]);
          }
        })
      )
      .subscribe((address) => {
        this.showSpinner = false
        this.addresses = address;
      });
  }

  onFocusOut() {
    this.showSpinner = false;
  }

  onSearchPlaceSelected() {
    if (this.refType === 'buy' && this.referralObj.flow === 'edit' &&
      (this.referralObj.buyLocation.location.fullAddress !== (this.locationForm.get('searchPlace').value).fullAddress)) {
      this.referralObj.hideBuySelection = false;
      this.agentSelectionService.updateBuyingAgentList(null);
      this.agentSelectionService.updateBuyingAgentBestMatch(null);
    } else if ((this.refType === 'sell') && this.referralObj.flow === 'edit' &&
      (this.referralObj.sellAddress.address.fullAddress !== (this.locationForm.get('searchPlace').value).fullAddress)) {
      this.referralObj.hideSellLocation = false;
      this.agentSelectionService.updateSellingAgentList(null);
      this.agentSelectionService.updateSellingAgentBestMatch(null);
    }

    const address = this.locationForm.get('searchPlace').value;
    if (address) {
      this.disableNextBtn = false;
      this.setLocation(address.latitude, address.longitude, 13);
      this.locationForm.get('searchPlace').patchValue(address.fullAddress);
    }
  }

  validateAddress() {
    if (this.locationForm.get('searchPlace').value !== '') {
      const index = this.addresses
        .map((x) => x.fullAddress)
        .indexOf(this.locationForm.get('searchPlace').value);
      if (index === -1) {
        this.disableNextBtn = true;
        this.setLocation(this.defaultLatitude, this.defaultLongitude, 4);
        this.locationForm.get('searchPlace').setErrors({ invalid: true });
      } else {
        this.disableNextBtn = false;
        this.setLocation(this.addresses[index].latitude, this.addresses[index].longitude, 13);
        if (this.refType === 'buy' && this.referralObj.flow === 'edit' &&
          (this.referralObj.buyLocation.location.fullAddress !== (this.locationForm.get('searchPlace').value))) {
          this.referralObj.hideBuySelection = false;
          this.agentSelectionService.updateBuyingAgentList(null);
          this.agentSelectionService.updateBuyingAgentBestMatch(null);
        } else if ((this.refType === 'sell') && this.referralObj.flow === 'edit' &&
          (this.referralObj.sellAddress.address.fullAddress !== (this.locationForm.get('searchPlace').value))) {
          this.referralObj.hideSellLocation = false;
          this.agentSelectionService.updateSellingAgentList(null);
          this.agentSelectionService.updateSellingAgentBestMatch(null);
        }
        this.refType === 'buy' ? this.referralObj['buyLocation'] = {
          location: {
            fullAddress: this.addresses[index].fullAddress,
            streetAddress: this.addresses[index].streetAddress,
            city: this.addresses[index].city,
            state: this.addresses[index].state,
            zipcode: this.addresses[index].zipcode,
            country: 'USA'
          }
        } :
          this.referralObj['sellAddress'] = {
            address: {
              fullAddress: this.addresses[index].fullAddress,
              streetAddress: this.addresses[index].streetAddress,
              city: this.addresses[index].city,
              state: this.addresses[index].state,
              zipcode: this.addresses[index].zipcode,
              country: 'USA'
            }
          };
      }
    } else {
      this.disableNextBtn = true;
      this.setLocation(this.defaultLatitude, this.defaultLongitude, 4);
      this.locationForm.get('searchPlace').setErrors(null);
    }
  }

  onBackBtnClick() {
    this.scrollProgressBar.emit('right');
    this.updateLocationDetails();
    this.saveReferral();
    if (this.refType === 'buy') {
      this.referralObj['step'].second = false;
      this.referralObj['step'].third = false;
    } if (this.refType === 'sell') {
      if (this.rrnReferral) {
        this.referralObj['step'].fifth = false;
        this.referralObj['step'].sixth = false;
      } else {
        this.referralObj['step'].seventh = false;
        this.referralObj['step'].eighth = false;
      } if (this.referralObj.preference !== 'both') {
        this.referralObj['step'].second = false;
      }
    } if (this.referralObj.preference === 'both' && this.referralObj.hideBuySelection) {
      this.referralObj['step'].sixth = false;
    }
  }

  saveReferral() {
    switch (this.refType) {
      case 'buy': {
        if (JSON.stringify(this.currentReferralObj.buyLocation) !== JSON.stringify(this.referralObj.buyLocation)
          && this.referralObj.step.second) {
          this.referralObj.currentStep = 'Buying Location';
          this.saveUpdateDraft.emit(this.referralObj);
        }
        break;
      }
      case 'sell': {
        if (JSON.stringify(this.currentReferralObj.sellAddress) !== JSON.stringify(this.referralObj.sellAddress) &&
          ((this.referralObj.preference === 'sell' && this.referralObj.step.second) ||
            (this.referralObj.preference === 'both' && this.referralObj.step.seventh))) {
          this.referralObj.currentStep = 'Selling Location';
          this.saveUpdateDraft.emit(this.referralObj);
        }
        break;
      }
    }
  }

  cancelReferral() {
    const cancelDialog = this.dialog.open(WarningDialogComponent, {
      data: {
        type: 'resetReferral',
        message: AE_WEB_CONST.cancelReferral
      },
      autoFocus: false
    });
    cancelDialog.afterClosed().subscribe((action) => {
      if (action) {
        this.resetReferral.emit(true);
      }
    });
  }

  ngOnDestroy() {
    this.updateLocationDetails();
    this.saveReferral();
  }
}
