<div class="stepper">
  <div class="stepper-container">
    <div [style.visibility]="(referralObj?.step?.second) ? 'visible' : 'hidden'" class="stepper-header">
      <ul class="steps">
        <li [ngClass]="
            referralObj?.step?.first
              ? 'step step--complete step--active'
              : 'step step--incomplete step--active'
          ">
          <span class="step__label step__label_single">CUSTOMER INFO</span>
          <span class="step__icon"></span>
        </li>
        <li [ngClass]="
            referralObj?.step?.second || referralObj?.step?.first
              ? 'step step--complete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label step__label_single">TYPE OF REFERRAL</span>
          <span class="step__icon"></span>
        </li>
        <li *ngIf="
            referralObj.preference === 'buy' ||
            referralObj.preference === 'both'
          " [ngClass]="
            referralObj?.step?.third
              ? 'step step--complete step--active'
              : referralObj?.step?.second
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label">BUYING <br />
            LOCATION</span>
          <span class="step__icon"></span>
        </li>
        <li *ngIf="
            referralObj.preference === 'buy' ||
            referralObj.preference === 'both'
          " [ngClass]="
            referralObj?.step?.fourth
              ? 'step step--complete step--active'
              : referralObj?.step?.third
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label">BUYING <br />
            PROPERTY TYPES</span>
          <span class="step__icon"></span>
        </li>
        <li *ngIf="
            referralObj.preference === 'buy' ||
            referralObj.preference === 'both'
          " [ngClass]="
            referralObj?.step?.seventh
              ? 'step step--complete step--active'
              : referralObj?.step?.fourth
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label">BUYING <br />
            ADDITIONAL DETAILS</span>
          <span class="step__icon"></span>
        </li>
        <!-- <li *ngIf="
            referralObj.preference === 'buy' ||
            referralObj.preference === 'both'
          " [ngClass]="
            referralObj?.step?.sixth
              ? 'step step--complete step--active'
              : referralObj?.step?.fifth
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label">BUYING <br />
            REFERRAL FEE</span>
          <span class="step__icon"></span>
        </li> -->
        <!-- <li *ngIf="
            (referralObj.preference === 'buy' ||
            referralObj.preference === 'both') &&
            !referralObj.hideBuySelection
          " [ngClass]="
            referralObj?.step?.seventh
              ? 'step step--complete step--active'
              : referralObj?.step?.sixth
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label">BUYING <br />
            AGENT SELECTION</span>
          <span class="step__icon"></span>
        </li> -->
        <li *ngIf="
            referralObj.preference === 'sell' ||
            referralObj.preference === 'both'
          " [ngClass]="
            referralObj?.step?.eighth
              ? 'step step--complete step--active'
              : (referralObj?.step?.seventh && referralObj.preference === 'both') || 
                (referralObj?.step?.second && referralObj.preference === 'sell') ||
                (referralObj?.step?.sixth && referralObj.hideBuySelection)
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label">SELLING <br />
            LOCATION</span>
          <span class="step__icon"></span>
        </li>
        <li *ngIf="
            referralObj.preference === 'sell' ||
            referralObj.preference === 'both'
          " [ngClass]="
            referralObj?.step?.ninth
              ? 'step step--complete step--active'
              : referralObj?.step?.eighth
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label">SELLING <br />
            PROPERTY TYPES</span>
          <span class="step__icon"></span>
        </li>
        <li *ngIf="
            referralObj.preference === 'sell' ||
            referralObj.preference === 'both'
          " [ngClass]="
            referralObj?.step?.twelveth
              ? 'step step--complete step--active'
              : referralObj?.step?.ninth
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label">SELLING <br />
            ADDITIONAL DETAILS</span>
          <span class="step__icon"></span>
        </li>
       <!-- <li *ngIf="
            referralObj.preference === 'sell' ||
            referralObj.preference === 'both'
          " [ngClass]="
            referralObj?.step?.eleventh
              ? 'step step--complete step--active'
              : referralObj?.step?.tenth
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label">SELLING <br />
            REFERRAL FEE</span>
          <span class="step__icon"></span>
        </li> -->
        <!-- <li *ngIf="
            (referralObj.preference === 'sell' ||
            referralObj.preference === 'both') &&
            !referralObj.hideSellLocation
          " [ngClass]="
            referralObj?.step?.twelveth
              ? 'step step--complete step--active'
              : referralObj?.step?.eleventh
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label">SELLING <br />
            AGENT SELECTION</span>
          <span class="step__icon"></span>
        </li> -->
        <li [ngClass]="
            referralObj?.step?.thirteenth
              ? 'step step--complete step--active'
              : (referralObj?.step?.twelveth && (referralObj.preference === 'sell' || referralObj.preference === 'both')) ||
                (referralObj?.step?.seventh && referralObj.preference === 'buy') ||
                (referralObj?.step?.eleventh && referralObj.hideSellLocation && (referralObj.preference === 'sell' || referralObj.preference === 'both')) ||
                (referralObj?.step?.sixth && referralObj.hideBuySelection && referralObj.preference === 'buy')
              ? 'step step--incomplete step--active'
              : 'step step--incomplete step--inactive'
          ">
          <span class="step__label step__label_single">CONFIRMATION </span>
          <span class="step__icon"></span>
        </li>
      </ul>
    </div>

    <mat-horizontal-stepper #stepper (selectionChange)="stepChanged($event)">
      <mat-step>
        <app-customer-info [referralObj]="referralObj" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" (saveUpdateDraft)="saveUpdateDraft($event)">
        </app-customer-info>

      </mat-step>
      <mat-step>
        <app-referral-type [referralObj]="referralObj" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" (saveUpdateDraft)="saveUpdateDraft($event)"
          (refTypeChanged)="refTypeChanged()">
        </app-referral-type>
      </mat-step>
      <mat-step *ngIf="
          referralObj.preference === 'buy' || referralObj.preference === 'both'
        ">
        <app-location [referralObj]="referralObj" refType="buy" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" (saveUpdateDraft)="saveUpdateDraft($event)">
        </app-location>
      </mat-step>
      <mat-step *ngIf="
          referralObj.preference === 'buy' || referralObj.preference === 'both'
        ">
        <app-property-type [referralObj]="referralObj" refType="buy" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" (saveUpdateDraft)="saveUpdateDraft($event)">
        </app-property-type>
      </mat-step>
      <mat-step *ngIf="
          referralObj.preference === 'buy' || referralObj.preference === 'both'
        ">
        <app-additional-info [referralObj]="referralObj" refType="buy" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" (saveUpdateDraft)="saveUpdateDraft($event)" (displayTransitionMessage)="displayTransitionMessage()">
        </app-additional-info>
      </mat-step>
      <!-- <mat-step *ngIf="
          referralObj.preference === 'buy' || referralObj.preference === 'both'
        ">
        <app-referral-fee [referralObj]="referralObj" refType="buy" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" (saveUpdateDraft)="saveUpdateDraft($event)"
          (displayTransitionMessage)="displayTransitionMessage()">
        </app-referral-fee>
      </mat-step> -->
      <!-- <mat-step *ngIf="
          (referralObj.preference === 'buy' || referralObj.preference === 'both')  &&
          !referralObj.hideBuySelection
        ">
        <app-agent-selection [referralObj]="referralObj" refType="buy" (updateReferralObj)="updateReferralObj($event)"
          (changeStep)="changeStep($event)" (scrollProgressBar)="scrollProgressBar($event)"
          (displayTransitionMessage)="displayTransitionMessage()">
        </app-agent-selection>
      </mat-step> -->
      <mat-step *ngIf="
          referralObj.preference === 'sell' || referralObj.preference === 'both'
        ">
        <app-location [referralObj]="referralObj" refType="sell" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" (saveUpdateDraft)="saveUpdateDraft($event)">
        </app-location>
      </mat-step>
      <mat-step *ngIf="
          referralObj.preference === 'sell' || referralObj.preference === 'both'
        ">
        <app-property-type [referralObj]="referralObj" refType="sell" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" (saveUpdateDraft)="saveUpdateDraft($event)">
        </app-property-type>
      </mat-step>
      <mat-step *ngIf="
          referralObj.preference === 'sell' || referralObj.preference === 'both'
        ">
        <app-additional-info [referralObj]="referralObj" refType="sell" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" (saveUpdateDraft)="saveUpdateDraft($event)">
        </app-additional-info>
      </mat-step>
      <!-- <mat-step *ngIf="
          referralObj.preference === 'sell' || referralObj.preference === 'both'
        ">
        <app-referral-fee [referralObj]="referralObj" refType="sell" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" (saveUpdateDraft)="saveUpdateDraft($event)">
        </app-referral-fee>
      </mat-step> -->
      <!-- <mat-step *ngIf="
      (referralObj.preference === 'sell' ||
      referralObj.preference === 'both') &&
      !referralObj.hideSellLocation
    ">
        <app-agent-selection [referralObj]="referralObj" refType="sell" (updateReferralObj)="updateReferralObj($event)"
          (changeStep)="changeStep($event)" (scrollProgressBar)="scrollProgressBar($event)">
        </app-agent-selection>
      </mat-step> -->
      <mat-step *ngIf="referralObj.preference">
        <app-confirmation [referralObj]="referralObj" (updateReferralObj)="updateReferralObj($event)"
          (scrollProgressBar)="scrollProgressBar($event)" (navigateToChild)="navigateToChild($event)">
        </app-confirmation>
      </mat-step>
    </mat-horizontal-stepper>
    <div>
      <button class="feedback-btn" (click)="openFeedbackPopup()"><img src="../../../../assets/icons/icon_feedback.png"><p>Feedback</p></button>
    </div>

  </div>

</div>