<section class="work-description">
  <div class="main-container">
    <div class="about-text">
      <h2 class="title-heading how-it-works-text">{{this.brandProperties.howItWorksTitle}}</h2>
    </div>
    <div class="work-description-border"></div>
    <p class="steps-text">
      {{this.brandProperties.howItWorksHeading}}
    </p>
    <div class="container">
      <div class="steps-card">
        <div class="mat-card-details">
          <mat-card class="example-card mat-card-div">
            <!-- <div><mat-icon aria-hidden="false" aria-label=" icon" class="icon">description</mat-icon></div> -->
            <div><img src="../../../../../assets/icons/bulb.png"></div>
            <mat-card-content>
              <p>
                {{this.brandProperties.howItWorksFirstCardName}}
              </p>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="mat-card-details">
          <mat-card class="example-card mat-card-div">
            <!-- <div><mat-icon aria-hidden="false" aria-label=" icon" class="icon">description</mat-icon></div> -->
            <div><img src="../../../../../assets/icons/regiformicon.png"></div>
            <mat-card-content>
              <p>
                {{this.brandProperties.howItWorksSecondCardName}}
              </p>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="mat-card-details">
          <mat-card class="example-card mat-card-div">
            <!-- <div><mat-icon aria-hidden="false" aria-label=" icon" class="icon">description</mat-icon></div> -->
            <div><img src="../../../../../assets/icons/earnincome.png"></div>
            <mat-card-content>
              <p>
                {{this.brandProperties.howItWorksThirdCardName}}
              </p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</section>
