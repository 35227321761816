<app-spinner></app-spinner>
<div *ngIf="dialogData.type !== 'profile'" class="message-popup">
  <button class="close-button" mat-icon-button [mat-dialog-close]="false">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>

  <div class="message-icon" *ngIf="dialogData.action?.ok">
    <div class="error-message-icon" *ngIf="dialogData.type === 'error'">
      <img src="assets/imgs/error-icon.png" />
    </div>
  </div>

  <div [ngClass]="
      dialogData.action?.leave && dialogData.action?.stay
        ? 'dialog-box-text dialog-text leave-stay-msg-text'
        : 'dialog-box-text dialog-text'
    ">
    <p [innerHTML]="dialogData.message" class="dialog-span-text"></p>
    <div class="dialog-box-actions row" *ngIf="dialogData.action?.cancel && dialogData.action?.delete">
      <button class="btn-default" [mat-dialog-close]="false">Cancel</button>
      <button class="btn-danger" [mat-dialog-close]="true">Delete</button>
    </div>
    <div class="dialog-box-actions stay-leave-btn-box row" *ngIf="dialogData.action?.leave && dialogData.action?.stay">
      <button *ngIf="dialogData.action?.leave" class="btn-default stay-leave-btn" [mat-dialog-close]="true">
        Leave this page
      </button>
      <button *ngIf="dialogData.action?.stay" class="btn-primary stay-leave-btn" [mat-dialog-close]="false">
        Stay on this page
      </button>
    </div>
    <div class="dialog-box-actions row ok-btn" *ngIf="dialogData.action?.ok">
      <button class="btn-primary ok" [mat-dialog-close]="true">OK</button>
    </div>
  </div>
</div>

<!-- Agent Profile -->
<mat-dialog-content *ngIf="dialogData.type === 'profile'" class="mat-profile">
  <div class="profile-popup">
    <button class="close-button" mat-icon-button [mat-dialog-close]="false">
      <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
    <div class="dailog-content">
      <div class="row">
        <div class="agent-info">
          <div class="sec-img">

            <p [style.visibility]="dialogData.refType?.length ? 'visible' : 'hidden'" >
              <strong>{{ dialogData.refType }} </strong>
            </p>
            <div *ngIf="!dialogData.message.hideAgentImage">
              <img alt="Profile image" class="profile-image" [src]="dialogData.message.profilePhoto"
                (error)="setInitials()" />
            </div>
            <div *ngIf="dialogData.message.hideAgentImage" class="agent-initials">
              {{ dialogData.message.agentInitials }}
            </div>
          </div>
          <div class="sec-info">
            <h3>{{ dialogData.message.fName }} {{ dialogData.message.lName }}</h3>
            <p>{{ dialogData.message.companyName }}</p>
            <p *ngIf="phoneNo" class="phone-info">
              <span><a [href]="'tel:' + phoneNo">{{ phoneNo }}</a></span>
            </p>
            <p class="mail-info">
              <span><a [href]="'mailTo:' + dialogData.message.mailId">{{
                  dialogData.message.mailId
                  }}</a></span>
            </p>
            <p class="website-info" *ngIf="dialogData.message.agentWebsiteAddress"
              (click)="openWebsite(dialogData.message.agentWebsiteAddress)">
              <span class="clickable">
                My Website
              </span>
            </p>
        </div>
        </div>
        <div class="agent-profile">
          <ul>
            <li>
              <img alt="Profile image" class="brand-image" src="../../../../assets/imgs/{{
                  dialogData.message.brandCode
                }}.png" />
            </li>
            <li>
              <span>{{
                dialogData.message.experience !== null || undefined
                ? dialogData.message.experience
                : '-'
                }}</span>Years of Experience
            </li>
            <li>
              <span>{{
                dialogData.message.numberOfClosedTransaction !== null ||
                undefined
                ? dialogData.message.numberOfClosedTransaction
                : '-'
                }}</span>
              Transactions in Last 12 Months
            </li>
            <li>
              <span>
                {{
                dialogData.message.last12MonthsAvgSalesPrice !== null ||
                undefined
                ? dialogData.message.last12MonthsAvgSalesPrice.toString()
                .length > 6
                ? (dialogData.message.last12MonthsAvgSalesPrice
                | currency: 'USD':'symbol'
                | shortNumber: 2)
                : (dialogData.message.last12MonthsAvgSalesPrice
                | currency: 'USD':'symbol'
                | shortNumber)
                : '-'
                }}
              </span>
              Average Sale Price
            </li>
          </ul>
        </div>
      </div>
      <div class="content content-sec">
        <h3>About</h3>
        <div>
          <pre> <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(dialogData.message.about)"></div></pre>
        </div>
      </div>
      <div class="content content-sec">
        <h3>Brokerage</h3>
        <p>{{ dialogData.message.companyName }}</p>
        <p>{{ companyAddress }}</p>
      </div>
      <div class="row content-sec">
        <div class="content">
          <h3>Property Specializations</h3>
          <ul>
            <li *ngFor="
                let property of (propertySpecializations || [])
              ">
              {{ property }}
            </li>
          </ul>
        </div>
        <div class="content">
          <h3>Awards</h3>
          <ul>
            <li *ngFor="let award of (dialogData.message.awards || [])">
              {{ award.yearAchieved }} {{ award.awardType }}
            </li>
          </ul>
        </div>
        <div class="content">
          <h3>Languages</h3>
          <ul>
            <li *ngFor="let language of (dialogData.message.languagesSpoken || [])">
              {{ language }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="dialog-box-actions row">
      <button *ngIf="dialogData.action?.close" [ngClass]="
          dialogData.refType?.length ? 'btn-default' : 'btn-default btn-close'
        " [mat-dialog-close]="false">
        Close
      </button>
      <button *ngIf="dialogData.action?.selectAgent" class="btn-primary" [mat-dialog-close]="dialogData.message">
        Select Agent
      </button>
    </div>
  </div>
</mat-dialog-content>
