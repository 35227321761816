<section class="agent-form-section">
  <div>
    <div class="form-wrapper">
      <form #agentForm="ngForm" [formGroup]="agentInfoForm">
        <!-- Agent Information -->
        <div class="form-container">
          <div class="referral-questions">
            <p>Please enter your information…</p>
          </div>
          <div class="input-field-section">
            <div class="row">
              <div class="input-field-form">
                <mat-form-field appearance="outline">
                  <mat-label><span>*</span> First Name</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="agentFirstName"
                    maxLength="30"
                  />
                  <mat-error
                    *ngIf="
                      agentInfoForm.get('agentFirstName').hasError('required')
                    "
                  >
                    Please enter First Name.
                  </mat-error>
                  <mat-error
                    *ngIf="
                      agentInfoForm.get('agentFirstName').hasError('pattern')
                    "
                  >
                    Please use only letters, a space, and -' characters.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="input-field-form">
                <mat-form-field appearance="outline">
                  <mat-label><span>*</span> Last Name</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="agentLastName"
                    maxLength="30"
                  />
                  <mat-error
                    *ngIf="
                      agentInfoForm.get('agentLastName').hasError('required')
                    "
                  >
                    Please enter Last Name.
                  </mat-error>
                  <mat-error
                    *ngIf="
                      agentInfoForm.get('agentLastName').hasError('pattern')
                    "
                  >
                    Please use only letters, a space, and -' characters.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="input-field-form">
                <mat-form-field appearance="outline">
                  <mat-label><span>*</span> Email</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="agentEmail"
                    maxLength="50"
                  />
                  <mat-error>Please enter a valid email address.</mat-error>
                </mat-form-field>
              </div>
              <div class="input-field-form">
                <mat-form-field appearance="outline">
                  <mat-label><span>*</span> Phone Number</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="agentPhone"
                    phoneFormat
                    maxlength="14"
                  />
                  <mat-error
                    *ngIf="agentInfoForm.get('agentPhone').hasError('required')"
                  >
                    Please enter Phone Number.</mat-error
                  >
                  <mat-error
                    *ngIf="
                      agentInfoForm.get('agentPhone').hasError('minLength')
                    "
                  >
                    Please enter a valid Phone Number.</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="state-input-field-form">
                <mat-form-field
                  appearance="outline"
                  class="license-state-input"
                >
                  <mat-label><span>*</span> License State</mat-label>
                  <mat-select formControlName="licenseState">
                    <mat-option *ngFor="let state of stateList" [value]="state">
                      {{ state.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error>Please select a license state.</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="next-back-btn-section">
            <button
              class="back-btn"
              style="visibility: hidden"
              matStepperPrevious
            >
              <mat-icon>arrow_back</mat-icon><span>Back</span>
            </button>
            <button class="back-btn cancel-btn" style="visibility: hidden">
              <span>Cancel</span>
            </button>
            <button
              class="next-btn"
              (click)="updateChange()"
              matStepperNext
              [disabled]="agentInfoForm.invalid"
            >
              <span>Next</span>
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
