<section class="agent-form-section">
  <div>
    <div class="form-wrapper">
      <div class="form-container">
        <div class="referral-questions">
          <p>
            {{ headerText }}
          </p>
          <p *ngIf="refType === 'buy'" class="sub-title">
            Select all that apply.
          </p>
        </div>
        <div class="property-type-btn">
          <button class="property-btn" (click)="updatePropertyType(1)"
            [ngClass]="{ 'property-selected': optionSelected(1) }">
            Single Family
          </button>
          <button class="property-btn" (click)="updatePropertyType(4)"
            [ngClass]="{ 'property-selected': optionSelected(4) }">
            Townhouse
          </button>
          <button *ngIf="refType === 'buy'" class="property-btn" (click)="updatePropertyType(12)"
            [ngClass]="{ 'property-selected': optionSelected(12) }">
            New Construction
          </button>
          <button *ngIf="refType === 'sell'" class="property-btn" (click)="updatePropertyType(11)"
            [ngClass]="{ 'property-selected': optionSelected(11) }">
            Lot / Land
          </button>
          <button class="property-btn" (click)="updatePropertyType(5)"
            [ngClass]="{ 'property-selected': optionSelected(5) }">
            Condo / Co-op
          </button>
          <button class="property-btn" (click)="updatePropertyType(9)"
            [ngClass]="{ 'property-selected': optionSelected(9) }">
            Commercial
          </button>
          <button class="property-btn" (click)="updatePropertyType(6)"
            [ngClass]="{ 'property-selected': optionSelected(6) }">
            Other
          </button>
        </div>

        <div class="next-back-btn-section">
          <button class="back-btn" matStepperPrevious (click)="onBackBtnClick()">
            <mat-icon>arrow_back</mat-icon><span>Back</span>
          </button>
          <button class="back-btn cancel-btn" (click)="cancelReferral()">
            <span>Cancel</span>
          </button>
          <!-- <button mat-fab color="primary" class="save-btn" aria-label="Save icon">
        <img src="../../../../../assets/imgs/save_icon.png" />
    </button> -->
          <button class="next-btn" matStepperNext (click)="updateChange()" [disabled]="disableNextButton()">
            <span>Next</span>
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>