<div class="snack-bar-div">
  <div class="snackbar-text">
    <div *ngIf="data.type === 'success'" class="success-message-icon">
      <img src="../../../../assets/imgs/check-outline-icon.png" />
    </div>
    {{ data.message }}
  </div>
  <div class="close-icon">
    <button class="close-btn" (click)="dismiss()" aria-label="Close Snackbar">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>