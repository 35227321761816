import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { AE_WEB_CONST } from 'src/app/core/models/constants';

@Component({
  selector: 'app-agent-info',
  templateUrl: './agent-info.component.html',
  styleUrls: ['./agent-info.component.scss']
})
export class AgentInfoComponent implements OnInit, OnChanges {

  @Input() referralObj: any;
  @Output() updateReferralObj = new EventEmitter<any>();
  @Output() scrollProgressBar = new EventEmitter<any>();
  agentInfoForm: UntypedFormGroup;
  stateList = AE_WEB_CONST.stateList;
  @ViewChild('agentForm', { static: false }) agentForm: FormGroupDirective;

  constructor(
    private referralFormBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.initialiseForm();
    if (this.agentInfoForm && this.referralObj.agentInformation) {
      this.agentInfoForm.patchValue({
        agentFirstName: this.referralObj.agentInformation.firstName,
        agentLastName: this.referralObj.agentInformation.lastName,
        agentEmail: this.referralObj.agentInformation.email,
        agentPhone: this.referralObj.agentInformation.phoneNo,
        licenseState: AE_WEB_CONST.stateList.find(state => state.shortName === this.referralObj.agentInformation.licenseState),
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.referralObj) {
      this.referralObj = changes.referralObj.currentValue;
      if (this.agentInfoForm && !this.referralObj.agentInformation) {
        this.initialiseForm();
        this.agentForm.resetForm();
      }
    }
  }

  updateChange() {
    this.referralObj.step.zero = true;
    this.referralObj.agentInformation = {
      firstName: this.agentInfoForm.get('agentFirstName').value.trim(),
      lastName: this.agentInfoForm.get('agentLastName').value.trim(),
      email: this.agentInfoForm.get('agentEmail').value.trim(),
      phoneNo: this.agentInfoForm.get('agentPhone').value
        .replace(/\D/g, '').substring(0, 10).replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3'),
      licenseState: this.agentInfoForm.get('licenseState').value.shortName
    };
    this.updateReferralObj.emit(this.referralObj);
  }

  initialiseForm() {
    this.agentInfoForm = this.referralFormBuilder.group({
      agentFirstName: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(/^[A-Za-z'-]+[\ ]?([a-zA-Z'-]*)$/)]],
      agentLastName: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(/^[A-Za-z'-]+[\ ]?([a-zA-Z'-]*)$/)]],
      agentEmail: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z0-9]{0}[a-zA-Z\-0-9]+[^-]\.)+[a-zA-Z]{2,}))+[\ ]?$/
      )]],
      agentPhone: ['', [Validators.required]],
      licenseState: ['', [Validators.required]]
    });
  }

}
