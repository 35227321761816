import { Component, Input, OnInit } from '@angular/core';
import { AgentDetails } from 'src/app/core/models/AgentDetails';

@Component({
  selector: 'app-left-navigation',
  templateUrl: './left-navigation.component.html',
  styleUrls: ['./left-navigation.component.scss'],
})
export class LeftNavigationComponent implements OnInit {
  @Input() agentDetail: AgentDetails;
  fullName: string;
  needAssistance = `Need assistance?
  Email us directly`;
  position = 'right';
  // @ViewChild('tooltip', { static: true }) tooltip: ElementRef;
  constructor() { }

  ngOnInit(): void {
    this.fullName = `${this.agentDetail.agent.firstName} ${this.agentDetail.agent.lastName}`;
  }

  // showTooltip() {
  //   if (screen.width > 568 && screen.width < 1100) {
  //     this.tooltip.nativeElement.visibility = 'visible';
  //   }
  // }
}
