import { Component, OnInit, Renderer2 } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from './core/services/login.service';
import { AppConfigService } from './core/services/app-config.service';
import { AuthenticationService } from './core/services/authentication.service';
import { AgentDetailService } from './core/services/agent-detail.service';
import { AgentDetails } from './core/models/AgentDetails';
import { ActionDialogComponent } from './core/components/action-dialog/action-dialog.component';
import { AE_WEB_CONST } from './core/models/constants';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from './core/services/spinner.service';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
/** Base application component - builds the site structure */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  /** getting brand of the application */
  brandClass = AppConfigService.brandingData.brandClass;
  baseUrl = AppConfigService.brandingData.baseUrl;
  agentDetail: AgentDetails | any;
  agentHasDashRoles: boolean;
  phoneNo = '1-877-426-5393';
  email = 'help@coldwellbanker.com';
  agentDetailsData: any;
  loggedOut: boolean;
  // warningTime = (new Date(60).getTime()) * 1000;
  sessionTime = (new Date(3300).getTime()) * 1000; // 3300 sec
  logoutTime = (new Date(3600).getTime()) * 1000; // 3600 sec
  showHome: boolean;
  rrnReferral = false;
  RepoAppMaintenanceVar = environment.APP_MAINTENANCE_MODE;
  AppMaintenance = false;
  CurrentRouteLink = '';
  rrnCurRouteLink = false;
  /** Component instantiation */
  constructor(
    private readonly cookieService: CookieService,
    public readonly router: ActivatedRoute,
    public loginSvc: LoginService,
    private routing: Router,
    private authsvc: AuthenticationService,
    private readonly appConfig: AppConfigService,
    private agentDetailSvc: AgentDetailService,
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    private titleSvc: Title,
    private renderer: Renderer2
  ) {
    this.spinnerService.show();
    document.querySelector('body').classList.add(this.brandClass);
  }

  /** Component Angular initialization life cycle hook */
  ngOnInit() {
    this.googleApiKey();
    const sessionCookie = this.cookieService.get('car-ses-tok');
    setTimeout(() => {
      if (this.routing.url.includes('/rni/')) {
        const redirectURL = environment.RRN_LANDING_PAGE_LINK;
        this.titleSvc.setTitle('Create Referral - CBRRN');
        this.routing.navigate(['/externalRedirect', { externalUrl: redirectURL }], {
          skipLocationChange: true,
        });
      } else {
        if (this.RepoAppMaintenanceVar) {
          this.spinnerService.hide();
          this.agentHasDashRoles = false;
          this.AppMaintenance = true;
          this.CurrentRouteLink = this.routing.url;
          this.rrnCurRouteLink = this.routing.url === '/rni' ? true : false;
          this.routing.navigate(['maintenance'], {
            state: { routeLink: this.CurrentRouteLink }
          });
        } else {
          if ((<string>this.routing.url).toLowerCase() === '/rni') {
            this.spinnerService.hide();
            this.rrnReferral = true;
            this.routing.navigate(['/rni']);
          } else if (this.routing.url === '/' && this.brandClass === AE_WEB_CONST.CB_Brand) {
            this.spinnerService.hide();
            // this.showHome = true;
            this.redirectToLanding();
          } else if (this.router.snapshot.queryParams['code'] && !sessionCookie) {
            this.spinnerService.show();
            const authCode = this.router.snapshot.queryParams['code'];
            let baseTokenUrl = <string>this.appConfig.getConfig('tokenUrl');
            baseTokenUrl = baseTokenUrl.replace('authCode', authCode);
            this.getAccessToken(baseTokenUrl);
          } else if (this.routing.url === '/logout') {
            this.spinnerService.hide();
            this.loggedOut = true;
            this.routing.navigate(['logout']);
          } else if (!sessionCookie) {
            this.spinnerService.show();
            this.redirectToOkta();
          } else {
            this.spinnerService.show();
            this.cookieService.deleteAll();
            this.redirectToOkta();
            // sessionCookie ? this.getAgentDetails() : this.redirectToOkta();
          }
        }
      }
    }, 1000);
  }

  googleApiKey() {
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'src', `https://maps.googleapis.com/maps/api/js?key=${environment.GOOGLE_API_KEY}&callback=initMap`);
    this.renderer.appendChild(document.head, script);
  }
  removeSession() {
    let redirectURL = <string>this.appConfig.getConfig('logoutUrl');
    redirectURL = redirectURL.replace('idToken', this.cookieService.get('car-ses-id'));
    this.routing.navigate(['/externalRedirect', { externalUrl: redirectURL }], {
      skipLocationChange: true
    });
  }

  redirectToOkta() {
    const redirectURL = this.appConfig.getConfig('redirectUrl');
    this.routing.navigate(['/externalRedirect', { externalUrl: redirectURL }], {
      skipLocationChange: true
    });
  }

  redirectToLanding() {
    const redirectURL = this.appConfig.getConfig('landingUrl');
    this.routing.navigate(['/externalRedirect', { externalUrl: redirectURL }], {
      skipLocationChange: true
    });
  }

  getAccessToken(baseTokenUrl) {
    this.routing.navigate(['createreferral'], { replaceUrl: true });
    this.authsvc.getAccessToken(baseTokenUrl).subscribe(resp => {
      if (resp) {
        this.cookieService.set('car-ses-tok', resp.access_token, null, null, null, null, null);
        this.cookieService.set('car-ses-id', resp.id_token, null, null, null, null, null);
        // this.warnuser(+this.warningTime);
        this.autoExpireSession(+this.sessionTime);
        this.autoLogout(+this.logoutTime);
        this.getAgentDetails();
      } else {
        this.spinnerService.hide();
      }
    });
  }

  getAgentDetails() {
    this.routing.navigate(['createreferral'], { replaceUrl: true });
    this.agentDetailSvc.getLoggedInAgent().subscribe((agent) => {
      if (agent && agent.status === 200) {
        this.agentDetail = agent.body;
        this.agentHasDashRoles = true;
        sessionStorage.setItem('car-ses-con', this.agentDetail.agent.id);
        this.agentDetailSvc.updateLoggedInAgentDetails(this.agentDetail);
        this.spinnerService.hide();
      } else {
        this.agentDetail = 'unauthorizedAgent';
        this.agentDetailSvc.updateLoggedInAgentDetails(this.agentDetail);
        this.spinnerService.hide();
      }
    });
  }

  autoExpireSession(expireTime: number) {
    setTimeout(() => {
      const dialogRef = this.dialog.open(ActionDialogComponent, {
        disableClose: true,
        data: {
          type: 'error',
          message: AE_WEB_CONST.sessionWarningMessage.confirmationMessage,
          action: { ok: true }
        },
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe((action) => {
        if (action) {
          if (this.routing.url !== '/logout') {
            this.redirectToOkta();
          }
        }
      });
    }, expireTime);
  }

  // warnuser(expireTime: number) {
  //   setTimeout(() => {
  //     this.loggedOut = true;
  //     this.dialog.open(InfoDialogComponent, {
  //       data: {
  //         type: 'success',
  //         message: 'Please save the draft before session expires',
  //       },
  //     });
  //   }, expireTime);
  // }

  autoLogout(expireTime: number) {
    setTimeout(() => {
      if (this.routing.url !== 'logout') {
        localStorage.setItem('logoutType', 'auto');
        this.cookieService.delete('car-ses-tok');
        this.loggedOut = true;
        this.routing.navigate(['logout']);
      }
    }, expireTime);
  }

  getClass() {
    if (this.agentHasDashRoles) {
      return 'referral-container-dashboard';
    } else {
      return 'referral-container-dashboard agent-no-roles';
    }
  }

  disableReferralApp(event) {
    this.loggedOut = event;
  }
}
