<!-- <div class="feedback-section feedback" id="feedback">
    <h2>CBRefer</h2>
<div class="feedback-send-text">Send an Agent to Agent Referral</div><br>
<div class="feedback--page-links"><a>Dashboard</a><a>Help Center</a></div>
</div> -->
<div id="feedback-section">
    <button class="close-button" mat-icon-button [mat-dialog-close]="false">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
    <form>
        <div class="feedback-question">
            <h2>Your feedback is important to us! <br/> How would you rate your experience with CB Refer?</h2>
            <div class="feedback-emoji">
                <div class="feedback-emoji-img" (click)="textAreaShown(1)"
                    [ngClass]="selectedFeedback === 1 ? 'arrow-top' : ' arrow-inactive' ">
                    <div class="very-diasatisfied emoji-img"></div>
                    <p class="emoji-text" [ngClass]="selectedFeedback === 1 ? 'emoji-text-active':'emoji-text-inactive'"> Very Dissatisfied</p>
                </div>
                <div class="feedback-emoji-img " (click)="textAreaShown(2)"
                    [ngClass]="selectedFeedback === 2 ? 'arrow-top' : ' arrow-inactive'">
                    <div class="diasatisfied emoji-img"></div>
                    <p class="emoji-text" [ngClass]="selectedFeedback === 2 ? 'emoji-text-active':'emoji-text-inactive'"> Dissatisfied</p>
                </div>
                <div class="feedback-emoji-img" (click)="textAreaShown(3)"
                    [ngClass]="selectedFeedback === 3 ? 'arrow-top' : ' arrow-inactive'">
                <div class="neutral emoji-img"></div>
                    <p class="emoji-text" [ngClass]="selectedFeedback === 3 ? 'emoji-text-active':'emoji-text-inactive'">Neutral</p>
                </div>
                <div class="feedback-emoji-img" (click)="textAreaShown(4)"
                    [ngClass]="selectedFeedback === 4 ? 'arrow-top' : ' arrow-inactive'">
                    <div class="satisfied emoji-img"></div>
                    <p class="emoji-text" [ngClass]="selectedFeedback === 4 ? 'emoji-text-active':'emoji-text-inactive'">Satisfied</p>
                </div>
                <div class="feedback-emoji-img" (click)="textAreaShown(5)"
                    [ngClass]="selectedFeedback === 5 ? 'arrow-top' : ' arrow-inactive'">
                    <div class="very-satisfied emoji-img"></div>
                    <p class="emoji-text" [ngClass]="selectedFeedback === 5 ? 'emoji-text-active':'emoji-text-inactive'">Very Satisfied</p>
                </div>
            </div>
            <div *ngIf="isShown">
                <div class="textarea-div">
                    <textarea rows="6" placeholder="Tell us about your experience..."
                        [formControl]="additionalComments"></textarea>
                </div>
                <div class="feedback-btn-section">
                    <button type="button" class="btn-default" [mat-dialog-close]="false">Cancel</button>
                    <button type="button" class="btn-primary" (click)="sendFeedback()">Send</button>
                </div>
            </div>
        </div>
    </form>
</div>