import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WarningDialogComponent } from 'src/app/core/components/warning-dialog/warning-dialog.component';
import { AE_WEB_CONST } from 'src/app/core/models/constants';
import { AgentSelectionService } from 'src/app/core/services/agent-selection.service';

@Component({
  selector: 'app-property-type',
  templateUrl: './property-type.component.html',
  styleUrls: ['./property-type.component.scss'],
})
export class PropertyTypeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() referralObj: any;
  @Input() refType: string;
  @Input() rrnReferral = false;
  @Output() updateReferralObj = new EventEmitter<any>();
  @Output() scrollProgressBar = new EventEmitter<any>();
  @Output() saveUpdateDraft = new EventEmitter<any>();
  @Output() resetReferral = new EventEmitter<any>();

  headerText: string;
  currentReferralObj: any;
  constructor(
    private agentSelectionService: AgentSelectionService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    if (!this.referralObj['buyingPropertyTypes'] && this.refType === 'buy') {
      this.referralObj['buyingPropertyTypes'] = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.referralObj) {
      this.referralObj = changes.referralObj.currentValue;
      switch (this.refType) {
        case 'buy': {
          if (!this.referralObj['buyingPropertyTypes']) {
            this.referralObj['buyingPropertyTypes'] = [];
          }
          if (this.referralObj['customerInfo']) {
            this.headerText = `What type of property does ${this.referralObj['customerInfo']['customerFirstName']} want to buy?`;
          }
          break;
        }
        case 'sell': {
          if (this.referralObj['customerInfo']) {
            this.headerText = `What type of property does ${this.referralObj['customerInfo']['customerFirstName']} want to sell?`;
          }
          break;
        }
      }
      this.currentReferralObj = JSON.parse(JSON.stringify(changes.referralObj.currentValue));
    }
  }

  updatePropertyType(value: number) {
    switch (this.refType) {
      case 'buy': {
        if (this.referralObj['buyingPropertyTypes'].indexOf(value) === -1) {
          this.referralObj['buyingPropertyTypes'].push(value);
        } else {
          const propTypeIndex = this.referralObj['buyingPropertyTypes'].indexOf(value);
          this.referralObj['buyingPropertyTypes'].splice(propTypeIndex, 1);
        }
        break;
      }
      case 'sell': {
        this.referralObj['sellingPropertyType'] = value;
        break;
      }
    }
  }

  optionSelected(value: number) {
    switch (this.refType) {
      case 'buy': {
        return (
          this.referralObj['buyingPropertyTypes'] &&
          this.referralObj['buyingPropertyTypes'].includes(value)
        );
      }
      case 'sell': {
        return (
          this.referralObj['sellingPropertyType'] &&
          this.referralObj['sellingPropertyType'] === value
        );
      }
    }
  }

  disableNextButton() {
    switch (this.refType) {
      case 'buy': {
        return this.referralObj['buyingPropertyTypes'].length === 0;
      }
      case 'sell': {
        return !this.referralObj['sellingPropertyType'];
      }
    }
  }

  updateChange() {
    this.scrollProgressBar.emit('left');
    switch (this.refType) {
      case 'buy': {
        this.referralObj.step.fourth = true;
        // applying commercial logic for all the property types. 
        if (this.referralObj.buyingPropertyTypes) {
          this.referralObj.hideBuySelection = true;
          this.referralObj.isCommercialBuy = true;
          this.referralObj.buyingAgentSelected = null;
          this.agentSelectionService.updateBuyingAgentList(null);
          this.agentSelectionService.updateBuyingAgentBestMatch(null);
          this.referralObj.buyOfframpReason = 'Commercial Agent Needed';
        } else if (this.currentReferralObj.buyingPropertyTypes) {
          this.referralObj.hideBuySelection = false;
          this.referralObj.isCommercialBuy = false;
          this.referralObj.buyOfframpReason = undefined;
        }
        break;
      }
      case 'sell': {
        if (this.rrnReferral) {
          this.referralObj['step'].seventh = true;
        } else {
          this.referralObj['step'].ninth = true;
        }
        if (this.referralObj.sellingPropertyType) {
          this.referralObj.hideSellLocation = true;
          this.referralObj.isCommercialSell = true;
          this.referralObj.sellingAgentSelected = null;
          this.agentSelectionService.updateSellingAgentList(null);
          this.agentSelectionService.updateSellingAgentBestMatch(null);
          this.referralObj.sellOfframpReason = 'Commercial Agent Needed';
        } else if (this.currentReferralObj.sellingPropertyType) {
          this.referralObj.hideSellLocation = false;
          this.referralObj.isCommercialSell = false;
          this.referralObj.sellOfframpReason = undefined;
        }
        break;
      }
    }
    this.updateReferralObj.emit(this.referralObj);
    this.saveReferral();
  }

  onBackBtnClick() {
    this.scrollProgressBar.emit('right');
    this.saveReferral();
    switch (this.refType) {
      case 'buy': {
        this.referralObj.step.third = false;
        this.referralObj.step.fourth = false;
        break;
      }
      case 'sell': {
        if (this.rrnReferral) {
          this.referralObj.step.sixth = false;
          this.referralObj.step.seventh = false;
        } else {
          this.referralObj.step.eighth = false;
          this.referralObj.step.ninth = false;
        }
        break;
      }
    }
  }

  saveReferral() {
    switch (this.refType) {
      case 'buy': {
        if (JSON.stringify(this.currentReferralObj.buyingPropertyTypes) !== JSON.stringify(this.referralObj.buyingPropertyTypes)
          && this.referralObj.step.third) {
          this.referralObj.currentStep = 'Buying Property Types';
          this.saveUpdateDraft.emit(this.referralObj);
        }
        break;
      }
      case 'sell': {
        if (this.currentReferralObj.sellingPropertyType !== this.referralObj.sellingPropertyType
          && this.referralObj.step.eighth) {
          this.referralObj.currentStep = 'Selling Property Type';
          this.saveUpdateDraft.emit(this.referralObj);
        }
        break;
      }
    }
  }

  cancelReferral() {
    const cancelDialog = this.dialog.open(WarningDialogComponent, {
      data: {
        type: 'resetReferral',
        message: AE_WEB_CONST.cancelReferral
      },
      autoFocus: false
    });
    cancelDialog.afterClosed().subscribe((action) => {
      if (action) {
        this.resetReferral.emit(true);
      }
    });
  }

  ngOnDestroy() {
    this.saveReferral();
  }
}
