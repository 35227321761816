import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { catchError, map } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { Address } from '../models/Address';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  /**
   * Base Constructor
   * @param baseClientSvc Instance of BaseClientService
   * @param logSvc Instance of RemoteLoggingService
   */
  constructor(
    private baseClient: BaseClientService,
    private logSvc: RemoteLoggingService
  ) { }


  getCities(searchTerm: string): Observable<Address[] | any> {
    return this.baseClient.getArr(`/b2b/location/city/${searchTerm}`, 'get cities')
      .pipe(
        map(r => r),
        catchError(err => {
          const emptyArr = [];
          this.logSvc.logError(err);
          return of(emptyArr);
        })
      );
  }

  getStreetAddress(searchTerm: any, rrnReferral: boolean): Observable<Address[] | any> {
    return this.baseClient.getArr(`/b2b/location/streetAddress/${searchTerm}`, 'get street address', null, null, rrnReferral)
      .pipe(
        map(r => r.body),
        catchError(err => {
          const emptyArr = [];
          this.logSvc.logError(err);
          return of(emptyArr);
        })
      );
  }

}
