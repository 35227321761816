import { Component, OnInit } from '@angular/core';
import { UntypedFormControl  } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  isShown = false;
  selectedFeedback: number;
  additionalComments = new UntypedFormControl();

  constructor(
    public dialogRef: MatDialogRef<FeedbackComponent>,
  ) { }

  ngOnInit(): void {
  }

  textAreaShown(feedback) {
    this.isShown = true;
    this.selectedFeedback = feedback;
  }

  sendFeedback() {
    const feedback = {
      feedbackRating: this.selectedFeedback,
      additionalComments: this.additionalComments.value
    };
    this.dialogRef.close(feedback);
  }

}
