import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() rrnReferral = false;
   currentYear = new Date().getFullYear();
  constructor() { }

  ngOnInit(): void { }
  // onClickLinks(){
  //   window.open('https://www.google.com','_blank');
  // }
}
