<app-spinner></app-spinner>

<!-- Create Referral -->

<div id="referral-container" class="referral container" *ngIf="agentDetail && !loggedOut && !rrnReferral">
  <app-header [agentDetail]="agentDetail" (loggedOut)="disableReferralApp($event)"></app-header>
  <div class="referral-container">
    <div *ngIf="agentHasDashRoles" class="referral-container-left-nav">
      <app-left-navigation [agentDetail]="agentDetail"></app-left-navigation>
    </div>
    <div [ngClass]="getClass()">
      <router-outlet *ngIf="agentHasDashRoles"></router-outlet>
      <div *ngIf="!agentHasDashRoles" class="agent-no-roles-message align-center">
        <h2 class="title-header">Please Contact Your Help Desk</h2>
        <div class="agent-no-roles-message-wrapper">
          You are not authorized to access this application.<br />
          If you believe you have received this message in error,<br />
          please contact us at
          <a>{{email}} or {{phoneNo}}.</a>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
<router-outlet *ngIf="loggedOut && !rrnReferral"></router-outlet>

<app-home *ngIf="showHome"></app-home>

<!-- RRN Referral -->

<div id="referral-container" class="referral container" *ngIf="rrnReferral">
  <app-header [rrnReferral]=true></app-header>
  <div class="referral-container">
    <div class="referral-container-dashboard">
      <router-outlet></router-outlet>
      <app-footer rrnReferral=true></app-footer>
    </div>
  </div>
</div>

<!-- Downtime/ Maintenance -->

<div id="referral-container" class="referral container maintenance-container" *ngIf="AppMaintenance">
  <app-header [rrnReferral]=rrnCurRouteLink></app-header>
  <div class="referral-container">
    <div class="referral-container-dashboard page-maintenance">
      <router-outlet></router-outlet>
      <app-footer [rrnReferral]=rrnCurRouteLink></app-footer>
    </div>
  </div>
 </div>